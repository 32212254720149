import React, { Component } from 'react'
import AdminMsg from '../../../presentationals/Admin/CustomerPages/SendMsg.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { pushNotification } from '../AdminActionCreator.component'
import { MSG_STRING, MAIL_STRING } from '../../../../utils/Constant'

class MessageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupState: false,
            popupStatus: '',
            message: '',
            templateId:''
        }
    }


    render() {
        const { allDetail } = this.props;
        return (
            <div>
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <AdminMsg
                    sendHandler={this.sendHandler.bind(this)}
                    messageHandler={this.messageHandler.bind(this)}
                    msgBody={this.state.message}
                    msgTempleteClick={this.msgTempleteClick.bind(this)}
                />
            </div>
        )
    }

    msgTempleteClick(msgType) {
        const { allDetail } = this.props;
        let payBackDate = allDetail != null && allDetail.paybackDate != null ? allDetail.paybackDate.split(' ')[0] : ""
        let payBackAmount = allDetail != null && allDetail.totalPayBackAmount != null ? allDetail.totalPayBackAmount : ""
        let installmentAmount = allDetail != null && allDetail.installmentAmount != null ? allDetail.installmentAmount : ""
        let installmentDate = allDetail != null && allDetail.installmentDate != null ? allDetail.installmentDate : ""
        if (msgType == 'WithNach') {
            this.setState({ message: 'BORROWBUDDY LOAN: We are initiating your auto debit of INR ' + payBackAmount + ' on ' + payBackDate + '.\n\nMaintain sufficient balance to avoid any inconvenience.' })
        } else if (msgType == 'WithoutNach') {
            this.setState({ message: 'BORROWBUDDY LOAN: Kindly note the repayment amount INR ' + payBackAmount+ ' as on ' + payBackDate + '.\n\nFor details check your Email.\n\nContact Us: ' + MAIL_STRING.MOBILE_NO + ' or recovery@borrowbuddy.co.in' })
        }else if (msgType == 'EmiWithNach') {
            this.setState({ message: 'BORROWBUDDY LOAN EMI: We are initiating your auto debit of INR ' + installmentAmount + ' on ' + installmentDate + '.\n\nMaintain sufficient balance to avoid any inconvenience.' })
        } else if (msgType == 'EmiWithoutNach') {
            this.setState({ message: 'BORROWBUDDY LOAN EMI: Kindly note the repayment amount INR ' + installmentAmount + ' as on ' + installmentDate + '.\n\nFor details check your Email.\n\nContact Us: ' + MAIL_STRING.MOBILE_NO + ' or recovery@borrowbuddy.co.in' })
        } else if (msgType == 'ExecutiveContact') {
            this.setState({templateId:'1207161475779223316'})
            this.setState({ message: MSG_STRING.EXECUTIVE_CONTACT })
        } else if (msgType == 'DocsUpload') {
            this.setState({templateId:'1207161475786136184'})
            this.setState({ message: MSG_STRING.DOC_MSG })
        }else if (msgType == 'razorpay') {
            this.setState({templateId:'1207161475799205532'})
            this.setState({ message: MSG_STRING.RAZ_MSG })
        }
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    messageHandler(e) {
        this.setState({ message: e.target.value });
    }

    sendHandler() {
        const { allDetail, admin } = this.props;
        if (this.state.message != '' && this.state.templateId !='') {
            pushNotification(allDetail, this.state.message.replace(/&/g, 'and'), '', admin.emailId, 'message', '', this.state.notificationImage, '', '',this.state.templateId,
                (callBack) => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Message Sent Successfully',
                    })
                    this.removePopup();
                })
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Please enter message',
            })
            this.removePopup();
        }
    }
}

export default MessageContainer;