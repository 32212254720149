import React from "react";
import ReactQuill from "react-quill";
import { DocumentMail, ExecutiveContact, HalfSalary, LastStep, NachRegistrationClarification, Razormail, RepeatApplication, SecurityChequeMail, generateEmailContent, generateLoanAgreementEmailContent, generateLoanApprovalEmail, kycRegistrationMail } from './emailTemplate'
import { APIS, WEB_URL } from "../../../../../utils/api-factory";
import ConfirmationPopup from "../../../../presentationals/Popup/ConfirmationPopup.component";
import Popup from "../../../../presentationals/Popup/Popup.component";
import { giftCardpushNotification, pushNotification, saveImportantLoans } from "../../AdminActionCreator.component";
import { STRING } from "../../../../../utils/Constant";
import StatusChangePopup from '../../../../presentationals/Popup/StatusChangeToComunicate'

let quillModules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        ["blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        [{ align: [] }],
        ["link", "image"],
    ],
};

let quillFormats = [
    "header",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "background",
    "align",
];

class Email extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mailText: '',
            popupState: false,
            popupStatus: '',
            allDocState: true,
            title: '',
            comment: '',
            slryTextState: false,
            adharTextState: false,
            panTextState: false,
            bankTextState: false,
            executiveContactState: false,
            salarySlipTextState: false,
            officeIdTextState: false,
            CurrentAddressProofState: false,
            isStatusChange: 'false',
            statusChangePopup: false,
            mailBody: '',
            whatsappMsg: '',
            mailViewState: '',
            halfSalaryState: '',
            mailType: '',
            documentsData: '',
            link: '',
            approveAmount: '',
            confirmationText: "Are You Sure To Send Mail?",
            confirmpopup: false,
            commentType: ''
        }
    }
    setMailText(e) {
        this.setState({ mailText: e })
    }

    setSubject(e) {
        this.setState({ subject: e.target.value })
    }
    mailTemplateHandler(e) {
        console.log(e.target.value)
        const { allDetail } = this.props
        if (e.target.value === 'Three Persons Reference') {
            let link = WEB_URL + 'userReference/' + allDetail.userId
            let data = generateLoanApprovalEmail(allDetail, link)
            this.setState({ subject: allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your BorrowBuddy Loan Application" : "" })
            this.setState({ mailText: data })
            this.setState({
                mailViewState: 'reference',
                mailType: 'reference'
            })
        }
        if (e.target.value === 'BorrowBuddy : Security Cheque Clarification') {
            let data = generateLoanAgreementEmailContent(allDetail)
            this.setState({ subject: 'BorrowBuddy : Security Cheque Clarification' })
            this.setState({ mailText: data })
            this.setState({
                subject: 'BorrowBuddy : Security Cheque Clarification', mailText: data, mailViewState: 'securityChequeClarification',
                mailType: 'securityChequeClarification'
            }, () => {
                // this.setState({
                //     mailViewState: 'securityChequeClarification',
                //     mailType: 'securityChequeClarification'
                // })
            })
            // this.setState({ mailText: data })

        }
        if (e.target.value === 'NACH Registration Clarification') {
            let data = NachRegistrationClarification(allDetail)
            this.setState({
                subject: 'NACH Registration Clarification', mailText: data, mailViewState: 'nachRegistrationClarification',
                mailType: 'nachRegistrationClarification'
            }, () => {
                // this.setState({
                //     mailViewState: 'nachRegistrationClarification',
                //     mailType: 'nachRegistrationClarification'
                // })
            })

        }
        if (e.target.value === '100% Salary Mail') {
            let data = HalfSalary(allDetail)
            this.setState({ subject: allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your BorrowBuddy Loan Application" : "" })
            this.setState({ mailText: data })
            this.setState({
                mailViewState: 'halfSalaryMail',
                mailType: 'halfSalaryMail'
            })
            // this.setState({ mailText: data })

        }
        if (e.target.value === 'Executive Contact Mail') {
            let data = ExecutiveContact(allDetail)
            this.setState({ subject: allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your BorrowBuddy Loan Application" : "" })

            this.setState({ mailText: data })
            this.setState({
                mailViewState: 'executiveContactMail',
                mailType: 'executiveContactMail'
            })


        }
        if (e.target.value === 'Repeat Application') {
            let data = RepeatApplication(allDetail)
            this.setState({ subject: allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your BorrowBuddy Loan Application" : "" })

            this.setState({ mailText: data })
            this.setState({
                mailViewState: 'repeatApplication',
                mailType: 'repeatApplication'
            })


        }
        if (e.target.value === 'Bank not live with Razorpay e-mandate') {
            let data = Razormail(allDetail)
            this.setState({ subject: allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your BorrowBuddy Loan Application" : "" })

            this.setState({ mailText: data })
            this.setState({
                mailViewState: 'raizorpaymail',
                mailType: 'raizorpaymail'
            })
            // this.setState({ mailText: data })

        }
        if (e.target.value === 'BorrowBuddy : Security Cheque and Nach') {
            let data = SecurityChequeMail(allDetail)
            this.setState({ subject: allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your BorrowBuddy Loan Application" : "" })

            this.setState({ mailText: data })
            this.setState({
                mailViewState: 'securityCheque',
                mailType: 'securityCheque'
            })

            // this.setState({ mailText: data })

        }
        if (e.target.value === 'Final step to get Loan Amount in your account') {
            let data = LastStep(allDetail);

            this.setState({
                subject: allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Final step to get Loan Amount in your account" : "",
                mailViewState: 'lastStep',
                mailType: 'lastStep',
                mailText: data
            }, () => {
                // this.setState({
                //     mailViewState: 'lastStep',
                //     mailType: 'lastStep'
                // });
            });



        }
        if (e.target.value === 'Document Mail') {
            let data = DocumentMail(allDetail, this.state.adharTextState, this.state.panTextState, this.state.bankTextState, this.state.salarySlipTextState, this.state.halfSalaryState, this.state.CurrentAddressProofState)
            this.setState({ subject: allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your BorrowBuddy Loan Application" : "" })

            this.setState({ mailText: data })
            this.setState({
                mailViewState: 'DocumentMail',
                mailType: 'DocumentMail'
            })

            // this.setState({ mailText: data })

        }
        if (e.target.value === 'Kyc Registration') {
            let data = kycRegistrationMail(allDetail)
            this.setState({ subject: allDetail != null && allDetail.applicationId != null ? "Application Id: " + allDetail.applicationId + " | Regarding your Borrowbuddy Loan Application" : "", mailText: data,mailViewState: 'kycRegistration',
            mailType: 'kycRegistration' }, () => {
                this.setState({
                    
                })
            })

            // this.setState({ mailText: data })

        }


    }
    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    componentDidMount() {
        const { allDetail } = this.props
        fetch(APIS.GET_USER_DOCUMENTS_BY_USER_ID + allDetail.userId)
            .then(res => res.json())
            .then(res => {
                this.setState({ documentsData: res })
            })
        if (this.state.documentsData.documentList != null) {
            if (this.state.documentsData.documentList.adharCardUrl == null) {
                this.setState({ adharTextState: true })
            } if (this.state.documentsData.documentList.panCardUrl == null) {
                this.setState({ panTextState: true })

            } if (this.state.documentsData.documentList.bankStatementUrl == null) {
                this.setState({ bankTextState: true })

            } if (this.state.documentsData.documentList.salaryProofUrl == null) {
                this.setState({ salarySlipTextState: true })

            } if (this.state.documentsData.documentList.photographUrl == null) {
                this.setState({ officeIdTextState: true })

            }
            if (this.state.documentsData.documentList.currentAddressUrl == null) {
                this.setState({ officeIdTextState: true })

            }
        } else {

            this.setState({
                adharTextState: true,
                panTextState: true,
                bankTextState: true,
                salarySlipTextState: true,
                officeIdTextState: true,
                CurrentAddressProofState: true
            })
        }
    }
    newmailSendHandler(type) {
        const { allDetail, admin, userDetail } = this.props;
        if (type == 'yes') {
            if (userDetail.typeOfProduct == 'Loan') {
                pushNotification(allDetail, this.state.mailText, this.state.subject, admin.emailId, STRING.COMMUNICATED, this.state.isStatusChange, this.state.notificationImage, this.state.mailType, this.state.whatsappMsg, '',
                    (callBack) => {

                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail Sent Successfully',
                                statusChangePopup: false,
                                isStatusChange: '',
                                confirmpopup: false
                            })
                            this.removePopup();
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail not sent',
                                statusChangePopup: false,
                                isStatusChange: '',
                                confirmpopup: false
                            })
                            this.removePopup();
                        }
                    })
            } else if (userDetail.typeOfProduct == 'Gift Card') {
                giftCardpushNotification(userDetail, this.state.mailText, this.state.subject, admin.emailId, STRING.COMMUNICATED, this.state.isStatusChange, this.state.notificationImage, this.state.mailType, this.state.whatsappMsg,
                    (callBack) => {

                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail Sent Successfully',
                                statusChangePopup: false,
                                isStatusChange: '',
                                confirmpopup: false
                            })
                            this.removePopup();
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail not sent',
                                statusChangePopup: false,
                                isStatusChange: '',
                                confirmpopup: false
                            })
                            this.removePopup();
                        }
                    })
            }
        } else {
            this.setState({ confirmpopup: false })
        }
    }
    sendHandler(type) {
        const { allDetail } = this.props
        if (this.state.mailText !== '') {
            if (type == 'mail' && (this.state.mailViewState === 'DocumentMail' || this.state.mailViewState === 'repeatApplication')) {
                this.setState({
                    statusChangePopup: true,
                    mailBody: this.state.mailText,
                    // whatsappMsg: document.getElementById("communicationMail").textContent,
                    // whatsappMsg: "Dear " + allDetail.firstName + " Complete your Phocket Loan Application by providing the following documents- " + officeId + adharCard + panCard + bankStatement + salarySlip + currentAddressProf + " Regards Team Phocket " + MAIL_STRING.MOBILE_NO + " " + MAIL_STRING.FINANCE_MAIL_ID,
                })
            } else {
                this.setState({
                    mailBody: this.state.mailText,
                    // whatsappMsg: "Dear " + allDetail.firstName + " Complete your Phocket Loan Application by providing the following documents- " + officeId + adharCard + panCard + bankStatement + salarySlip + currentAddressProf + " Regards Team Phocket " + MAIL_STRING.MOBILE_NO + " " + MAIL_STRING.FINANCE_MAIL_ID,
                    confirmpopup: true
                })


            }
        } else {
            this.setState({
                popupState: true,
                popupStatus: 'Enter or select Mail first!',

            })
            this.removePopup();
        }
    }
    closePopup() {
        this.setState({ popupState: false });
    }
    checkStatusChange(e) {
        this.setState({ isStatusChange: e.target.value })
    }

    checkStatusHandler() {
        const { allDetail, admin, userDetail } = this.props;
        if (this.state.isStatusChange != '') {
            if (userDetail.typeOfProduct == 'Loan') {
                pushNotification(allDetail, this.state.mailText, this.state.subject, admin.emailId, STRING.COMMUNICATED, this.state.isStatusChange, this.state.notificationImage, this.state.mailType, this.state.whatsappMsg, '',
                    (callBack) => {

                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail Sent Successfully',
                                statusChangePopup: false,
                                isStatusChange: ''
                            })
                            this.removePopup();
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail not sent',
                                statusChangePopup: false,
                                isStatusChange: ''
                            })
                            this.removePopup();
                        }
                    })
            } else if (userDetail.typeOfProduct == 'Gift Card') {
                giftCardpushNotification(userDetail, this.state.mailText, this.state.subject, admin.emailId, STRING.COMMUNICATED, this.state.isStatusChange, this.state.notificationImage, this.state.mailType, this.state.whatsappMsg,
                    (callBack) => {

                        if (callBack == 'success') {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail Sent Successfully',
                                statusChangePopup: false,
                                isStatusChange: ''
                            })
                            this.removePopup();
                        } else {
                            this.setState({
                                popupState: true,
                                popupStatus: 'Documents Mail not sent',
                                statusChangePopup: false,
                                isStatusChange: ''
                            })
                            this.removePopup();
                        }
                    })
            }
        }
        if (this.state.commentType !== '') {
            this.saveComment()
        }
    }
    commentHandler(e) {
        this.setState({ commentType: e.target.value })
    }
    saveComment(data) {
        const { admin, allDetail } = this.props
        pushNotification(allDetail, this.state.commentType, '', admin.emailId, "comment", '', '', '', '', '',
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Comment Saved Successfully',
                        commentType: ''
                    })
                    this.removePopup();
                } else {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Please try again',
                        commentType: ''
                    })
                    this.removePopup();
                }
            })
    }
    markAsImportant(isImportant) {
        const { allDetail } = this.props
        saveImportantLoans(allDetail, isImportant,
            (callBack) => {
                if (callBack == 'success') {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Application Marked as Important',
                    })
                    this.removePopup();
                }
            })
    }
    closeStatusPopup() {
        this.setState({ statusChangePopup: false })
    }
    subjectHandler(e) {
        this.setState({ subject: e.target.value })
    }

    render() {
        const { allDetail } = this.props
        return (
            <div className="details-new com-box" style={{ paddingBottom: window.innerHeight < 650 ? '200px' : '20px', overflow: 'auto', maxHeight: '600px' }}>
                {/* {console.log(this.state.mailText)} */}
                {this.state.confirmpopup ?

                    <ConfirmationPopup cancelLoanConfirm={this.newmailSendHandler.bind(this)} confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.statusChangePopup == true ?
                    <StatusChangePopup
                        commentHandler={this.commentHandler.bind(this)}
                        checkStatusChange={this.checkStatusChange.bind(this)}
                        checkStatusHandler={this.checkStatusHandler.bind(this)}
                        markAsImportant={this.markAsImportant.bind(this)}
                        allDetail={allDetail}
                        isStatusChange={this.state.isStatusChange}
                        closeStatusPopup={this.closeStatusPopup.bind(this)} />
                    : ""}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <select className="form-control" style={{ background: '#DBE9FF', color: '#000' }} onChange={(e) => this.mailTemplateHandler(e)}>
                    <option value={''}>Select Template</option>
                    <option value={'Kyc Registration'}>Kyc Registration</option>

                    <option value={'Three Persons Reference'}>Three Persons Reference</option>
                    <option value={'BorrowBuddy : Security Cheque Clarification'}>BorrowBuddy : Security Cheque Clarification</option>
                    <option value={'NACH Registration Clarification'}>NACH Registration Clarification</option>
                    <option value={'100% Salary Mail'}>100% Salary Mail</option>
                    <option value={'Executive Contact Mail'}>Executive Contact Mail</option>
                    <option value={'Repeat Application'}>Repeat Application</option>
                    <option value={'Bank not live with Razorpay e-mandate'}>Bank not live with Razorpay e-mandate</option>
                    <option value={'BorrowBuddy : Security Cheque and Nach'}> BorrowBuddy : Security Cheque and Nach</option>
                    <option value={'Final step to get Loan Amount in your account'}> Final step to get Loan Amount in your account</option>
                    <option value={'Document Mail'}> Document Mail</option>


                </select>
                <label style={{ marginTop: '10px' }}>Mail Box</label>
                <div className="" style={{ background: '#F1F7FF', borderRadius: '6px', padding: '10px' }}>
                    <div>
                        <input style={{ background: '#DBE9FF', color: '#000' }} onChange={(e) => this.subjectHandler(e)} className="form-control" value={this.state.subject} placeholder="Subject" />
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <ReactQuill
                            theme="snow"
                            value={this.state.mailText}
                            onChange={(e) => this.setMailText(e)}
                            className="mailBody"
                            modules={quillModules}
                            formats={quillFormats}
                            style={{ overflow: 'auto' }}
                        />
                    </div>
                    <div>
                        <button onClick={() => this.sendHandler('mail')} style={{ marginTop: '20px' }} className="btn btn-primary">Send</button>
                    </div>
                </div>
            </div>
        )
    }
}
export default Email