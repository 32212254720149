import React, { useState, useEffect } from "react";
import { calculateEmiBreakApi, generateRazorPayLinkApi } from "../../containers/Admin/AdminActionCreator.component";
import Loader from '../../presentationals/Loader/Loader.component'

const GenerateLink = ({ openGenerateLink, emiDetail, userDetail, admin, searchedLoanData }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [calculatedData, setCalculatedData] = useState('');
    const [emailComm, setEmailComm] = useState(true);
    const [smsComm, setSmsComm] = useState(true);
    const [paymentData, setPaymentData] = useState({});
    const [expiryDate, setExpiryDate] = useState('');
    const [otherNumber, setOtherNumber] = useState('');
    const [unixDateTime, setUnixTimestamp] = useState('');
    const [emiData, setSlectedData] = useState('');
    const [dateTime, setDateTime] = useState('');
    const [otherNumberOption, setOtherOption] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState('test');
    const [isSelected, setIsSelected] = useState(false);
    const [loaderState,setLoader]=useState(false)

    const [extendedData, setExtendedData] = useState(

    );
    const [waivedNach, setwaivedNach] = useState('0');
    const [waivedPenalty, setwaivedPenalty] = useState('0');

    const handleCheckboxChange = (index) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(index)) {
                return prevSelectedRows.filter((i) => i !== index);
            } else {
                return [...prevSelectedRows, index];
            }
        });
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(searchedLoanData.map((_, i) => i));
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if (searchedLoanData && searchedLoanData.length > 0) {
            setSelectAll(selectedRows.length === searchedLoanData.length);
        }
    }, [selectedRows, searchedLoanData]);

    const handleWaivedNachChange = (index, value) => {
        const updatedData = [...extendedData];
        updatedData[index].waivedNach = value;
        setExtendedData(updatedData);
    };

    const handleWaivedPenaltyChange = (value) => {
        const updatedData = [...extendedData];
        updatedData.waivedPenalty = value;
        setExtendedData(updatedData);
    };

    const getSelectedRowsData = () => {
        return extendedData.filter((_, i) => selectedRows.includes(i));
    };

    const calculate = (selectedData) => {
        setSlectedData(selectedData)
        setLoader(true)
        let data = {
            "waivedNach": waivedNach,
            "waivedPenalty": waivedPenalty,
            "loanId": userDetail.loanId
        }
        calculateEmiBreakApi(data,'bullet', (callback) => {
            setCalculatedData(callback);
            setLoader(false)

        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name !== 'numberType') {
            setPaymentData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
        if (name === 'numberType') {
            if (value === 'primary') {
                setOtherOption(false)
                setPaymentData((prevData) => ({
                    ...prevData,
                    mobileNumber: userDetail.mobileNumber,
                }));
            }
            if (value === 'alternate') {
                setOtherOption(false)
                setPaymentData((prevData) => ({
                    ...prevData,
                    mobileNumber: userDetail.alternativeNo,
                }));
            }
            if (value === 'other') {
                setOtherOption(true)
            }
        }
    };

    const changeHandler = (e, type) => {
        if (type === 'EMAIL') {
            setEmailComm(e.target.checked);
        } else if (type === 'SMS') {
            setSmsComm(e.target.checked);
        } else if (type === 'otherNumber') {
            setOtherNumber(e.target.value);
            setPaymentData((prevData) => ({
                ...prevData,
                mobileNumber: e.target.value,
            }));
        } else if (type === 'expiryDateTime') {
            setExpiryDate(e.target.value);
            const selectedDateTime = e.target.value;
            setDateTime(selectedDateTime);

            // Convert selected date and time to Unix timestamp
            if (selectedDateTime) {
                const unixTimestamp = new Date(selectedDateTime).getTime() / 1000; // Convert to seconds
                setUnixTimestamp(unixTimestamp);
            }
        }
    };

    const extractEmiData = (data) => {
        return data.map(item => ({
            emiId: item?.emiId ?? null,
            principalAmount: item?.princpleAmt ?? null,
            interestAmount: item?.InterestAmount ?? null,
            penalty: item?.penalty ?? null,
            penaltyGst: item?.penaltyGst ?? null,
            nachBounce: item?.nachBounce ?? '0',
            bounceGst: item?.bounceGst ?? '0',
            emiAmount: item?.AMOUNT ?? null,
            overdueAmount: item?.OVERDUEAMOUNT ?? '0',
            penaltyWaived: item?.waivedPenalty ?? '0',
            nachBounceWaived: item?.waivedNach ?? '0'
        }));
    };

    const linkGenerate = () => {
        if (paymentData.mobileNumber) {
            setLoader(true)
            let data = {
                "loanId": userDetail.loanId,
                "userId": userDetail.userId,
                "amount": calculatedData.totalAmount,
                "emiList": extractEmiData(emiData),
                "empId": admin.emailId,
                "paymentType": paymentData.PaymentType,
                "sendSms": smsComm,
                "sendMail": emailComm,
                "nbfcId": "ATcUsLVH",
                "penaltyWaived": 0,
                "nachBounceWaived": 0,
                "expireBy": unixDateTime,
                "mobileNumber": paymentData.mobileNumber
            }
            generateRazorPayLinkApi(data, callback => {
                if (callback.shortUrl) {
                    setPaymentUrl(callback.shortUrl)
                    setLoader(false)

                }
            })
        }
    };

    const handleCopy = () => {
        if (paymentUrl !== '') {
            navigator.clipboard.writeText(paymentUrl)
                .then(() => {
                    alert('Payment URL copied to clipboard!');
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    return (
        <div className="background-blur income">
            <div className="popup-main small-popup container-fluid dbl-border" style={{ marginTop: "8%", width: "80%", maxHeight: '70%', overflow: 'auto' }}>
            {loaderState?
              <Loader/>  
            :""}
                <div className="row">
                    <div className="col-sm-6 col-xs-12">
                    <h3 style={{color:'black',marginLeft:'20px'}}>Total Amount For Payment Link is <b>{calculatedData.totalAmount}</b></h3>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className="text-right">
                            <i
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => openGenerateLink()}
                                className="fa fa-times"
                            ></i>
                        </div>
                    </div>
                </div>
                <div className="row from-group">
                    {console.log(searchedLoanData.totalPaybackAmount, 'searchedLoanData.payOnToday')}
                    {userDetail.loanEmi === 1 && searchedLoanData ? (
                        <div className="col-xs-12 phocket-table-new" style={{ overflow: "auto" }}>
                            <table className="text-center">
                                <thead>
                                    <tr>
                                        {/* <th className="text-center">
                                                Select
                                                <br />
                                                Select All&nbsp;&nbsp;
                                            </th> */}
                                        <th className="text-center">Amount</th>
                                        <th className="text-center">Principle Amt</th>
                                        {/* <th className="text-center">Interest Amount</th>
                                            <th className="text-center">Penalty</th>
                                            <th className="text-center">Overdue Amount</th> */}
                                        <th className="text-center">Waived NACH</th>
                                        <th className="text-center">Waived Penalty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchedLoanData.payOnToday}

                                    <tr >
                                        {/* <td className="text-center">
                                                    <input
                                                        type="checkbox"
                                                        checked={isSelected}
                                                        onChange={() => handleCheckboxChange()}
                                                    />
                                                </td> */}
                                        <td className="text-center">{searchedLoanData.approveAmount}</td>
                                        <td className="text-center">
                                            {searchedLoanData.approveAmount}
                                        </td>
                                        {/* <td className="text-center">
                                                    {data.InterestAmount !== null && data.InterestAmount !== undefined
                                                        ? Number(data.InterestAmount).toFixed(2)
                                                        : ""}
                                                </td>
                                                <td className="text-center">{data.penalty}</td>
                                                <td className="text-center">{data.OVERDUEAMOUNT}</td> */}
                                        <td className="text-center">
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={waivedNach}
                                                onChange={(e) => setwaivedNach(e.target.value)}
                                            />
                                        </td>
                                        <td className="text-center">
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={waivedPenalty}
                                                onChange={(e) => setwaivedPenalty(e.target.value)}
                                            />
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    ) : ""
                    }
                </div>
                <div style={{ marginLeft: '20px' }}>
                    <button
                        className="btn btn-primary"
                        onClick={() => calculate()}
                        style={{ marginTop: '30px' }}
                    >Calculate
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GenerateLink;
