import React, { Component } from 'react'
import IncompleteApp from '../../../presentationals/Admin/BulkActions/IncompleteApplication.component'
import { _formatDateInYMD } from '../../../../utils/validation'
import { APIS } from '../../../../utils/api-factory'
import { incompleteCommunication } from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component';
import { TemplateId } from '../../../presentationals/Admin/BulkActions/MessageTemplates.component'

class IncompleteApplications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            incompleteAppStartDate: '',
            incompleteAppEndDate: '',
            applicationCount: [],
            popupState: false,
            popupStatus: '',
            mailBoxSate: false,
            msgBoxState: false,
            body: '',
            btnDisabled: false,
            allStates: [],
            incompleteApplication: {
                StartDate: _formatDateInYMD(new Date()),
                EndDate: _formatDateInYMD(new Date()),
                communication: '',
                stateFilter: ''
            },
            incompleteApplicationData: {
                body: '',
                title: '',
                url: '',
                type: []
            },
            templateIdTosend: ''
        }
    }

    componentWillMount() {
        fetch(APIS.GET_ALL_STATE)
            .then(res => res.json())
            .then(res => {
                this.setState({
                    allStates: res
                })
            })
    }
    mailTempleteClick(e, type) {
        let incompleteApplicationData = Object.assign({}, this.state.incompleteApplicationData)
        if (type == 'REVIEW') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.REVIEW_ON_PLAYSTORE })
        }
        if (type == 'MONTHEND') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.MONTH_END_SAL_ADVANCE })
        }
        if (type == 'BILLPAY') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BILL_PAY_SAL_ADVANCE })
        }
        if (type == 'TRYBORROWBUDDY') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BORROWBUDDY_APP_TRY_KARO })
        }
        if (type == 'JHATPATLOAN') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.JHATPAT_LOAN })
        }
        if (type == 'ADVANCESALARY') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ELIGIBLE_FOR_ADVANCE_SAL_2_LAC })
        }
        if (type == 'FESTIVESHOPING') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.FESTIVE_SHOPPING_LOAN })
        }
        if (type == 'FEWMINUTELOAN') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WITHIN_FEW_MINUTES })
        }
        if (type == 'LASTCHANCELOAN') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LAST_CHANCE_LOAN })
        }
        if (type == 'UPTO100000') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.UPTO_200000_LOAN })
        }
        if (type == 'LOANWAITING') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WAITING })
        }
        if (type == 'LOWINTERST') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_AT_LOW_INTEREST })
        }
        if (type == 'SPEEDOFROCKET') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.MONEY_AT_SPEED_OF_ROCKET })
        }
        if (type == 'BANKBAZAR') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ADVANCE_SALARY_VIA_BANKBAZAAR })
        }
        if (type == 'URGENTCASH') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.URGENT_CASH })
        }
        if (type == 'FIVEMINUTELOAN') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_WITHIN_5_MINUTES })
        } if (type == 'BANKBAZAAR') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BANKBAZAAR })
        } if (type == 'SALARY_12_MONTH') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.SALARY_12_MONTH })
        }
        if (type == 'GET_A_BORROWBUDDY_PL') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.GET_A_BORROWBUDDY_PL })
        }
        if (type == 'ADVANCE_SAL_BY_BUDDYLOAN') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.ADVANCE_SAL_BY_BUDDYLOAN })
        }
        if (type == 'PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PRE_QUALIFIED_BUDDY_LOAN_BORROWBUDDY })
        }
        if (type == 'BORROWBUDDY_BANKBAZAAR') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BORROWBUDDY_BANKBAZAAR })
        }
        if (type == 'LOAN_FOR_15000_SALARY') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.LOAN_FOR_15000_SALARY })
        }
        if (type == 'BorrowBuddy_App_partnered_with_Anjraj_Talent') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BorrowBuddy_App_partnered_with_Anjraj_Talent })
        }
        if (type == 'Dear_Customer_Refer_your_friends') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Dear_Customer_Refer_your_friends })
        }
        if (type == 'BorrowBuddy_App_partnered_with_Genesis') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BorrowBuddy_App_partnered_with_Genesis })
        }
        if (type == 'BorrowBuddy_App_partnered_with_Hyrexpert') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BorrowBuddy_App_partnered_with_Hyrexpert })
        }
        if (type == 'BorrowBuddy_App_partnered_with_Karyarth') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.BorrowBuddy_App_partnered_with_Karyarth })
        }
        if (type == 'Borrowbuddy_App_partnered_with_SAM_Career') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.Borrowbuddy_App_partnered_with_SAM_Career })
        }
        if (type == 'PHONEPARLOAN') {
            incompleteApplicationData.body = e.target.value
            this.setState({ templateIdTosend: TemplateId.PHONEPARLOAN })
        }
        
        this.setState({ incompleteApplicationData })
    }

    render() {
        return (
            <div className="">
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                <IncompleteApp
                    mailTempleteClick={this.mailTempleteClick.bind(this)}
                    incompleteChange={this.incompleteChange.bind(this)}
                    getCount={this.getCount.bind(this)}
                    sendCommunicationHandler={this.sendCommunicationHandler.bind(this)}
                    incompleteAppStartDate={this.state.incompleteAppStartDate}
                    incompleteAppEndDate={this.state.incompleteAppEndDate}
                    applicationCount={this.state.applicationCount}
                    communicateTypeHandler={this.communicateTypeHandler.bind(this)}
                    mailBoxSate={this.state.mailBoxSate}
                    msgBoxState={this.state.msgBoxState}
                    incompleteApplication={this.state.incompleteApplication}
                    incompleteApplicationData={this.state.incompleteApplicationData}
                    communicateType={this.communicateType.bind(this)}
                    createLink={this.createLink.bind(this)}
                    btnDisabled={this.state.btnDisabled}
                    allStates={this.state.allStates}
                />
            </div>
        )
    }

    createLink() {
        var selection = document.getSelection();
        document.execCommand('createLink', true, this.state.incompleteApplicationData.url);
        selection.anchorNode.parentElement.target = '_blank';
    }

    communicateTypeHandler(boxType) {
        if (boxType == 'mail') {
            this.setState({
                mailBoxSate: true,
                msgBoxState: false,
            })
        } else if (boxType == 'msg') {
            this.setState({
                msgBoxState: true,
                mailBoxSate: false,
            })
        }
    }

    communicateType(e, comType) {
        let incompleteApplicationData = Object.assign({}, this.state.incompleteApplicationData);
        if (comType == 'msgBody') {
            incompleteApplicationData.body = e.target.value
        } else if (comType == 'mailTitle') {
            incompleteApplicationData.title = e.target.value
        } else if (comType == 'url') {
            incompleteApplicationData.url = e.target.value
        } else if (comType == 'communicationType') {
            let comunicationType = []
            let notification = document.getElementById("byStatusSendNotification")
            let mail = document.getElementById("byStatusSendMail")
            if (notification != null) {
                if (notification.checked == true) {
                    if (comunicationType.includes(notification.value)) {

                    } else {
                        comunicationType.push(notification.value)
                    }
                } else {
                    let notificationIndex = comunicationType.indexOf(notification.value)
                    if (notificationIndex >= 0) {
                        comunicationType.splice(notificationIndex, 1);
                    }
                }
            }
            if (mail != null) {
                if (mail.checked == true) {
                    if (comunicationType.includes(mail.value)) {

                    } else {
                        comunicationType.push(mail.value)
                    }
                } else {
                    let mailIndex = comunicationType.indexOf(mail.value)
                    if (mailIndex >= 0) {
                        comunicationType.splice(mailIndex, 1);
                    }
                }
            }
            incompleteApplicationData.type = comunicationType
        }
        this.setState({ incompleteApplicationData })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    incompleteChange(e, statusType) {
        let incompleteApplication = Object.assign({}, this.state.incompleteApplication);
        if (statusType == 'startDate') {
            this.setState({ incompleteAppStartDate: e })
            incompleteApplication.StartDate = _formatDateInYMD(e)
        } else if (statusType == 'endDate') {
            this.setState({ incompleteAppEndDate: e })
            incompleteApplication.EndDate = _formatDateInYMD(e)
        } else if (statusType == 'stateFilter') {
            incompleteApplication.stateFilter = e.target.value
        }
        this.setState({ incompleteApplication })
    }

    getCount() {
        fetch(APIS.GET_INCOMPLETE_COUNT_BY_FILTER + this.state.incompleteApplication.StartDate + '&endDate=' + this.state.incompleteApplication.EndDate + '&state=' + this.state.incompleteApplication.stateFilter)
            .then(res => res.json())
            .then(json => {
                this.setState({ applicationCount: json })
            })
    }

    sendCommunicationHandler() {
        this.setState({ btnDisabled: true })
        if (this.state.msgBoxState == true) {
            incompleteCommunication(this.state.incompleteApplicationData, this.state.incompleteApplication, 'msg', this.props.admin, '', this.state.applicationCount, this.state.templateIdTosend,
                (callBack) => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Communicated Successfully',
                        btnDisabled: false
                    })
                    this.removePopup();
                })
        } else if (this.state.mailBoxSate == true) {
            let mailBody = document.getElementById("incompleteAppsMailBody").innerHTML
            incompleteCommunication(this.state.incompleteApplicationData, this.state.incompleteApplication, 'mail', this.props.admin, mailBody, this.state.applicationCount, this.state.applicationCount, this.state.templateIdTosend,
                (callBack) => {
                    this.setState({
                        popupState: true,
                        popupStatus: 'Communicated Successfully',
                        btnDisabled: false
                    })
                    this.removePopup();
                })
        }
    }


}
export default IncompleteApplications;