import React from 'react'
import { APIS } from '../../../../../utils/api-factory';
import { deleteAssignedEntryApi, saveAssignByStatusAPINewLeave } from '../../AdminActionCreator.component';
import LeavePopup from './leavePopup';
import moment from 'moment';

class ListingRule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userStatusData: '',
            leavePopupState: false,
            leaveEndDate: '',
            leaveStartDate: '',
            agentData: ''

        }
    }
    deleteEntry(data) {
        deleteAssignedEntryApi(data, callback => {
            console.log(callback)
        })
    }
    markLeave(data) {
        console.log(data)
        this.setState({ agentData: data })
        if(data.presentStatus){
        this.setState({ leavePopupState: true })
        }else{
            saveAssignByStatusAPINewLeave(data.status, data.emailId, data.emplId, data.salaryBucket, null, null, callback => {
                console.log(callback)
                this.props.getAssignedByStatus()
            })
        }
    }
    close() {
        this.setState({ leavePopupState: false })
    }
    dateHandler(e, type) {
        if (type === 'start') {
            this.setState({ leaveStartDate: e.target.value })
        }
        if (type === 'end') {
            this.setState({ leaveEndDate: e.target.value })

        }

    }
    saveLeave() {
        saveAssignByStatusAPINewLeave(this.state.agentData.status, this.state.agentData.emailId, this.state.agentData.emplId, this.state.agentData.salaryBucket, this.state.leaveStartDate, this.state.leaveEndDate, callback => {
            this.props.getAssignedByStatus()
            this.setState({leavePopupState:false})

        })
    }
    render() {
        const { userStatusData } = this.props
        return (
            <div className='container-fluid details-new'>
                {this.state.leavePopupState ?
                    <LeavePopup saveLeave={this.saveLeave.bind(this)} dateHandler={this.dateHandler.bind(this)} close={this.close.bind(this)} />
                    : ""}

                <div className="phocket-table-new text-center">
                    <table>
                        <thead>
                            <tr>
                                <th className='text-center'>Agent Email Id</th>
                                <th className='text-center'>Mark Leave</th>
                                <th className='text-center'>Leave Status</th>
                                <th className='text-center'>From Date</th>
                                <th className='text-center'>To Date</th>
                                <th className='text-center'>Salary Bucket</th>
                                <th className='text-center'>Remove Agent</th>

                            </tr>
                        </thead>
                        <tbody>
                            {userStatusData !== undefined && userStatusData !== '' ? userStatusData.map((data, i) => {
                                return (
                                    <tr >
                                        <td>{data.emailId}</td>
                                        <td><button className='btn btn-default' onClick={() => this.markLeave(data)} style={{ borderRadius: '6px', border: '1px solid #267DFF', background: '#FFF', color: '#267DFF' }}><i className='fa fa-calendar'></i>&nbsp;&nbsp;{data.presentStatus ? 'Mark Leave' : 'Unmark Leave'}</button></td>
                                        <td>{data.presentStatus ? <div style={{ borderRadius: '6px', background: '#E1F6EC', color: '#4CC78C', padding: '10px', fontWeight: '700' }}>Present</div> : <div style={{ borderRadius: '6px', background: '#FFE1DBA6', color: '#E63946', padding: '10px', fontWeight: '700' }}>Absent</div>}</td>
                                        <td>{data.leaveStartDate?moment(data.leaveStartDate).format('DD-MM-YYYY'):''}</td>
                                        <td>{data.leaveEndDate?moment(data.leaveEndDate).format('DD-MM-YYYY'):''}</td>
                                        <td>{data.salaryBucket}</td>
                                        <td><i className='fa fa-trash' style={{ cursor: 'pointer' }} onClick={() => this.deleteEntry(data.id)}></i></td>
                                    </tr>
                                )
                            }) : ""}

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
export default ListingRule