import React, { useState } from 'react'
import AggregatorRegistration from '../../../registrationForm'
import BudgetPlan from '../../../budgetPlan'
import Configuration from '../configuration'
const ConfigPage = ({ setViewState, setDetailsShow, editdata, pageViewHandler }) => {
    const [tab, setTab] = useState('add')
    return (
        <>
            <div className="" style={{ marginTop: window.innerWidth > 1800 ? '0px':'33px' }}>
                <div className="row">

                    <div className='' style={{paddingTop:window.innerWidth >1800 ? '20px':'', background: '#1D3557', height: '100%', width: '5%', minWidth: '100px', position: 'fixed' }}>

                        {/* <div className="row" style={{ marginTop: '25px' }}>
                            <div onClick={() => setTab('add')} className={tab === 'add' ? 'campaignTabactive col-sm-4 col-xs-12' : 'campaignTab col-sm-4 col-xs-12'}>
                                <div >
                                    <div className="text-center" >{editdata ? 'Update Agreegator' : 'Add Agreegators'}</div>
                                </div>
                            </div>
                            <div onClick={() => setTab('config')} className={tab === 'config' ? 'campaignTabactive col-sm-4 col-xs-12' : 'campaignTab col-sm-4 col-xs-12'}>
                                <div >
                                    <div className="text-center" >{editdata ? 'Update Configuration' : 'Add Configuration'} </div>
                                </div>
                            </div>
                            <div onClick={() => setTab('budget')} className={tab === 'budget' ? 'campaignTabactive col-sm-4 col-xs-12' : 'campaignTab col-sm-4 col-xs-12'}>
                                <div>
                                    <div className="text-center" > {editdata ? 'Update Budget Plan' : 'Add Budget Plan'}</div>
                                </div>
                            </div>
                        </div> */}
                        <div className="aggDetailsTab" style={{ color: '#fff' }} >
                            <a
                                className="tabText"
                                style={{ color: '#fff' }}
                                onClick={() => setTab('add')}
                            >
                                <div className={tab === 'add' ? 'active-box' : 'inactive-box'}>
                                    <i class="fa fa-users" aria-hidden="true"></i>
                                </div>
                                {editdata ? 'Update' : 'Add'}<br />Agreegator
                            </a>
                        </div>
                        <div className="aggDetailsTab" style={{ color: '#fff' }} >
                            <a
                                className="tabText"
                                style={{ color: '#fff' }}
                                onClick={() => setTab('config')}
                            >
                                <div className={tab === 'config' ? 'active-box' : 'inactive-box'}>
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                </div>
                                {/* {editdata ? 'Update Configuration' : 'Add Configuration'} */}
                                {editdata ? 'Update' : 'Add'}<br />Configuration

                            </a>
                        </div>
                        <div className="aggDetailsTab" style={{ color: '#fff' }} >
                            <a
                                className="tabText"
                                style={{ color: '#fff' }}
                                onClick={() => setTab('budget')}
                            >
                                <div className={tab === 'budget' ? 'active-box' : 'inactive-box'}>
                                    <i class="fa fa-inr" aria-hidden="true"></i>
                                </div>
                                {/* {editdata ? 'Update Budget Plan' : 'Add Budget Plan'} */}
                                {editdata ? 'Update' : 'Add'}<br/>Budget Plan

                            </a>
                        </div>

                    </div>
                </div>
                <div style={{ width: 'calc(95%-100px)', marginLeft: '100px' }}>

                    {tab === 'add' ?
                        <AggregatorRegistration editdata={editdata}  pageViewHandler={pageViewHandler}/>
                        : ""}
                    {tab === 'budget' ?
                        <BudgetPlan editdata={editdata} pageViewHandler={pageViewHandler}/>
                        : ""}
                    {tab === 'config' ?
                        <Configuration editdata={editdata} pageViewHandler={pageViewHandler}/>
                        : ""}
                </div>
            </div>
        </>
    )
}
export default ConfigPage