import React, { Component } from 'react'
import { TYPE, STRING } from '../../../../../utils/Constant'
import { calculateEmiNbfcApi } from '../../AdminActionCreator.component'
import {
  approveLoanApi,
  getNbfcList,
  approveGiftCardApi
} from '../../AdminActionCreator.component'
import ApproveDataPopup from '../../../../presentationals/Popup/approveData.component'
import { _formatDateInDash, _preFormatDate } from '../../../../../utils/validation'
import SidePopUp from '../../../../presentationals/Popup/Popup.component'
import moment, { months } from 'moment'
import { APIS } from '../../../../../utils/api-factory'

class ApproveData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nbfc: 'ATcUsLVH',
      popState: false,
      popupStatus: '',
      nbfcList: '',
      nbfcIdState: '',
      approveData: {
        approveDate: _formatDateInDash(new Date()),
        approveDateToShow: new Date(),
        salaryDate: '',
        salaryDateToShow: '',
        selectEMI: '',
        ApproveAmount: '',
        errorMsg: '',
        nbfcId: 'ATcUsLVH',
        // company:'',
        subStatus: null
      },
      company: '',
      emiTableData: [],
      disabled: false,
      giftCardMonth: '',
      belowoneLakhState: false,
      rangeVreedhi: '',
      sendMail: true
    }
  }
  sendMailHandler(e) {
    this.setState({ sendMail: !this.state.sendMail })
  }

  componentWillMount() {
    const { allDetail, admin, userDetails } = this.props
    this.setState({ company: userDetails.giftCardType })
    let approveData = Object.assign({}, this.state.approveData)

    approveData.salaryDateToShow = (allDetail.employmentDetailsPO.salaryDay)
    approveData.selectEMI = allDetail.loanEmi
    approveData.salaryDate =
      allDetail != null &&
        allDetail.employmentDetailsPO != null &&
        allDetail.employmentDetailsPO.salaryDay != null
        ? allDetail.employmentDetailsPO.salaryDay +
        '-' +
        moment(new Date())
          .add(1, 'months')
          .format('MM-YYYY')
        : null
    if (userDetails.ivrStatus == 1 && userDetails == 'Loan') {
      approveData.ApproveAmount =
        allDetail != null && allDetail.tentativeAmount != null
          ? allDetail.tentativeAmount
          : null
      allDetail.emiPaybackDate != null
        ? allDetail.emiPaybackDate.map((emi, i) => {

          approveData['repaymentDate' + i] = emi
          approveData['repaymentDateToShow' + i] = moment(
            emi,
            'DD-MM-YYYY'
          )._d
        })
        : null
      this.setState({ emiTableData: allDetail.emiPaybackDate, approveData })
    } else {
      approveData.ApproveAmount =
        allDetail != null && allDetail.loanAmount != null
          ? allDetail.loanAmount
          : null
      let nbfcName =
        admin.nbfcName != null && admin.nbfcName != '' ? admin.nbfcName : 'nbfc'
      if (userDetails.typeOfProduct == 'Loan') {
        calculateEmiNbfcApi(
          userDetails.loanAmount,
          allDetail.loanEmi,
          allDetail.employmentDetailsPO.salaryDay,
          userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
          callBack => {
            callBack.length > 0
              ? callBack.map((emi, i) => {
                if (emi.Date != undefined) {
                  approveData['repaymentDate' + i] = emi.Date
                  approveData['repaymentDateToShow' + i] = moment(
                    emi.Date,
                    'DD-MM-YYYY'
                  )._d
                }
              })
              : null

            this.setState({ emiTableData: callBack, approveData })
          }
        )
      } else if (userDetails.typeOfProduct == 'Gift Card') {
        let approveData = Object.assign({}, this.state.approveData)
        approveData.selectEMI = '3'
        calculateEmiNbfcApi(
          userDetails.loanAmount,
          '3',
          allDetail.employmentDetailsPO.salaryDay,
          userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
          callBack => {
            callBack.length > 0
              ? callBack.map((emi, i) => {
                if (emi.Date != undefined) {
                  approveData['repaymentDate' + i] = emi.Date
                  approveData['repaymentDateToShow' + i] = moment(
                    emi.Date,
                    'DD-MM-YYYY'
                  )._d
                }
              })
              : null

            this.setState({ emiTableData: callBack, approveData })
          }
        )
      }
    }
    this.setState({
      approveData
    })
  }
  componentDidMount() {
    const { userDetails, allDetail } = this.props
    getNbfcList(callBack => {
      this.setState({ nbfcList: callBack })
    })
    // let approveData=Object.assign({},this.state.approveData)
    // approveData.company=this.props.userDetails.giftCardType
    // this.setState({approveData})
    fetch(APIS.REPEAT_USER_CHECK + userDetails.userId)
      .then(res => res.json())
      .then(json => {
        // console.log(json.repeatUser)
        this.setState({ repeatUserData: json.repeatUser })
      })
  }
  nbfcHandler(e) {
    this.setState({ nbfc: e.target.value })
    let approveData = Object.assign({}, this.state.approveData)
    approveData.nbfcId = e.target.value
    this.setState({ approveData })
  }
  closePopup() {
    this.setState({ popState: false })
  }
  belowoneLakh(belowoneLakhState) {
    if (belowoneLakhState == true) {
      this.setState({ belowoneLakhState: false })
      let approveData = Object.assign({}, this.state.approveData)
      approveData.ApproveAmount = ''
      this.setState({
        approveData
      })
    }
    else if (belowoneLakhState == false) {
      this.setState({ belowoneLakhState: true })
      let approveData = Object.assign({}, this.state.approveData)
      approveData.ApproveAmount = ''
      this.setState({
        approveData
      })
    }

  }
  salaryhandler(e) {
    const { allDetail, admin, userDetails } = this.props

    let approveData = Object.assign({}, this.state.approveData)
    approveData.salaryDate = e.target.value
    approveData.salaryDateToShow = e.target.value
    // approveData.salaryDateToShow = e
    this.setState({ company: userDetails.giftCardType })
    // approveData.company=userDetails.giftCardType

    let nbfcName =
      admin.nbfcName != null && admin.nbfcName != '' ? admin.nbfcName : 'nbfc'
    if (userDetails.typeOfProduct == 'Loan') {
      calculateEmiNbfcApi(
        userDetails.loanAmount,
        this.state.approveData.selectEMI,
        e.target.value,
        userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
        callBack => {
          callBack.length > 0
            ? callBack.map((emi, i) => {
              if (emi.Date != undefined) {
                approveData['repaymentDate' + i] = emi.Date
                approveData['repaymentDateToShow' + i] = moment(
                  emi.Date,
                  'DD-MM-YYYY'
                )._d
              }
            })
            : null

          this.setState({ emiTableData: callBack, approveData })
        }
      )
    } else if (userDetails.typeOfProduct == 'Gift Card') {
      let approveData = Object.assign({}, this.state.approveData)
      approveData.selectEMI = '3'
      calculateEmiNbfcApi(
        userDetails.loanAmount,
        '3',
        e.target.value,
        userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
        callBack => {
          callBack.length > 0
            ? callBack.map((emi, i) => {
              if (emi.Date != undefined) {
                approveData['repaymentDate' + i] = emi.Date
                approveData['repaymentDateToShow' + i] = moment(
                  emi.Date,
                  'DD-MM-YYYY'
                )._d
              }
            })
            : null

          this.setState({ emiTableData: callBack, approveData })
        }
      )
    }

    this.setState({
      approveData
    })

  }
  render() {
    const { admin, userDetails } = this.props
    return (
      <div>
        <ApproveDataPopup
          sendMail={this.state.sendMail}
          sendMailHandler={this.sendMailHandler.bind(this)}
          salaryhandler={this.salaryhandler.bind(this)}
          rangeVreedhi={this.state.rangeVreedhi}
          repeatUserData={this.state.repeatUserData}
          company={this.state.company}
          userDetails={userDetails}
          giftCardMonth={this.state.giftCardMonth}
          nbfc={this.state.nbfc}
          nbfcHandler={this.nbfcHandler.bind(this)}
          nbfcList={this.state.nbfcList}
          approveData={this.state.approveData}
          closeApprovePopup={this.closeApprovePopup.bind(this)}
          approveDataHandler={this.approveDataHandler.bind(this)}
          approveDataChange={this.approveDataChange.bind(this)}
          emiTableData={this.state.emiTableData}
          errorMsg={this.state.errorMsg}
          disabled={this.state.disabled}
          admin={admin}
          belowoneLakhState={this.state.belowoneLakhState}
          belowoneLakh={this.belowoneLakh.bind(this)}
        />
        {this.state.popState ? (
          <SidePopUp
            popupStatus={this.state.popupStatus}
            closePopup={this.closePopup.bind(this)}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
  vreedhiRepaymentDate(data) {
    const { allDetail, admin, nbfcNameForApprove, userDetails } = this.props
    let approveData = Object.assign({}, this.state.approveData)

    calculateEmiNbfcApi(
      userDetails.loanAmount,
      data,
      allDetail.employmentDetailsPO.salaryDay,
      userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
      callBack => {
        callBack.length > 0
          ? callBack.map((emi, i) => {
            if (emi.Date != undefined) {
              approveData['repaymentDate' + i] = emi.Date
              approveData['repaymentDateToShow' + i] = moment(
                emi.Date,
                'DD-MM-YYYY'
              )._d
            }
          })
          : null

        this.setState({ emiTableData: callBack, approveData })
      }
    )
  }

  approveDataChange(e, dataType, index) {
    const { allDetail, admin, nbfcNameForApprove, userDetails } = this.props
    let nbfcName =
      admin.nbfcName != null && admin.nbfcName != '' ? admin.nbfcName : 'nbfc'
    let approveData = Object.assign({}, this.state.approveData)
    if (dataType == TYPE.LOAN_APPROVE_DATE) {
      approveData.approveDate = _formatDateInDash(e)
      approveData.approveDateToShow = e
      let nextmonthone = moment(e).add(30, 'days')._d
      let nextmonthtwo = _preFormatDate(moment(e).add(60, 'days')._d)
      let nextmonththree = _preFormatDate(moment(e).add(90, 'days')._d)
      let nextmonthfour = _preFormatDate(moment(e).add(120, 'days')._d)
      let nextmonthfive = _preFormatDate(moment(e).add(150, 'days')._d)
      let nextmonthsix = _preFormatDate(moment(e).add(180, 'days')._d)
      let nextmonthseven = _preFormatDate(moment(e).add(210, 'days')._d)
      let nextmontheight = _preFormatDate(moment(e).add(240, 'days')._d)
      let nextmonthnine = _preFormatDate(moment(e).add(270, 'days')._d)
      let nextmonthten = _preFormatDate(moment(e).add(300, 'days')._d)
      let nextmontheleleven = _preFormatDate(moment(e).add(330, 'days')._d)
      let nextmonthtwelve = _preFormatDate(moment(e).add(360, 'days')._d)


      // console.log(nextmonththree)
      if (this.state.approveData.selectEMI == 1) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
      }
      if (this.state.approveData.selectEMI == 2) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
        approveData['repaymentDate' + 1] = (nextmonthtwo)

      }
      if (this.state.approveData.selectEMI == 3) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
        approveData['repaymentDate' + 1] = (nextmonthtwo)
        approveData['repaymentDate' + 2] = (nextmonththree)

      }
      if (this.state.approveData.selectEMI == 4) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
        approveData['repaymentDate' + 1] = (nextmonthtwo)
        approveData['repaymentDate' + 2] = (nextmonththree)
        approveData['repaymentDate' + 3] = (nextmonthfour)

      }
      if (this.state.approveData.selectEMI == 5) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
        approveData['repaymentDate' + 1] = (nextmonthtwo)
        approveData['repaymentDate' + 2] = (nextmonththree)
        approveData['repaymentDate' + 3] = (nextmonthfour)
        approveData['repaymentDate' + 4] = (nextmonthfive)
      }
      if (this.state.approveData.selectEMI == 6) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
        approveData['repaymentDate' + 1] = (nextmonthtwo)
        approveData['repaymentDate' + 2] = (nextmonththree)
        approveData['repaymentDate' + 3] = (nextmonthfour)
        approveData['repaymentDate' + 4] = (nextmonthfive)
        approveData['repaymentDate' + 5] = (nextmonthsix)

      } if (this.state.approveData.selectEMI == 7) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
        approveData['repaymentDate' + 1] = (nextmonthtwo)
        approveData['repaymentDate' + 2] = (nextmonththree)
        approveData['repaymentDate' + 3] = (nextmonthfour)
        approveData['repaymentDate' + 4] = (nextmonthfive)
        approveData['repaymentDate' + 5] = (nextmonthsix)
        approveData['repaymentDate' + 6] = (nextmonthseven)
      }
      if (this.state.approveData.selectEMI == 8) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
        approveData['repaymentDate' + 1] = (nextmonthtwo)
        approveData['repaymentDate' + 2] = (nextmonththree)
        approveData['repaymentDate' + 3] = (nextmonthfour)
        approveData['repaymentDate' + 4] = (nextmonthfive)
        approveData['repaymentDate' + 5] = (nextmonthsix)
        approveData['repaymentDate' + 6] = (nextmonthseven)
        approveData['repaymentDate' + 7] = (nextmontheight)
      }
      if (this.state.approveData.selectEMI == 9) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
        approveData['repaymentDate' + 1] = (nextmonthtwo)
        approveData['repaymentDate' + 2] = (nextmonththree)
        approveData['repaymentDate' + 3] = (nextmonthfour)
        approveData['repaymentDate' + 4] = (nextmonthfive)
        approveData['repaymentDate' + 5] = (nextmonthsix)
        approveData['repaymentDate' + 6] = (nextmonthseven)
        approveData['repaymentDate' + 7] = (nextmontheight)
        approveData['repaymentDate' + 8] = (nextmonthnine)

      } if (this.state.approveData.selectEMI == 10) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
        approveData['repaymentDate' + 1] = (nextmonthtwo)
        approveData['repaymentDate' + 2] = (nextmonththree)
        approveData['repaymentDate' + 3] = (nextmonthfour)
        approveData['repaymentDate' + 4] = (nextmonthfive)
        approveData['repaymentDate' + 5] = (nextmonthsix)
        approveData['repaymentDate' + 6] = (nextmonthseven)
        approveData['repaymentDate' + 7] = (nextmontheight)
        approveData['repaymentDate' + 8] = (nextmonthnine)
        approveData['repaymentDate' + 9] = (nextmonthten)
      }
      if (this.state.approveData.selectEMI == 11) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
        approveData['repaymentDate' + 1] = (nextmonthtwo)
        approveData['repaymentDate' + 2] = (nextmonththree)
        approveData['repaymentDate' + 3] = (nextmonthfour)
        approveData['repaymentDate' + 4] = (nextmonthfive)
        approveData['repaymentDate' + 5] = (nextmonthsix)
        approveData['repaymentDate' + 6] = (nextmonthseven)
        approveData['repaymentDate' + 7] = (nextmontheight)
        approveData['repaymentDate' + 8] = (nextmonthnine)
        approveData['repaymentDate' + 9] = (nextmonthten)
        approveData['repaymentDate' + 10] = (nextmontheleleven)

      }
      if (this.state.approveData.selectEMI == 12) {
        approveData['repaymentDate' + 0] = _preFormatDate(nextmonthone)
        approveData['repaymentDate' + 1] = (nextmonthtwo)
        approveData['repaymentDate' + 2] = (nextmonththree)
        approveData['repaymentDate' + 3] = (nextmonthfour)
        approveData['repaymentDate' + 4] = (nextmonthfive)
        approveData['repaymentDate' + 5] = (nextmonthsix)
        approveData['repaymentDate' + 6] = (nextmonthseven)
        approveData['repaymentDate' + 7] = (nextmontheight)
        approveData['repaymentDate' + 8] = (nextmonthnine)
        approveData['repaymentDate' + 9] = (nextmonthten)
        approveData['repaymentDate' + 10] = (nextmontheleleven)
        approveData['repaymentDate' + 11] = (nextmonthtwelve)

      }
    } else if (dataType == TYPE.LOAN_AMOUNT) {
      // approveData.ApproveAmount = e.target.value
      if (this.state.belowoneLakhState == false && e.target.value <= 100000 && userDetails.loanFrom !== 'vreedhi') {
        approveData.ApproveAmount = e.target.value
      } else if (this.state.belowoneLakhState == false && e.target.value > 100000 && userDetails.loanFrom !== 'vreedhi') {
        alert('Kindly tick the check box provided')

      }
      if (this.state.belowoneLakhState == true) {
        approveData.ApproveAmount = e.target.value
      }
      if (userDetails.loanFrom === 'vreedhi') {

        if (e.target.value === '50000') {
          approveData.ApproveAmount = e.target.value
          approveData.selectEMI = '42'
          calculateEmiNbfcApi(
            userDetails.loanAmount,
            '42',
            this.state.approveData.salaryDateToShow,
            userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
            callBack => {
              callBack.length > 0
                ? callBack.map((emi, i) => {
                  if (emi.Date != undefined) {
                    approveData['repaymentDate' + i] = emi.Date
                    approveData['repaymentDateToShow' + i] = moment(
                      emi.Date,
                      'DD-MM-YYYY'
                    )._d
                  }
                })
                : null

              this.setState({ emiTableData: callBack, approveData })
            }
          )

        }
        if (e.target.value === '70000') {
          approveData.ApproveAmount = e.target.value
          approveData.selectEMI = '48'
          calculateEmiNbfcApi(
            userDetails.loanAmount,
            '48',
            this.state.approveData.salaryDateToShow,
            userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
            callBack => {
              callBack.length > 0
                ? callBack.map((emi, i) => {
                  if (emi.Date != undefined) {
                    approveData['repaymentDate' + i] = emi.Date
                    approveData['repaymentDateToShow' + i] = moment(
                      emi.Date,
                      'DD-MM-YYYY'
                    )._d
                  }
                })
                : null

              this.setState({ emiTableData: callBack, approveData })
            }
          )

        }
        if (e.target.value === '100000') {
          approveData.ApproveAmount = e.target.value
          approveData.selectEMI = '52'
          calculateEmiNbfcApi(
            userDetails.loanAmount,
            '52',
            this.state.approveData.salaryDateToShow,
            userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
            callBack => {
              callBack.length > 0
                ? callBack.map((emi, i) => {
                  if (emi.Date != undefined) {
                    approveData['repaymentDate' + i] = emi.Date
                    approveData['repaymentDateToShow' + i] = moment(
                      emi.Date,
                      'DD-MM-YYYY'
                    )._d
                  }
                })
                : null

              this.setState({ emiTableData: callBack, approveData })
            }
          )

        }
        if (e.target.value === '125000') {
          approveData.ApproveAmount = e.target.value
          approveData.selectEMI = '56'
          calculateEmiNbfcApi(
            userDetails.loanAmount,
            '56',
            this.state.approveData.salaryDateToShow,
            userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
            callBack => {
              callBack.length > 0
                ? callBack.map((emi, i) => {
                  if (emi.Date != undefined) {
                    approveData['repaymentDate' + i] = emi.Date
                    approveData['repaymentDateToShow' + i] = moment(
                      emi.Date,
                      'DD-MM-YYYY'
                    )._d
                  }
                })
                : null

              this.setState({ emiTableData: callBack, approveData })
            }
          )

        }
        if (e.target.value === '150000') {
          approveData.ApproveAmount = e.target.value
          approveData.selectEMI = '65'
          calculateEmiNbfcApi(
            userDetails.loanAmount,
            '65',
            this.state.approveData.salaryDateToShow,
            userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
            callBack => {
              callBack.length > 0
                ? callBack.map((emi, i) => {
                  if (emi.Date != undefined) {
                    approveData['repaymentDate' + i] = emi.Date
                    approveData['repaymentDateToShow' + i] = moment(
                      emi.Date,
                      'DD-MM-YYYY'
                    )._d
                  }
                })
                : null

              this.setState({ emiTableData: callBack, approveData })
            }
          )

        }
        if (e.target.value === '200000') {
          approveData.ApproveAmount = e.target.value
          approveData.selectEMI = '78'
          calculateEmiNbfcApi(
            userDetails.loanAmount,
            '78',
            this.state.approveData.salaryDateToShow,
            userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
            callBack => {
              callBack.length > 0
                ? callBack.map((emi, i) => {
                  if (emi.Date != undefined) {
                    approveData['repaymentDate' + i] = emi.Date
                    approveData['repaymentDateToShow' + i] = moment(
                      emi.Date,
                      'DD-MM-YYYY'
                    )._d
                  }
                })
                : null

              this.setState({ emiTableData: callBack, approveData })
            }
          )

        }




      }
    } else if (dataType == TYPE.SELECT_EMI) {
      approveData.selectEMI = e.target.value
      if (userDetails.loanFrom === 'vreedhi') {
        if (e.target.value < 26 || e.target.value > 104) {
          e.target.value = ''
          this.setState({ rangeVreedhi: 'Please Enter Between 26 to 104 Weeks' })
        } else {
          this.setState({ rangeVreedhi: '' })

        }
      }
      calculateEmiNbfcApi(
        userDetails.loanAmount,
        e.target.value,
        this.state.approveData.salaryDateToShow,
        userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
        callBack => {
          callBack.length > 0
            ? callBack.map((emi, i) => {
              if (emi.Date != undefined) {
                approveData['repaymentDate' + i] = emi.Date
                approveData['repaymentDateToShow' + i] = moment(
                  emi.Date,
                  'DD-MM-YYYY'
                )._d
              }
            })
            : null

          this.setState({ emiTableData: callBack, approveData })
        }
      )

    } else if (dataType == TYPE.FIRST_REPAYMENT_DATE) {
      this.state.emiTableData.length > 0
        ? this.state.emiTableData.map((emi, i) => {
          if (i == index) {
            if (index == 0) {
              approveData['repaymentDateToShow' + i] = e
              approveData['repaymentDate' + i] = _formatDateInDash(e)
            } else if (index > 0) {
              let previousEmi = moment(
                approveData['repaymentDate' + (index - 1)],
                'DD-MM-YYYY'
              )
              let currentEmi = moment(new Date(e))
              let emiDateDiff = currentEmi.diff(previousEmi, 'days')
              if (emiDateDiff >= 25 && emiDateDiff <= 35) {
                approveData['repaymentDateToShow' + i] = e
                approveData['repaymentDate' + i] = _formatDateInDash(e)
                this.setState({
                  errorMsg: ''
                })
              } else {
                this.setState({
                  errorMsg:
                    'Difference between 2 emis should be 25-35 days and your date difference is ' +
                    Number(emiDateDiff) +
                    ' days.'
                })
              }
            }
          }
        })
        : null
    } else if (dataType == 'company') {
      // approveData.company=e.target.value
      this.setState({ company: e.target.value })
    } else if (dataType == 'substatus') {
      approveData.subStatus = e.target.value
    }
    this.setState({
      approveData
    })
  }

  approveDataHandler() {
    const { allDetail, admin, reason, mailState, score, userDetails } = this.props
    let paybackDates = []
    if (this.state.rangeVreedhi === '') {
      if (userDetails.loanFrom !== 'vreedhi') {
        this.state.emiTableData != null && this.state.emiTableData.length > 0
          ? this.state.emiTableData.map((emi, i) => {
            let dateValue = document.getElementById('RepaymentDate_' + i).value
            paybackDates.push(dateValue)

          })
          : null
      } else {
        this.state.emiTableData != null && this.state.emiTableData.length > 0
          ? this.state.emiTableData.map((emi, i) => {
            let dateValue = this.state.approveData['repaymentDate' + i]
            paybackDates.push(dateValue)

          })
          : null
      }


      if (
        this.state.approveData.nbfcId != null &&
        this.state.approveData.nbfcId != ''
      ) {
        this.setState({ disabled: true })
        if (userDetails.typeOfProduct == 'Loan') {
          approveLoanApi(
            userDetails,
            STRING.APPROVED_STATUS,
            reason,
            this.state.approveData,
            this.state.sendMail,
            score,
            admin.emailId,
            paybackDates,
            userDetails.loanFrom !== "vreedhi" ? admin.nbfcName : 'vreedhi',
            callBack => {
              this.closeApprovePopup(callBack)
              this.setState({ disabled: false })
            }
          )
        } else if (userDetails.typeOfProduct == 'Gift Card') {
          this.setState({
            giftCardMonth: STRING.THIRD_EMI
          })
          if (this.state.approveData.company != '') {
            approveGiftCardApi(
              userDetails,
              STRING.APPROVED_STATUS,
              this.state.approveData,
              mailState,
              admin.emailId,
              paybackDates,
              userDetails.giftCardId,
              this.state.company,
              callBack => {
                this.closeApprovePopup(callBack)
                this.setState({ disabled: false })
              }
            )
          } else {
            this.setState({
              popState: true,
              popupStatus: 'Please Select Comapny'
            })
          }
        }
      } else {
        this.setState({
          popState: true,
          popupStatus: 'Please Fill Details'
        })
      }
      this.remove()
    }
  }

  remove() {
    setTimeout(
      function () {
        this.setState({ popState: false })
      }.bind(this),
      5000
    )
  }
  closeApprovePopup(res) {
    this.props.closeApprovePopup(res)
  }
}

export default ApproveData
