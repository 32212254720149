import React, { useState, useEffect } from "react";
import FinBit from "./finbitAnalysis";
import Digitap from './digitap'
import Loader from '../../../presentationals/Loader/layerLoader.component'
import { getBankstatementStatus } from '../../../containers/Admin/AdminActionCreator.component'
const FinancialAnalysis = ({ statementSource, allDetail, admin, finbitJson, finbitJsonData,handletabs }) => {
    const [loader, setLoader] = useState(true)
    const [popupstate, setPopupState] = useState(false)
    const [popupstatus, setPopupStatus] = useState('')
    console.log("statementSource",statementSource)
    useEffect(() => {
        getBankstatementStatusData()
    }, [])

    const getBankstatementStatusData = () => {
        getBankstatementStatus(allDetail.userId, (callback) => {
            console.log(callback)
            setLoader(false)
            if (callback?.status == 'failure'){
                setPopupStatus(callback.message)
                setPopupState(true)
            }
            else if (callback?.txn_status[0]?.status == 'Failure') {
                setPopupStatus(callback?.txn_status[0].msg)
                setPopupState(true)
            } 
            // else if (callback?.txn_status[0]?.status == 'Success') {

            // } 
        })
    }


    return (
        <>

    {
        loader ?
        <Loader />
    :''}
            {
                loader === false ?
                    statementSource.digitap === true ?
                        <Digitap allDetail={allDetail} admin={admin} finbitJson={finbitJson} finbitJsonData={finbitJsonData} popup={popupstate} popupvalue={popupstatus} handletabs={handletabs} />
                        :
                        <FinBit allDetail={allDetail} admin={admin} finbitJson={finbitJson} finbitJsonData={finbitJsonData} popup={popupstate} popupvalue={popupstatus}  handletabs={handletabs}/>
                    :
                ''}
        </>
    )
}
export default FinancialAnalysis