import React from 'react'
import { ROLE } from '../../../utils/Constant'
import HomeImage from '../../../images/generate-lead (1).png'
import SecondLogo from '../../../images/Group (5).png'
import ThirdLogo from '../../../images/Vector (25).png'
import FourthLogo from '../../../images/marketing.png'
import FifthLogo from '../../../images/Layer 4.png'
import SixthLogo from '../../../images/access-control.png'
import SeventhLogo from '../../../images/dashboard (1).png'
import EighthLogo from '../../../images/Digital Key.png'
import NinthLogo from '../../../images/Group (6).png'

const SettingsNavOptions = ({
    navItemClick,
    pageViewHandler,
    admin,
    menuOpenState,
    generateReportState,
    subHandleTab,
    losSubHandle, pageViewState, lmsSubTab
}) => (
    <div className='phocket-crm-menu new-menu animate-crm-menu  ' id='CRMMenu'>
        <ul className='mail-list-new'>
            {admin.rolelist != undefined ?
                (admin.rolelist.indexOf(ROLE.LOAN_APPROVAL_LEADS) >= 0 || admin.rolelist.indexOf(ROLE.LEAD_MANAGEMENT) >= 0 || admin.rolelist.indexOf(ROLE.APPLICATION_STATUS) || admin.rolelist.indexOf(ROLE.Gift_Card_Leads) >= 0 || admin.rolelist.indexOf(ROLE.Vreedhi_Leads) >= 0 || admin.rolelist.indexOf(ROLE.Bada_Business_Leads) >= 0 || admin.rolelist.indexOf(ROLE.Tea_Garden) >= 0 || admin.rolelist.indexOf(ROLE.TE_Gift_Card_Leads) >= 0 || admin.rolelist.indexOf(ROLE.IVR) >= 0 || admin.rolelist.indexOf(ROLE.Approved_To_Disbursed_Disbursed_By_ICICI) >= 0) ?
                    <div className='mail-list' >
                        {/* <li
                            className={menuOpenState === 'los' ? 'mail-list-item-active' : 'mail-list-item'}
                            onClick={e => navItemClick('los')}
                        >
                            <img src={HomeImage} width='20px' style={{ marginTop: '-4px' }} /><span style={{ marginLeft: '10px' }}> LOS </span>{menuOpenState === 'los' ? <i class="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i> : <i class="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i>}
                        </li> */}
                        {menuOpenState == 'los' ? (
                            <>
                                <div className='sub-list' style={{ listStyle: 'none' }}>
                                    {admin.rolelist != undefined ?
                                        admin.rolelist.indexOf(ROLE.LOAN_APPROVAL_LEADS) >= 0 ?
                                            <div
                                                className={pageViewState === 'leadMainPage' ? 'sub-list-item-active' : 'sub-list-item'}
                                                id='leadMainPage'
                                                onClick={e => pageViewHandler('leadMainPage')}
                                            >
                                                All Leads
                                            </div>
                                            : "" : ""}


                                    {admin.rolelist != undefined ?
                                        admin.rolelist.indexOf(ROLE.LEAD_MANAGEMENT) >= 0 ?
                                            <div
                                                className={pageViewState === 'leadManagement' ? 'sub-list-item-active' : 'sub-list-item'}
                                                id='leadMainPage'
                                                onClick={e => pageViewHandler('leadManagement')}
                                            >
                                                Lead Management
                                            </div>
                                            : "" : ""}
                                    {admin.rolelist != undefined ?
                                        admin.rolelist.indexOf(ROLE.APPLICATION_STATUS) >= 0 ?
                                            <div
                                                className={pageViewState === 'AppStatus' ? 'sub-list-item-active' : 'sub-list-item'}
                                                id='leadMainPage'
                                                // onClick={e => subHandleTab('allapplication', 'los')}
                                                onClick={e => pageViewHandler('AppStatus')}
                                            >
                                                All Applications
                                                {/* {losSubHandle === 'allapplication' ? <i class="fa fa-caret-up" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i> : <i class="fa fa-caret-down" aria-hidden="true" style={{ float: 'right', color: '#fff' }}></i>} */}
                                            </div> : "" : ""}


                                    {/* {admin.rolelist != undefined ?
                                        admin.rolelist.indexOf(ROLE.Gift_Card_Leads) >= 0 ?
                                            <div
                                                className={pageViewState === 'giftCardLeads' ? 'sub-list-item-active' : 'sub-list-item'}

                                                id='giftCardLeads'
                                                onClick={e => pageViewHandler('giftCardLeads')}
                                            >
                                                Gift Card Leads
                                            </div>
                                            : "" : ""} */}
                                    {/* {admin.rolelist != undefined ?
                                        admin.rolelist.indexOf(ROLE.Gift_Card_Leads) >= 0 ?
                                            <div
                                                className={pageViewState === 'kycPendingLeads' ? 'sub-list-item-active' : 'sub-list-item'}

                                                id='kycPendingLeads'
                                                onClick={e => pageViewHandler('kycPendingLeads')}
                                            >
                                                KYC Pending Leads
                                            </div>
                                            : "" : ""}
                                    {/* {admin.rolelist != undefined ?
                                        admin.rolelist.indexOf(ROLE.Vreedhi_Leads) >= 0 ?
                                            <li
                                                className={pageViewState === 'vreedhiLeads' ? 'sub-list-item-active' : 'sub-list-item'}
                                                id='vreedhiLeads'
                                                onClick={e => pageViewHandler('vreedhiLeads')}
                                            >
                                                Vreedhi Leads
                                            </li>
                                            : "" : ""}
                                    {admin.rolelist != undefined ?
                                        admin.rolelist.indexOf(ROLE.Bada_Business_Leads) >= 0 ?
                                            <li
                                                className={pageViewState === 'BadaBuisnessLeads' ? 'sub-list-item-active' : 'sub-list-item'}
                                                id='BadaBuisnessLeads'
                                                onClick={e => pageViewHandler('BadaBuisnessLeads')}
                                            >
                                                Bada Buisness Leads
                                            </li>
                                            : "" : ""}
                                    {admin.rolelist != undefined ?
                                        admin.rolelist.indexOf(ROLE.Tea_Garden) >= 0 ?
                                            <li
                                                className={pageViewState === 'teaGardenLeads' ? 'sub-list-item-active' : 'sub-list-item'}
                                                id='garden'
                                                onClick={e => pageViewHandler('teaGardenLeads')}
                                            >
                                                Tea Garden Leads
                                            </li>
                                            : "" : ""}
                                    {admin.rolelist != undefined ?
                                        admin.rolelist.indexOf(ROLE.TE_Gift_Card_Leads) >= 0 ?
                                            <li
                                                className={pageViewState === 'teaGardenGiftCardLeads' ? 'sub-list-item-active' : 'sub-list-item'}
                                                id='garden'
                                                onClick={e => pageViewHandler('teaGardenGiftCardLeads')}
                                            >
                                                Tea Garden Gift Card Leads
                                            </li>
                                            : "" : ""} */}
                                    {admin.rolelist != undefined ?
                                        admin.rolelist.indexOf(ROLE.IVR) >= 0 ?
                                            <li
                                                className={pageViewState === 'inBondReport' ? 'sub-list-item-active' : 'sub-list-item'}
                                                id='garden'
                                                onClick={e => pageViewHandler('inBondReport')}
                                            >
                                                IVR
                                            </li>
                                            : "" : ""}





                                </div>
                            </>
                        ) : ""}


                    </div>
                    : "" : ""}

        </ul>
    </div>
)

export default SettingsNavOptions