import React from 'react'
import { ACCOUNTDETAIL, MAIL_STRING } from '../../../utils/Constant'

// subject: Reminder: Phocket Loan Due (without NACH).

const EmiLoanDueWithoutNach = ({ allDetail }) => (
    <div>
        <div>Hi {allDetail != null && allDetail.userName != null ? allDetail.userName : ""},</div>
        <br />
        <div>Kindly note the EMI amount of INR {allDetail != null && allDetail.installmentAmount != null ? allDetail.installmentAmount : ""} is due on {allDetail != null && allDetail.installmentDate != null ? allDetail.installmentDate : null}.</div>
        <br />
        <div>You can pay through Netbanking to this account: </div>
        <br />
        <div>{ACCOUNTDETAIL.COMPANY_NAME}</div>
        <div>{ACCOUNTDETAIL.BANK_NAME}</div>
        <div>{ACCOUNTDETAIL.BRANCH_NAME}</div>
        <div>{ACCOUNTDETAIL.ACCOUNT_NUMBER}</div>
        <div>{ACCOUNTDETAIL.IFSC_CODE}</div>
        <br />
        <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default EmiLoanDueWithoutNach