import React from 'react'
import Finbitui from './digitapcomponent'
import { getFinBitData, getFinBitDataNew, saveUnderWriterApi, getDigitapBasicData, getFraudFilterList, getDigitapDataByType ,getBankstatementStatus} from '../../../containers/Admin/AdminActionCreator.component'
import Loader from '../../../presentationals/Loader/layerLoader.component'
import { _preFormatDate, _formatDateInDash } from '../../../../utils/validation'
import { APIS } from '../../../../utils/api-factory'
import PopupSuccess from '../../../presentationals/Popup/Successpopup.component'
import PopUp from '../../../presentationals/Popup/ErrorPopUp.component'
import CheckReport from '../../../presentationals/Popup/finbitgeneratepopup'
import PopUpNew from '../../../presentationals/Popup/Popup.component'
import { getNotesnew, saveCommentForCreditAssesment } from '../Analysis/analysisactionCreator'
import moment from 'moment'
let paginationNumber = 20;
let jsondata = {}
class FinBit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zoomStateAnalysis: false,
            finBitData: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            loaderState: false,
            type: 'transactions',
            viewFalse: true,
            finBitDataNew: '',
            datePickerState: false,
            endDate: _preFormatDate(new Date()),
            startDate: _preFormatDate(new Date()),
            bankData: '',
            popupStatus: '',
            popupStateSuccess: false,
            popupStateSuccess: false,
            selectedFilter: '',
            bankDataFibit: '',
            fraudData: '',
            analysisData: '',
            finbitPopup: false,
            regenerate: '',
            pageNumber: 1,
            viewPage: 'info',
            popupstate: false,
            underWriterStatus: '',
            newNotes: '',
            notesPopup: false,
            notesType: '',
            notesText: '',
            filterType: 'financialDecision',
            suffixDate: '',
            fraudanalysisdropdown: [],
            fraudType: '',
            zoomState: false,
            analysisdatakey: [
                "Opening",
                "Closing Balance",
                "Total No of Salary Credits",
                "Total Amount of Salary Credits",
                "Total No. of Credit Transactions",
                "Total Amount of Credit Transactions",
                "Total No. of Debit Transactions",
                "Total Amount of Debit Transactions",
                "No. of EMI / loan payments",
                "Total No. of EMI / loan payments",
                "Total No.of I / W Bounced",
                "Sum of I/W Bounced",
                "Total amount of I / W Bounced",
                "Total Number of Outward Cheque Bounces",
                "totalLoanDisbursal",
                "Total Amount of Loan Disbursal",
                "Total No. of Loan Disbursal",
                "Total No. of I/W Chq Bounced",
                "Total No. of I / W Chq Bounced",
                "inw_chq_bounce_nonTechnical",
                "Total No. of I / W Cheque Bounce Non Technical",
                "total_inw_chq_bounce_nonTechnical",
                "Total Amount of I / W Cheque Bounce Non Technical",
                "salary_count",
                "Average EOD Balance",
                "FOIR",
                "Employer Name",
                "Avg balance till 10th of month",
                "Avg balance till 20th of month",
                "Avg balance till last date of month",
                "Total No. of CreditCard Payment",
                "Total No. of Bills & Utilities",
                "Total No. of Food",
                "Total No. of Alcohol",
                "Total No. of Travel",
                "Total No. of Entertainment & Lifestyle",
                "Total No. of Shopping & Purchase",
                "Total No. of Investment Expense",
                "Total No. of Gaming",
                "Total No. of Personal Loan",
                "Total No. of Home Loan",
                "Total No. of Auto Loan ",
                "Total No. of Insurance",
                "Total No. of Tax Transaction Debit",
                "Total No. of Transfer to Wallet Transaction",
                "Total No. of Reversal Transaction Debit",
                "Total No. of Medical & Personal Care Transaction",
                "Total No. of Charges Transaction",
                "Total No. of Below Minimum Balance Transaction",
                "Total No. of Salary Paid Transaction",
                "Total No. of Transfer Out Transaction",
                "Total No. of Foreign Wallet Transaction",
                "Total No. of Other Category Debit Transaction",
                "Total Amount of Bills & Utilities",
                "Total Amount of Food",
                "Total Amount of Alcohol",
                "Total Amount of Personal Loan",
                "Total Amount of Home Loan",
                "Total Amount of Auto Loan",
                "Total Amount of Travel",
                "Total Amount of Entertainment & Lifestyle",
                "Total Amount of Shopping & Purchase",
                "Total Amount of Investment Expense",
                "Total Amount of Insurance",
                "Total Amount of Tax Transaction Debit",
                "Total Amount of Gaming",
                "Total Amount of Transfer to Wallet Transaction",
                "Total Amount of Reversal Transaction Debit",
                "Total Amount of CreditCard Payment",
                "Total Amount of Cash Withdrawals",
                "Total Amount of Medical & Personal Care Transaction",
                "Total Amount of Charges Transaction",
                "Total Amount of Below Minimum Balance Transaction",
                "Total Amount of Salary Paid Transaction",
                "Total Amount of Transfer Out Transaction",
                "Total Amount of Foreign Wallet Transaction",
                "Total Amount of EMI / loan Payments",
                "Total Amount of Other Category Debit Transaction",
                "Total No. of Mutual Funds",
                "Total Debit Amount of Mutual Funds",
                "Total Expense on Crypto Currency",
                "Total Expense on Share Market",
                "Total No. of Gas Bill Payments",
                "Total Amount of Gas Bill Payments",
                "Total No. of Electricity Bill Payments",
                "Total No. of Mobile & Broadband Bill Payments",
                "Total Amount of Mobile & Broadband Bill Payments",
                "Total Amount of Electricity Bill Payments"]

        }
    }
    componentDidMount() {
        const {popup,popupvalue} = this.props;
        jsondata = this.props.finbitJson
        this.getData()

        if(popup == true){
            this.setState({popupstate: true,popupStatus: popupvalue})
        }
    }

    // getBankstatementStatusData(){
    //     const { allDetail } = this.props
    //     this.setState({loaderState: true})
    //     getBankstatementStatus(allDetail.userId,(callback)=>{
    //         this.setState({loaderState: false})
    //         if(callback?.txn_status[0].status == 'Failure'){
    //             this.setState({ popupstate: true,popupStatus: callback?.txn_status[0].msg,finbitPopup:false })
    //         }else if(callback?.txn_status[0].status == 'Success'){
    //             this.setState({ finbitPopup:true })
    //             this.getData()
    //         }else if(callback?.status == 'failure'){
    //             this.setState({ popupstate: true,popupStatus: callback?.message,finbitPopup:false })
    //         }
    //     })
    // }
    selectData(data, value) {
        if (jsondata.hasOwnProperty(data)) {
            const index = jsondata[data].indexOf(value);
            if (index !== -1) {
                jsondata[data].splice(index, 1);
            } else {
                jsondata[data].push(value);
            }
        } else {
            jsondata[data] = [value];
        }

        console.log(jsondata);
        this.props.finbitJsonData(jsondata)
    }
    getAnalysisData() {
        const { allDetail } = this.props
        fetch(APIS.FINBIT_ANALYSIS_API + allDetail.userId)
            .then(res => res.json())
            .then(json => {
                console.log(json, 'json')
                this.setState({ analysisData: json, })
                if (json.salaryDay) {
                    let date = this.addSuffixToDay(json.salaryDay)
                    this.setState({ suffixDate: date })
                }
            })
    }
    zoomAnalysis() {
        this.setState({ zoomStateAnalysis: !this.state.zoomStateAnalysis })
    }
    getData() {

        this.setState({ loaderState: true, finbitPopup: false })
        const { allDetail } = this.props
        // fetch(APIS.GET_ACCOUNT_DETAILS_USER_ID + allDetail.userId)
        //     .then(res => res.json())
        //     .then(json => {

        //         this.setState({ bankData: json }, () => this.getAnalysisData())
        //     })

        // getFinBitData(allDetail.userId, 'transactionsDetail', this.state.regenerate, callBack => {
        //     if (callBack != 'no data' && callBack.responseCode != 0) {
        //         var x = callBack.transactions
        //         var y = [...x].reverse();
        //         this.setState({
        //             finBitDataNew: y,
        //             finBitData: callBack,
        //             viewFalse: true,
        //             loaderState: false,
        //             // bankDataFibit: callBack.bank_account

        //         })

        //     }
        //     else {
        //         this.setState({ loaderState: false })
        //     }
        //     if (this.state.finBitData && this.state.bankData) {
        //         if (this.state.finBitData.bank_account.accountNo === this.state.bankData.accountNumber) {
        //             this.setState({ popupStatus: 'Only Account Number Matched', popupStateSuccess: true })
        //         }
        //         if (this.state.finBitData.bank_account.ifsCode === this.state.bankData.ifscCode) {
        //             this.setState({ popupStatus: 'Only IFSC Matched', popupStateSuccess: true })
        //         }
        //         if (this.state.finBitData.bank_account.accountNo === this.state.bankData.accountNumber && this.state.finBitData.bank_account.ifsCode === this.state.bankData.ifscCode) {
        //             this.setState({ popupStatus: 'Acount Details Matched', popupStateSuccess: true })

        //         }

        //         if (this.state.finBitData.bank_account.accountNo !== this.state.bankData.accountNumber && this.state.finBitData.bank_account.ifsCode !== this.state.bankData.ifscCode) {
        //             this.setState({ popupStatus: 'Account Details Not Matched', popupStateError: true })

        //         }
        //     }


        // })
        getDigitapBasicData(allDetail.userId, (response) => {
            if (response) {

                this.setState({
                    bankDataFibit: response,
                    loaderState: false,
                })
                this.getAnalysisData()
                this.props.handletabs();

            } else {
                this.setState({
                    loaderState: false,
                })
            }
        })
        getDigitapDataByType(allDetail.userId, 'transactions', '', (response) => {

            if (response != 'no data') {
                var x = response
                var y = [...x].reverse();
                this.setState({
                    finBitDataNew: y,
                    viewFalse: true,
                    loaderState: false,
                    type: 'transactions',
                })
            } else {
                this.setState({
                    loaderState: false,
                })
            }
        })

    }
    closePopup() {
        this.setState({ popupStateSuccess: false, popupStateError: false, popupstate: false, popupStatus: '' })
    }
    searchHandler() {
        const { allDetail } = this.props

        this.setState({ loaderState: true })
        getFinBitDataNew(allDetail.userId, 'bankAccountSummary', this.state.startDate, this.state.endDate, this.state.regenerate, callBack => {
            if (callBack != 'no data') {
                var x = callBack.transactions
                var y = [...x].reverse();
                this.setState({
                    type: 'bankAccountSummary',
                    finBitData: y,
                    viewFalse: true,
                    loaderState: false,

                })
            } else {
                this.setState({ loaderState: false })
            }
        })
    }
    onChangeHandlerDate(e, type) {
        if (type == 'startDate') {
            this.setState({ startDate: _formatDateInDash(e) })
        }
        if (type == 'endDate') {
            this.setState({ endDate: _formatDateInDash(e) })


        }

    }
    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    subtypeHandler(data) {
        const { allDetail } = this.props;
        if (data.target.value !== '' && data.target.value !== undefined && data.target.value !== null) {

            this.setState({
                fraudType: data.target.value, finBitDataNew: '', loaderState: true, type: 'fraud_analysis'
            })
            getDigitapDataByType(allDetail.userId, 'fraud_analysis', data.target.value, (response) => {
                console.log("ress", response);
                if (response) {
                    var x = response.transactions
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        // type:'fraud_analysis',
                        loaderState: false,
                    })
                } else {
                    this.setState({
                        loaderState: false,
                    })
                }
            })
        }
    }
    typeHandler(data) {
        this.props.handletabs();
        const { allDetail } = this.props
        this.setState({ tableMinPagination: 0, tableMaxPagination: 20, selectedFilter: '' })
        if (data.target.value == 'fraud_analysis') {
            this.setState({ loaderState: true, datePickerState: false, finBitDataNew: '', type: data.target.value })
            getFraudFilterList(allDetail.userId, (response) => {
                if (response.length > 0 && response != 'no data') {
                    this.setState({
                        loaderState: false,
                        // type: 'fraud_analysis',
                        fraudanalysisdropdown: response
                    })
                }
                else {
                    this.setState({ loaderState: false })
                }
            })

        }
        if (data.target.value == 'transactions') {
            this.setState({
                fraudanalysisdropdown: [], finBitDataNew: '', type: data.target.value
            })
            getDigitapDataByType(allDetail.userId, 'transactions', '', (response) => {
                console.log(response);
                if (response != 'no data') {
                    var x = response
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        loaderState: false,
                        // type: 'transactions',
                    })
                } else {
                    this.setState({
                        loaderState: false,
                    })
                }
            })
        }
        if (data.target.value == 'salary') {
            this.setState({
                fraudanalysisdropdown: [], finBitDataNew: '', type: data.target.value
            })
            getDigitapDataByType(allDetail.userId, 'salary', '', (response) => {
                console.log("resss", response);
                if (response != 'no data') {
                    var x = response
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        loaderState: false,
                        // type: 'salary',
                    })
                } else {
                    this.setState({
                        loaderState: false,
                    })
                }
            })
        }
        if (data.target.value == 'analysis_data') {
            this.setState({
                fraudanalysisdropdown: [], finBitDataNew: '', type: data.target.value
            })
            getDigitapDataByType(allDetail.userId, 'analysis_data', '', (response) => {
                console.log("resss", response);
                if (response != 'no data') {

                    this.setState({
                        finBitDataNew: response,
                        viewFalse: true,
                        loaderState: false,
                        zoomStateAnalysis:true
                        // type: 'analysis_data',
                    })
                } else {
                    this.setState({
                        loaderState: false,
                    })
                }
            })
        }
        if (data.target.value == 'loan_analysis') {
            this.setState({
                fraudanalysisdropdown: [], finBitDataNew: '', type: data.target.value
            })
            getDigitapDataByType(allDetail.userId, 'loan_analysis', '', (response) => {
                console.log("resss", response);
                if (response != 'no data') {

                    this.setState({
                        finBitDataNew: response,
                        viewFalse: true,
                        loaderState: false,
                        // type: 'loan_analysis',
                    })
                } else {
                    this.setState({
                        loaderState: false,
                    })
                }
            })

        }
        if (data.target.value == 'salary_dates') {
            this.setState({
                fraudanalysisdropdown: [], finBitDataNew: '', type: data.target.value
            })
            getDigitapDataByType(allDetail.userId, 'salary_dates', '', (response) => {
                console.log("resss", response);
                if (response != 'no data') {
                    var x = response
                    var y = [...x].reverse();
                    this.setState({
                        finBitDataNew: y,
                        viewFalse: true,
                        loaderState: false,
                        // type: 'loan_analysis',
                    })
                } else {
                    this.setState({
                        loaderState: false,
                    })
                }
            })

        }

    }
    finterChangeHandler(e) {
        this.setState({ selectedFilter: e.target.value })
    }
    ConfirmCrifDataGenerate(type) {
        if (type === 'yes') {
            this.setState({ regenerate: 1 }, () => this.getData())
        }
        if (type === 'no') {
            this.setState({ regenerate: 0 }, () => this.getData())
        }

    }
    handleChangeNewPagination(e, value) {
        this.setState({ pageNumber: value })
        const newMinPagination = (value - 1) * paginationNumber;
        const newMaxPagination = value * paginationNumber;
        this.setState({ tableMaxPagination: newMaxPagination, tableMinPagination: newMinPagination })


    };
    setViewPage(data) {
        this.setState({ viewPage: data })
    }
    saveUnderWritter = (type) => {
        const { allDetail, admin } = this.props

        let decisionType = 'financialDecision'
        if (this.state.underWriterStatus !== '' && decisionType !== '') {
            saveUnderWriterApi(decisionType, allDetail, this.state.underWriterStatus, admin, callBack => {
                if (callBack === 'success') {
                    this.setState({ underWriterStatus: '', popupstate: true, popupStatus: 'successfully saved !' }, () => this.removePopup())
                } else {
                    this.setState({ underWriterStatus: '', popupstate: true, popupStatus: 'please try again later !' }, () => this.removePopup())
                }
            })
        } else {
            this.setState({ underWriterStatus: '', popupstate: true, popupStatus: 'Please select value !' }, () => this.removePopup())
        }
    }
    removePopup = () => {
        setTimeout(() => {
            this.setState({ popupstate: false })
        }, 5000);
    }
    underWriterHandler = (e) => {
        this.setState({ underWriterStatus: e.target.value })
    }
    closepopupSide = () => {
        this.setState({ popupstate: false })

    }
    openCommentPopup = (data) => {
        const { allDetail } = this.props
        this.setState({ notesPopup: true, notesType: this.state.filterType })
        getNotesnew(allDetail.loanId, allDetail.userId, this.state.filterType, callBack => {
            this.setState({ newNotes: callBack })
        })

    }
    closepopup = () => {

        this.setState({ notesPopup: false, notesText: '', newNotes: '' })
    }
    saveNotesType = () => {
        const { allDetail, admin } = this.props
        if (this.state.notesText !== '' && this.state.notesText !== undefined && this.state.notesText !== null) {
            saveCommentForCreditAssesment(allDetail.loanId, allDetail.userId, this.state.notesText, admin.emailId, this.state.filterType, callBack => {
                this.setState({ notesPopup: false })
                if (callBack === 'success') {
                    getNotesnew(allDetail.loanId, allDetail.userId, this.state.filterType, callBack => {
                        this.setState({ newNotes: callBack })
                    })
                }

            })
        }
    }
    commentChangeHandler = (e, type) => {
        this.setState({ notesText: e.target.value, notesType: type })


    }
    addSuffixToDay(date) {
        const day = date;
        let suffix;

        if (day >= 11 && day <= 13) {
            suffix = "th";
        } else {
            switch (day % 10) {
                case 1:
                    suffix = "st";
                    break;
                case 2:
                    suffix = "nd";
                    break;
                case 3:
                    suffix = "rd";
                    break;
                default:
                    suffix = "th";
            }
        }

        return day + suffix;
    }
    zoomPage() {
        if (this.state.zoomState) {
            this.setState({ zoomState: false })
        }
        if (this.state.zoomState === false) {
            this.setState({ zoomState: true })
        }
    }

    render() {
        const { admin, allDetail } = this.props
        let filterData = this.state.finBitDataNew
        if (this.state.selectedFilter && this.state.selectedFilter != '' && filterData != null && filterData != '' && filterData.length > 0) {
            filterData = filterData.filter((row) => {
                if (row.narration != null) {
                    return row.narration.toLowerCase().includes(this.state.selectedFilter.toLowerCase())
                }
            })
        }
        return (
            <div>
                {this.state.notesPopup ?
                    <div className="background-blur">

                        <div className="popup-main small-popup container-fluid max-width-500px " style={{ padding: '20px', borderRadius: '10px' }}>
                            <i onClick={() => this.closepopup()} style={{ float: "right", marginTop: '-18px', marginRight: '-17px', fontSize: '20px', color: 'red' }} className="fa fa-times-circle"></i>
                            <div className="row from-group">
                                <div className="col-xs-12" style={{ padding: '10px' }}>
                                    <h5 className="" style={{ marginBottom: '10px', marginTop: '10px', fontWeight: '700' }}><i style={{ fontWeight: '700' }} className='fa fa-commenting-o'></i>&nbsp;&nbsp; Comment</h5>
                                </div>
                            </div>
                            <div style={{ background: '#F1F7FF', border: '1px solid #DBE9FF', padding: '10px', borderRadius: '12px' }}>

                                <textarea className='form-control' placeholder='type here...' onChange={(e) => this.commentChangeHandler(e, this.state.notesType)} />
                                <div className='text-right'>
                                    <button className="btn btn-primary" style={{ marginTop: '10px', borderRadius: '5px', border: '1px solid #D3D9E9', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', background: '#2B78FF' }} onClick={() => this.saveNotesType()}>Save</button>
                                </div>
                            </div>
                            {this.state.newNotes !== '' && this.state.newNotes.length > 0 ? <h5 style={{ fontWeight: '700' }}> Previous Comments</h5> : ""}
                            {this.state.newNotes !== '' && this.state.newNotes.length > 0 ?
                                <div style={{
                                    borderRadius: '10px',
                                    border: '1px solid #D1D9E2',
                                    background: '#fff',
                                    maxHeight: '200px',
                                    overflowY: 'scroll'
                                }}>

                                    {this.state.newNotes.map((data, i) => {
                                        return (
                                            <div className='container-fluid'>
                                                <div className='row' style={{ borderBottom: '1px solid #D1D9E2', padding: '10px' }} key={i}>
                                                    <div className='col-sm-6 col-xs-6'>
                                                        {data.notes}<br />
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.empId}</span>
                                                    </div>
                                                    <div className='col-sm-6 col-xs-6 text-right'>
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '10px' }}>{data.addedOn ? moment(data.addedOn).format('DD-MM-YYYY') : ""}</span>
                                                    </div>

                                                </div>
                                                {/* <hr /> */}
                                            </div>
                                        )
                                    })}
                                </div>
                                : ""}

                        </div>
                    </div>
                    : ""}
                {this.state.popupstate ?
                    <PopUpNew popupStatus={this.state.popupStatus} closePopup={this.closepopupSide.bind(this)} />
                    : ""}
                {this.state.finbitPopup ?
                    <CheckReport admin={admin} ConfirmCrifDataGenerate={this.ConfirmCrifDataGenerate.bind(this)} />
                    : ""}
                {this.state.loaderState ?
                    <Loader />
                    : ""}
                {this.state.popupStateError ?
                    <PopUp
                        popupStatus={this.state.popupStatus}
                        closePopup={this.closePopup.bind(this)} />
                    : ""}
                {this.state.popupStateSuccess ?
                    <PopupSuccess
                        popupStatus={this.state.popupStatus}
                        closePopup={this.closePopup.bind(this)} />
                    : ""}
                {this.state.finbitPopup === false ?
                    <Finbitui
                    getData={this.getData.bind(this)}
                   
                        zoomStateAnalysis={this.state.zoomStateAnalysis}
                        zoomAnalysis={this.zoomAnalysis.bind(this)}

                        zoomPage={this.zoomPage.bind(this)}
                        zoomState={this.state.zoomState}
                        allDetail={allDetail}
                        jsondata={jsondata}
                        selectData={this.selectData.bind(this)}
                        suffixDate={this.state.suffixDate}
                        notesPopup={this.state.notesPopup}
                        openCommentPopup={this.openCommentPopup}
                        underWriterHandler={this.underWriterHandler}
                        saveUnderWritter={this.saveUnderWritter}
                        newNotes={this.state.newNotes}
                        setViewPage={this.setViewPage.bind(this)}
                        viewPage={this.state.viewPage}
                        pageNumber={this.state.pageNumber}
                        handleChangeNewPagination={this.handleChangeNewPagination.bind(this)}
                        analysisData={this.state.analysisData}
                        fraudData={this.state.fraudData}
                        bankDataFibit={this.state.bankDataFibit}
                        finterChangeHandler={this.finterChangeHandler.bind(this)}
                        datePickerState={this.state.datePickerState}
                        searchHandler={this.searchHandler.bind(this)}
                        onChangeHandlerDate={this.onChangeHandlerDate.bind(this)}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        finBitDataNew={filterData}
                        // finBitDataNew={this.state.finBitDataNew}
                        type={this.state.type}
                        viewFalse={this.state.viewFalse}
                        typeHandler={this.typeHandler.bind(this)}
                        phocketTablePagination={this.phocketTablePagination.bind(this)}
                        tableMinPagination={this.state.tableMinPagination}
                        tableMaxPagination={this.state.tableMaxPagination}
                        finBitData={this.state.finBitData}
                        fraudanalysisdropdown={this.state.fraudanalysisdropdown}
                        subtypeHandler={this.subtypeHandler.bind(this)}
                        analysisdatakey={this.state.analysisdatakey}
                    />
                    : ""}
            </div>
        )
    }
}
export default FinBit
