import React, { useEffect } from "react";
import { getWorkingHistoryData } from "./analysisactionCreator";
import { useState } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Nodata from '../../../../images/nodata.png'

const WorkingHistory = ({ allDetail }) => {
    const [kycJson, setKycData] = useState('')
    const [crifJson, setCrifData] = useState({})
    const [similarJson, setSimilarData] = useState('')
    const [smsJson, setsmsData] = useState({})
    const [finbitData, setFibitData] = useState('')
    const [loaderState, setLoaderState] = useState(false)
    const [showNoData, SetshowNodata] = useState(false)
    const [applicationIdsList, setApplicationIds] = useState([])


    useEffect(() => {
        setLoaderState(true)
        getWorkingHistoryData(allDetail.userId, callBack => {
            if (callBack.length > 0) {
                setApplicationIds(callBack)
                // mergedData(callBack)
                // let mergedData = callBack.reduce((acc, current) => {
                //     const existingItem = acc.find(item => item.applicationId === current.applicationId);

                //     if (existingItem) {
                //       existingItem.smsData = { ...existingItem.smsData, ...current.smsData };
                //       existingItem.finbitData = { ...existingItem.finbitData, ...current.finbitData };
                //     } else {
                //       acc.push(current);
                //     }

                //     return acc;
                //   }, []);
                //   console.log(mergedData,'mergedDatamergedData')
                //   setApplicationIds(mergedData)

            } else {
                SetshowNodata(true)
            }
            // })
            setLoaderState(false)


        })
    }, [])

    const SelectIdsHandler = (id) => {
        let data = JSON.parse(id)
        if (data) {
            SetshowNodata(false)
            if (data.kycData) {
                setKycData(JSON.parse(data.kycData))
            }
            if (data.crifData) {
                setCrifData(JSON.parse(data.crifData))
            }
            if (data.internalDedupeData) {
                setSimilarData(JSON.parse(data.internalDedupeData))
            }
            if (data.smsData) {
                setsmsData(JSON.parse(data.smsData))
            }
            if (data.finbitData) {
                setFibitData(JSON.parse(data.finbitData))
            }
        } else {
            setCrifData('')
            setKycData('')
            setSimilarData('')
            setsmsData('')
            setFibitData('')
            SetshowNodata(true)
        }

    }
    const toSentenceCaseAdhaaar = (str) => {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
    };
    const toSentenceCasePan = (str) => {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
    };
    const toSentenceCaseLocation = (str) => {
        return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
    };
    return (
        <div className="container-fluid animated fadeIn " style={{ marginBottom: '20px' }}>
            <div className="" style={{ background: '#fff', padding: '20px', borderRadius: '10px', minHeight: '600px' }}>
                {
                    showNoData ?
                        <div className="text-center" style={{ marginTop: '100px' }}>
                            <img src={Nodata} width={'20%'} />
                        </div>
                        : ""
                }
                <div className="row">
                    <div className="col-sm-2">

                        {applicationIdsList.length > 0 ? (
                            <>
                                <label>Application Id</label>
                                <select className="form-control" onChange={(e) => SelectIdsHandler(e.target.value)}>
                                    <option value={''}>Select Application Id</option>

                                    {applicationIdsList.map((data, i) => (
                                        data.applicationId ?
                                            <option key={i} value={JSON.stringify(data)}>
                                                {data.applicationId}
                                            </option>
                                            : ""
                                    ))}
                                </select>
                            </>
                        ) : null}
                    </div>
                </div>
                {kycJson !== '' ?
                    <>
                        <h4><b>Kyc Data</b></h4>

                        {kycJson !== '' && kycJson.aadhaar !== undefined && kycJson.aadhaar !== null ?
                            <>
                                <hr />
                                <h5 style={{ textDecoration: 'underline' }}>Aadhaar Information</h5>

                                <div className="row">
                                    {Object.entries(kycJson.aadhaar).map(([key, value], index) => (
                                        <div className="col-sm-4 col-xs-12" key={index}>
                                            <h6>{toSentenceCaseAdhaaar(key)}</h6>
                                            <h5><b>{key === 'image' ? <img width={'30px'} src={'data:image/png;base64,' + value} /> : value}</b></h5>
                                        </div>
                                    ))}
                                </div>

                            </>
                            : ""}

                        {kycJson !== '' && kycJson.pan !== undefined && kycJson.pan !== null ?
                            <>
                                <hr />
                                <h5 style={{ textDecoration: 'underline' }}>Pan Information</h5>

                                <div className="row">
                                    {Object.entries(kycJson.pan).map(([key, value], index) => (
                                        <div className="col-sm-4 col-xs-12" key={index}>
                                            <h6>{toSentenceCaseAdhaaar(key)}</h6>
                                            <h5><b>{key === 'image' ? <img width={'30px'} src={'data:image/png;base64,' + value} /> : value}</b></h5>
                                        </div>
                                    ))}
                                </div>
                            </>
                            : ""}

                        {kycJson !== '' && kycJson.location !== undefined && kycJson.location !== null ?
                            <>
                                <hr />
                                <h5 style={{ textDecoration: 'underline' }}>Location Information</h5>

                                <div className="row">
                                    {Object.entries(kycJson.location).map(([key, value], index) => (
                                        <div className="col-sm-4 col-xs-12" key={index}>
                                            <h6>{toSentenceCaseAdhaaar(key)}</h6>
                                            <h5><b>{key === 'image' ? <img width={'30px'} src={'data:image/png;base64,' + value} /> : value}</b></h5>
                                        </div>
                                    ))}
                                </div>
                            </>
                            : ""}

                        <hr style={{ borderTop: '2px solid' }} />
                    </>
                    : ""}
                {crifJson !== '' && (crifJson.greenFlag !== undefined || crifJson.redFlag !== undefined || crifJson.yellowFlag !== undefined || crifJson.obligation !== undefined) ? <h4 ><b>Crif Data</b></h4> : ""}
                {crifJson !== '' && crifJson.greenFlag !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>Green Flag</h5>
                        <table>
                            <thead>
                                {crifJson !== '' && crifJson.greenFlag !== undefined ?
                                    <tr>
                                        <th>Account Type</th>
                                        <th>OverDue Amount</th>
                                        <th>Disburse Amount</th>
                                        <th>Disburse Date</th>
                                        <th>Account Status</th>
                                        <th>Current Balance</th>
                                        <th>Status</th>
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {crifJson ? Object.entries(crifJson.greenFlag).map((data, index) => (
                                    <tr key={index}>
                                        <td>{data[1].accountType}</td>
                                        <td>{data[1].overDueAmount}</td>
                                        <td>{data[1].disburseAmount}</td>
                                        <td>{data[1].disburseDate}</td>
                                        <td>{data[1].accountStatus}</td>
                                        <td>{data[1].currentBalance}</td>
                                        <td>{data[1].status}</td>
                                    </tr>
                                )) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {crifJson !== '' && crifJson.redFlag !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>Red Flag</h5>
                        <table>
                            <thead>
                                {crifJson !== '' && crifJson.redFlag !== undefined ?
                                    <tr>
                                        <th>Account Type</th>
                                        <th>OverDue Amount</th>
                                        <th>Disburse Amount</th>
                                        <th>Disburse Date</th>
                                        <th>Account Status</th>
                                        <th>Current Balance</th>
                                        <th>Status</th>
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {crifJson ? Object.entries(crifJson.redFlag).map((data, index) => (
                                    <tr key={index}>
                                        <td>{data[1].accountType}</td>
                                        <td>{data[1].overDueAmount}</td>
                                        <td>{data[1].disburseAmount}</td>
                                        <td>{data[1].disburseDate}</td>
                                        <td>{data[1].accountStatus}</td>
                                        <td>{data[1].currentBalance}</td>
                                        <td>{data[1].status}</td>
                                    </tr>
                                )) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {crifJson !== '' && crifJson.yellowFlag !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>Yellow Flag</h5>
                        <table>
                            <thead>
                                {crifJson !== '' && crifJson.yellowFlag !== undefined ?
                                    <tr>
                                        <th>Account Type</th>
                                        <th>OverDue Amount</th>
                                        <th>Disburse Amount</th>
                                        <th>Disburse Date</th>
                                        <th>Account Status</th>
                                        <th>Current Balance</th>
                                        <th>Status</th>
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {crifJson ? Object.entries(crifJson.yellowFlag).map((data, index) => (
                                    <tr key={index}>
                                        <td>{data[1].accountType}</td>
                                        <td>{data[1].overDueAmount}</td>
                                        <td>{data[1].disburseAmount}</td>
                                        <td>{data[1].disburseDate}</td>
                                        <td>{data[1].accountStatus}</td>
                                        <td>{data[1].currentBalance}</td>
                                        <td>{data[1].status}</td>
                                    </tr>
                                )) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {crifJson !== '' && crifJson.obligation !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>Yellow Flag</h5>
                        <table>
                            <thead>
                                {crifJson !== '' && crifJson.obligation !== undefined ?
                                    <tr>
                                        <th>Account Type</th>
                                        <th>OverDue Amount</th>
                                        <th>Disburse Amount</th>
                                        <th>Disburse Date</th>
                                        <th>Account Status</th>
                                        <th>Current Balance</th>
                                        <th>Status</th>
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {crifJson ? Object.entries(crifJson.obligation).map((data, index) => {

                                    var updatedData = data[1].combinedPaymentHistory.replace(/\|/g, ' ');
                                    return (
                                        <tr key={index}>
                                            <td>{data[1].accType}</td>
                                            <td>{data[1].overDueAmt}</td>
                                            <td>{data[1].disbursedAmt}</td>
                                            <td>{data[1].disburseDate}</td>
                                            <td>{data[1].accountStatus}</td>
                                            <td>{data[1].currentBal}</td>
                                            <td style={{ width: '50px' }}>{updatedData}</td>
                                            <td>{data[1].ownershipInd}</td>
                                            <td>{data[1].securityStatus}</td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {crifJson !== '' && (crifJson.greenFlag !== undefined || crifJson.redFlag !== undefined || crifJson.yellowFlag !== undefined || crifJson.obligation !== undefined) ?
                    <hr style={{ borderTop: '2px solid' }} /> : ""}
                {smsJson !== '' && (smsJson.Salary !== undefined || smsJson.Emi !== undefined || smsJson.legal !== undefined || smsJson.OverDue !== undefined || smsJson.Raw !== undefined) ? <h4><b>Sms  Data</b></h4> : ""}
                {smsJson !== '' && smsJson.Raw !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>Raw</h5>
                        <table>
                            <thead>
                                {smsJson !== '' && smsJson.Raw !== undefined ?
                                    <tr>
                                        <th>Bank Name</th>
                                        <th className="text-center">Message</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                        <th>Salary</th>
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {smsJson ? Object.entries(smsJson.Raw).map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{data[1].bankName}</td>
                                            <td>{data[1].message}</td>
                                            <td>{data[1].messageType}</td>
                                            <td>{data[1].messageDate}</td>
                                            <td>{data[1].salary}</td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {smsJson !== '' && smsJson.OverDue !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>OverDue</h5>
                        <table>
                            <thead>
                                {smsJson !== '' && smsJson.OverDue !== undefined ?
                                    <tr>
                                        <th>Bank Name</th>
                                        <th className="text-center">Message</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {smsJson ? Object.entries(smsJson.OverDue).map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{data[1].bankName}</td>
                                            <td>{data[1].message}</td>
                                            <td>{data[1].messageType}</td>
                                            <td>{data[1].messageDate}</td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {smsJson !== '' && smsJson.Emi !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>EMI</h5>
                        <table>
                            <thead>
                                {smsJson !== '' && smsJson.Emi !== undefined ?
                                    <tr>
                                        <th>Bank Name</th>
                                        <th className="text-center">Message</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {smsJson ? Object.entries(smsJson.Emi).map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{data[1].bankName}</td>
                                            <td>{data[1].message}</td>
                                            <td>{data[1].messageType}</td>
                                            <td>{data[1].messageDate}</td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {smsJson !== '' && smsJson.Salary !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>Salary</h5>
                        <table>
                            <thead>
                                {smsJson !== '' && smsJson.Salary !== undefined ?
                                    <tr>
                                        <th>Bank Name</th>
                                        <th className="text-center">Message</th>
                                        <th>Type</th>
                                        <th>Date</th>
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {smsJson ? Object.entries(smsJson.Salary).map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{data[1].bankName}</td>
                                            <td>{data[1].message}</td>
                                            <td>{data[1].messageType}</td>
                                            <td>{data[1].messageDate}</td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {smsJson !== '' && (smsJson.Salary !== undefined || smsJson.Emi !== undefined || smsJson.legal !== undefined || smsJson.OverDue !== undefined) ?
                    <hr style={{ borderTop: '2px solid' }} /> : ""}
                {similarJson !== '' && (similarJson.address !== undefined || similarJson.name !== undefined || similarJson.mobileNo !== undefined || similarJson.accountNo !== undefined) ? <h4><b>Internal Dedupe Data</b></h4> : ""}

                {similarJson !== '' && similarJson.address !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>Address</h5>
                        <table>
                            <thead>
                                {similarJson !== '' && similarJson.address !== undefined ?
                                    <tr>
                                        <th>Application Id</th>
                                        <th>Name</th>
                                        <th>Email ID</th>
                                        <th>Loan Status</th>
                                        <th>Reject Reason</th>
                                        <th>Match By</th>
                                        <th>Priority</th>
                                        <th>Address</th>
                                        {/* <th>Info</th> */}
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {similarJson ? Object.entries(similarJson.address).map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{data[1].applicationId}</td>
                                            <td style={{ whiteSpace: 'break-spaces' }}>{data[1].userName}</td>
                                            <td>{data[1].emailId}</td>
                                            <td>{data[1].loanStatus}</td>
                                            <td>{data[1].rejectReason}</td>
                                            <td>{data[1].matchBy.toString()}</td>
                                            <td>{data[1].pri}</td>
                                            <td>{data[1].address}</td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {similarJson !== '' && similarJson.name !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>Kyc</h5>
                        <table>
                            <thead>
                                {similarJson !== '' && similarJson.name !== undefined ?
                                    <tr>
                                        <th>Application Id</th>
                                        <th>Name</th>
                                        <th>Email ID</th>
                                        <th>Loan Status</th>
                                        <th>Reject Reason</th>
                                        <th>Match By</th>
                                        <th>Priority</th>
                                        <th>Address</th>
                                        {/* <th>Info</th> */}
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {similarJson ? Object.entries(similarJson.name).map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{data[1].applicationId}</td>
                                            <td style={{ whiteSpace: 'break-spaces' }}>{data[1].userName}</td>
                                            <td>{data[1].emailId}</td>
                                            <td>{data[1].loanStatus}</td>
                                            <td>{data[1].rejectReason}</td>
                                            <td>{data[1].matchBy.toString()}</td>
                                            <td>{data[1].pri}</td>
                                            <td>{data[1].address}</td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {similarJson !== '' && similarJson.mobileNo !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>Mobile Number</h5>
                        <table>
                            <thead>
                                {similarJson !== '' && similarJson.mobileNo !== undefined ?
                                    <tr>
                                        <th>Application Id</th>
                                        <th>Name</th>
                                        <th>Email ID</th>
                                        <th>Loan Status</th>
                                        <th>Reject Reason</th>
                                        <th>Match By</th>
                                        <th>Priority</th>
                                        <th>Address</th>
                                        {/* <th>Info</th> */}
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {similarJson ? Object.entries(similarJson.mobileNo).map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{data[1].applicationId}</td>
                                            <td style={{ whiteSpace: 'break-spaces' }}>{data[1].userName}</td>
                                            <td>{data[1].emailId}</td>
                                            <td>{data[1].loanStatus}</td>
                                            <td>{data[1].rejectReason}</td>
                                            <td>{data[1].matchBy.toString()}</td>
                                            <td>{data[1].pri}</td>
                                            <td>{data[1].address}</td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {similarJson !== '' && similarJson.accountNo !== undefined ?
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <h5 style={{ textDecoration: 'underline' }}>Account Number</h5>
                        <table>
                            <thead>
                                {similarJson !== '' && similarJson.accountNo !== undefined ?
                                    <tr>
                                        <th>Application Id</th>
                                        <th>Name</th>
                                        <th>Email ID</th>
                                        <th>Loan Status</th>
                                        <th>Reject Reason</th>
                                        <th>Match By</th>
                                        <th>Priority</th>
                                        <th>Address</th>
                                        {/* <th>Info</th> */}
                                    </tr>
                                    : ""}
                            </thead>
                            <tbody>
                                {similarJson ? Object.entries(similarJson.accountNo).map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{data[1].applicationId}</td>
                                            <td style={{ whiteSpace: 'break-spaces' }}>{data[1].userName}</td>
                                            <td>{data[1].emailId}</td>
                                            <td>{data[1].loanStatus}</td>
                                            <td>{data[1].rejectReason}</td>
                                            <td>{data[1].matchBy.toString()}</td>
                                            <td>{data[1].pri}</td>
                                            <td>{data[1].address}</td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </table>
                    </div>
                    : ""}
                {similarJson !== '' && (similarJson.address !== undefined || similarJson.name !== undefined || similarJson.mobileNo !== undefined || similarJson.accountNo !== undefined) ?
                    <hr style={{ borderTop: '2px solid' }} /> : ""}
                    {console.log(' finbitData.transactionsDetail finbitData.transactionsDetail', finbitData)}
                {finbitData != '' && finbitData !== null && Object.entries(finbitData).length > 0 ?
                    <>
                        <h4><b>Financial Data</b></h4>
                        {
                            finbitData.transactions !== undefined && finbitData.transactions !== null && finbitData.transactions !== '' ?
                                <div className=" form-group overflow-auto phocket-table-new">
                                    <h5 style={{ textDecoration: 'underline' }}>Transaction Details</h5>

                                    <table className="" style={{ marginTop: '0px' }}>

                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Description</th>
                                                <th>Amount</th>
                                                <th>Type</th>
                                                <th>Balance After Transaction</th>
                                                <th>Category</th>
                                                {/* <th>First Level Category</th>
                                                <th>Second Level Category</th> */}

                                            </tr>


                                        </thead >

                                        <tbody>
                                            {finbitData ? Object.entries(finbitData.transactions).map((detail, i) => {

                                                return (
                                                    <tr key={i}>
                                                        <td>{detail[1].transactionDate}</td>
                                                        <td>{detail[1].narration}</td>
                                                        <td>{detail[1].amount}</td>
                                                        <td style={{ fontWeight: 'bold', color: detail[1].transactionType === "Debit" ? 'red' : 'green' }}>{detail[1].transactionType}</td>
                                                        <td>{detail[1].balance}</td>
                                                        <td>{detail[1].category}</td>
                                                        {/* <td>{detail[1].secondLevelCategory}</td> */}

                                                    </tr>
                                                )
                                            })
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                                : ""
                        }

                        {finbitData.income !== undefined && finbitData.income !== null && finbitData.income !== '' ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>Income Details</h5>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <td>Transaction Type</td>
                                            <th>Balance After Transaction</th>
                                            <th>Bank</th>
                                            <th>Category</th>


                                        </tr>

                                        <tbody>
                                            {finbitData ? Object.entries(finbitData.income).map((detail, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{detail[1].date}</td>
                                                        <td>{detail[1].description}</td>
                                                        <td>{detail[1].amount}</td>
                                                        <td style={{ fontWeight: 'bold', color: detail[1].type === 'DEBIT' ? 'red' : 'green' }}>{detail[1].transactionType}</td>

                                                        <td>{detail[1].balanceAfterTransaction}</td>

                                                        <td>{detail[1].bank}</td>
                                                        <td>{detail[1].category}</td>

                                                        {/* <td>{detail[1].transactionType}</td> */}

                                                    </tr>
                                                )
                                            })
                                                : null}
                                        </tbody>

                                    </thead>
                                </table>
                            </div>
                            : ""}
                        {finbitData.expenses !== undefined && finbitData.expenses !== null && finbitData.expenses !== '' ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>Expenses Details</h5>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Bank</th>
                                            <th>Category</th>
                                        </tr>


                                    </thead>
                                    <tbody>
                                        {finbitData ? Object.entries(finbitData.expenses).map((detail, i) => {
                                            return (
                                                <tr key={i} >
                                                    <td>{detail[1].date}</td>
                                                    <td>{detail[1].description}</td>
                                                    <td>{detail[1].amount}</td>
                                                    <td>{detail[1].bank}</td>
                                                    <td>{detail[1].category}</td>
                                                </tr>
                                            )
                                        })
                                            : ""}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        {finbitData.creditCard !== undefined && finbitData.creditCard !== null && finbitData.creditCard !== '' ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>Credit Card Details</h5>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Bank</th>

                                            <th>Total</th>
                                        </tr>


                                    </thead>
                                    <tbody>
                                        {finbitData ? Object.entries(finbitData.creditCard).map((detail, i) => {
                                            return (
                                                <tr key={i} >
                                                    <td>{detail[1].date}</td>
                                                    <td>{detail[1].description}</td>
                                                    <td>{detail[1].amount}</td>
                                                    <td>{detail[1].bank}</td>

                                                    <td>{detail[1].total}</td>
                                                </tr>
                                            )
                                        })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        {finbitData.highValueTransactions !== undefined && finbitData.highValueTransactions !== null && finbitData.highValueTransactions !== '' ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>High Value Transactions</h5>

                                <table>

                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Type</th>

                                            <th>Balance After Transcation</th>
                                            <th>Bank</th>
                                            <th>Category</th>

                                        </tr>


                                    </thead>
                                    <tbody>
                                        {finbitData ? Object.entries(finbitData.highValueTransactions).map((detail, i) => {
                                            return (
                                                <tr key={i} >
                                                    <td>{detail[1].date}</td>
                                                    <td>{detail[1].description}</td>
                                                    <td>{detail[1].amount}</td>
                                                    <td style={{ fontWeight: 'bold', color: detail[1].type === 'DEBIT' ? 'red' : 'green' }}>{detail[1].type}</td>
                                                    <td>{detail[1].balanceAfterTranscation}</td>
                                                    <td>{detail[1].bank}</td>
                                                    <td>{detail[1].category}</td>

                                                    {/* <td>{detail[1].type}</td> */}




                                                </tr>
                                            )
                                        })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        {finbitData.loanReceived !== undefined && finbitData.loanReceived !== null && finbitData.loanReceived !== '' ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>Loan Received</h5>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Balance</th>
                                            <th>Bank</th>

                                        </tr>


                                    </thead>
                                    <tbody>
                                        {finbitData ? Object.entries(finbitData.loanReceived).map((detail, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{detail[1].date}</td>
                                                    <td>{detail[1].description}</td>
                                                    <td>{detail[1].amount}</td>
                                                    <td>{detail[1].balance}</td>
                                                    <td>{detail[1].bank}</td>



                                                </tr>
                                            )
                                        })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        {finbitData.frequentTransaction !== undefined && finbitData.frequentTransaction !== null ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>Frequent Transactions</h5>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Bank</th>

                                        </tr>

                                    </thead>
                                    <tbody>
                                        {finbitData ? Object.entries(finbitData.frequentTransaction).map((detail, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{detail[1].date}</td>
                                                    <td>{detail[1].description}</td>
                                                    <td>{detail[1].amount}</td>
                                                    <td>{detail[1].bank}</td>
                                                </tr>
                                            )
                                        })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        {finbitData.sanctionedAmount !== undefined && finbitData.sanctionedAmount !== null ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>Sanctioned Amount</h5>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Bank</th>
                                            <th>sanctionedAmount</th>
                                        </tr>


                                    </thead>
                                    <tbody>
                                        {finbitData ? Object.entries(finbitData.sanctionedAmount).map((detail, i) => {
                                            return (
                                                <tr key={i}>

                                                    <td>{detail[1].bank}</td>
                                                    <td>{detail[1].sanctionedAmount}</td>


                                                </tr>
                                            )
                                        })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        {finbitData.ecsNNach !== undefined && finbitData.ecsNNach !== null ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>EcsAndNach </h5>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>TransactionType</th>
                                            <th>BalanceAfterTransaction</th>
                                            <th>Bank</th>

                                            <th>MonthAndYear</th>
                                            <th>Total</th>

                                        </tr>

                                    </thead>
                                    <tbody>

                                        {finbitData ? Object.entries(finbitData.ecsNNach).map((detail, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{detail[1].date}</td>
                                                    <td>{detail[1].description}</td>
                                                    <td>{detail[1].amount}</td>
                                                    <td style={{ fontWeight: 'bold', color: detail[1].type === 'DEBIT' ? 'red' : 'green' }}>{detail[1].transactionType}</td>
                                                    <td>{detail[1].balanceAfterTransaction}</td>
                                                    <td>{detail[1].bank}</td>
                                                    <td>{detail[1].monthAndYear}</td>
                                                    <td>{detail[1].total}</td>


                                                </tr>
                                            )
                                        })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        {finbitData.creditCardPayment !== undefined && finbitData.creditCardPayment !== null ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>Credit Card Payment</h5>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Bank</th>

                                            <th>MonthAndYear</th>
                                            <th>Total</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {finbitData ? Object.entries(finbitData.creditCardPayment).map((detail, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{detail[1].date}</td>
                                                    <td>{detail[1].description}</td>
                                                    <td>{detail[1].amount}</td>
                                                    <td>{detail[1].bank}</td>
                                                    <td>{detail[1].monthAndYear}</td>
                                                    <td>{detail[1].total}</td>


                                                </tr>
                                            )
                                        })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        {finbitData.averageBankBalancePerMonth !== undefined && finbitData.averageBankBalancePerMonth !== null ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>Average Bank Balance Per Month</h5>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Average Bank Balance Amount</th>
                                            <th>Bank</th>
                                            <th>FirstDayAmount</th>
                                            <th>FourteenDayAmount</th>
                                            <th>MonthAndYear</th>
                                            <th>ThirtyDayAmount</th >
                                        </tr >

                                    </thead>
                                    <tbody>
                                        {finbitData ? Object.entries(finbitData.averageBankBalancePerMonth).map((detail, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{detail[1].averageBankBalanceAmount}</td>
                                                    <td>{detail[1].bank}</td>
                                                    <td>{detail[1].firstDayAmount}</td>
                                                    <td>{detail[1].fourteenDayAmount}</td>
                                                    <td>{detail[1].monthAndYear}</td>
                                                    <td>{detail[1].thirtyDayAmount}</td>


                                                </tr>
                                            )
                                        })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        {finbitData.loanRepayment !== undefined && finbitData.loanRepayment !== null ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>Loan Repayment</h5>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Type</th>
                                            <th>Balance After Transcation</th>
                                            <th>Bank</th>
                                            <th>Category</th>

                                        </tr>

                                    </thead>
                                    <tbody>
                                        {finbitData ? Object.entries(finbitData.loanRepayment).map((detail, i) => {

                                            return (
                                                <tr key={i} >
                                                    <td>{detail[1].dateTime}</td>
                                                    <td>{detail[1].description}</td>
                                                    <td>{detail[1].amount}</td>
                                                    <td style={{ fontWeight: 'bold', color: detail[1].type === 'DEBIT' ? 'red' : 'green' }}>{detail[1].transactionType}</td>
                                                    <td>{detail[1].balanceAfterTransaction}</td>
                                                    <td>{detail[1].bank}</td>
                                                    <td>{detail[1].category}</td>

                                                    {/* <td>{detail[1].transactionType}</td> */}




                                                </tr>
                                            )
                                        })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}

                        {finbitData.moneyReceived !== undefined && finbitData.moneyReceived !== null ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}>Money Received</h5>

                                <table>
                                    <thead>

                                        <tr >
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Transaction Type</th>
                                            <th>Balance After Transcation</th>
                                            <th>Bank</th>
                                            <th>Category</th>
                                            <th>MonthAndYear</th>
                                            <th>Total</th>
                                        </tr>


                                    </thead>
                                    <tbody>
                                        {finbitData ? Object.entries(finbitData.moneyReceived).map((detail, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{detail[1].date}</td>
                                                    <td>{detail[1].description}</td>
                                                    <td>{detail[1].amount}</td>
                                                    <td style={{ fontWeight: 'bold', color: detail[1].type === 'DEBIT' ? 'red' : 'green' }}>{detail[1].transactionType}</td>
                                                    <td>{detail[1].balanceAfterTransaction}</td>
                                                    <td>{detail[1].bank}</td>
                                                    <td>{detail[1].category}</td>
                                                    <td>{detail[1].monthAndYear}</td>
                                                    <td>{detail[1].total}</td>

                                                    {/* <td>{detail[1].transactionType}</td> */}


                                                </tr>
                                            )
                                        })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        {finbitData.summaryForSavingAccount !== undefined && finbitData.summaryForSavingAccount !== null ?
                            <div className=" form-group overflow-auto phocket-table-new">
                                <h5 style={{ textDecoration: 'underline' }}> Summary For Saving Account</h5>

                                <table>
                                    <thead>

                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Transaction Type</th>
                                            <th>Balance After Transaction</th>

                                            <th>Bank</th>
                                            <th>Category</th>

                                        </tr>

                                    </thead>
                                    <tbody>
                                        {finbitData ? Object.entries(finbitData.summaryForSavingAccount).map((detail, i) => {

                                            return (
                                                <tr key={i}>
                                                    <td>{detail[1].dateTime}</td>
                                                    <td>{detail[1].description}</td>
                                                    <td>{detail[1].amount}</td>
                                                    <td style={{ fontWeight: 'bold', color: detail[1].type === 'DEBIT' ? 'red' : 'green' }}>{detail[1].type}</td>

                                                    <td>{detail[1].balanceAfterTransaction}</td>

                                                    <td>{detail[1].bank}</td>
                                                    <td>{detail[1].category}</td>
                                                    {/* <td>{detail[1].type}</td> */}


                                                </tr>
                                            )
                                        })
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                            : ""}
                        <hr style={{ borderTop: '2px solid' }} />
                    </ >
                    : ""}

                {
                    loaderState ?
                        <div className='phocket-table-new' style={{ overflow: 'auto' }} >
                            <table className='text-center' style={{ marginTop: '0px' }}>
                                <thead>
                                    <tr>
                                        <th><Skeleton height={40} /></th>
                                        <th><Skeleton height={40} /></th>
                                        <th><Skeleton height={40} /></th>
                                        <th><Skeleton height={40} /></th>
                                        <th><Skeleton height={40} /></th>
                                        <th><Skeleton height={40} /></th>
                                        <th><Skeleton height={40} /></th>
                                        <th><Skeleton height={40} /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>

                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>
                                    <tr>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                        <td><Skeleton height={40} /></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        : ""
                }

                


            </div >

        </div >
    )
}
export default WorkingHistory