import React from 'react'
import { MAIL_STRING } from '../../../utils/Constant'

// subject: Phocket: NACH Bounced

const Covid = ({ allDetail }) => (
    <div>
        <div>Hello {allDetail != null && allDetail.firstName != null ? allDetail.firstName : ""},</div>
        <br />
        <div>Congratulations!</div>
        <br />
        <div>We have an in-principal approval against your application but your disbursement is temporarily on hold
         due to current pandemic(COVID-19), request you to please understand the situation.</div>
       <br/>
       <div>Best Regards,</div>
        <div>Team BorrowBuddy</div>
        <div>{MAIL_STRING.MOBILE_NO}</div>
    </div>
)

export default Covid