import React, { useState } from 'react'
import { XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Label } from 'recharts';
import TablePopup from './TablePopup';
export default function DebtManagementDashboard() {
    const [LinechartData, setLinechartData] = useState([]);
    const Piecolors = ['#223d64', '#f24839', '#447dd3'];
    // const Piecolors = ['#223d64', '#f24839', '#267dff'];
    const [tablePopup, setTablePopup] = useState(false);
    const [tablePopupDataType, setTablePopupDataType] = useState({});
    const [tablePopupData, setTablePopupData] = useState([]);
    const [nbfcFilter, setnbfcFilter] = useState('all')
    const [data, setData] = useState({
        payableAccount: {
            amount: 5678,
            data: [
                {
                    name: 'Days',
                    value: 4000,
                },
                {
                    name: 'Days',
                    value: 3000,
                },
                {
                    name: 'Days',
                    value: 2000,
                },
            ]
        },
        overdue: {
            amount: 5678,
            data: [
                {
                    name: 'Days',
                    value: 4000,
                },
                {
                    name: 'Days',
                    value: 3000,
                },
                {
                    name: 'Days',
                    value: 2000,
                },
            ]
        },
        overduePercentage: {
            overduePercentage: 568,
            data: [
                {
                    name: 'Days',
                    value: 4000,
                },
                {
                    name: 'Days',
                    value: 3000,
                },
                {
                    name: 'Days',
                    value: 2000,
                },
            ]
        },
        cashOnHand: {
            amount: 5678,
            data: [
                {
                    name: 'Days',
                    value: 4000,
                },
                {
                    name: 'Days',
                    value: 3000,
                },
                {
                    name: 'Days',
                    value: 2000,
                },
            ]
        },
        APFunnel: [
            {
                name: 'Total Purchase',
                value: 40000,
            },
            {
                name: 'Payable Amount',
                value: 3000,
            },
            {
                name: 'OverDue',
                value: 2000,
            }
        ],
        invoicedByStatus: [
            { name: 'Paid Invoices', value: 400 },
            { name: 'OverDue Invoices', value: 300 },
            { name: 'Open Invoices', value: 300 },
        ],
        averageCreditorDays: {
            avgDays: 345,
            data: [
                {
                    name: 'Name',
                    value: 4000,
                },
                {
                    name: 'Name',
                    value: 3000,
                },
                {
                    name: 'Name',
                    value: 2000,
                },
            ]
        },
        accountPayableAge: [
            {
                name: '1-30 Days',
                value: 4000,
            },
            {
                name: '31-60 Days',
                value: 3000,
            },
            {
                name: '61-90 Days',
                value: 2000,
            },
            {
                name: '90 + Days',
                value: 2780,
            }
        ]
    })

    const Linechartdata = [
        {
            name: 'Page A',
            uv: 4000,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            amt: 2100,
        },
    ];
    const Bardata = [
        {
            name: 'Total Purchase',
            uv: 4000,
            amt: 2400,
        },
        {
            name: 'Payable Amount',
            uv: 3000,
            amt: 2210,
        },
        {
            name: 'OverDue',
            uv: 2000,
            amt: 2290,
        }
    ];


    const InvoicePieData = [
        { name: 'Paid Invoices', value: 400 },
        { name: 'OverDue Invoices', value: 300 },
        { name: 'Open Invoices', value: 300 },
    ];
    const NeedlePieData = [
        { name: 'Paid Invoices', value: 400 },
        { name: 'OverDue Invoices', value: 300 },
        { name: 'Open Invoices', value: 300 },
    ];


    const AccountPayableAgeData = [
        {
            name: '1-30 Days',
            uv: 4000,
            amt: 2400,
        },
        {
            name: '31-60 Days',
            uv: 3000,
            amt: 2210,
        },
        {
            name: '61-90 Days',
            uv: 2000,
            amt: 2290,
        },
        {
            name: '90 + Days',
            uv: 2780,
            amt: 2000,
        }
    ];

    const handleBarClick = (data) => {
        console.log(data)
        setTablePopupDataType(data)
        setTablePopup(true)
    }

    const closePopup = () => {
        setTablePopup(false)
        setTablePopupDataType({})
        setTablePopupData([])
    }
    const handleChange = (e) => {

        setnbfcFilter(e.target.value)

    };

    return (
        <>
            <div className="DebtManagementDashboard">
                <div className="MonthDateFilter" style={{marginBottom:'30px'}}>
                    <div className="row">

                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <div className="inputGrp">
                                <label htmlFor="nbfc" className='labeltag'>NBFC</label>
                                <select className='MonthinputTag' id="nbfc" name="nbfc" value={nbfcFilter} onChange={handleChange} style={{ width: '100%' }} >
                                    <option value="all">ALL</option>
                                    <option value="drp">DRP</option>
                                    <option value="citra">Citra</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-xs-12">
                            <button className='FilterApply'>Apply Filter</button>
                        </div>

                    </div>
                </div>
                <div className="LinechartBlock">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-xs-12 newPadding">
                            <div className="outerBox">
                                <div className="innerBox">
                                    <div className="HeadingBox" style={{ backgroundColor: '#223d64' }}>
                                        <h5>Payable Account</h5>
                                    </div>

                                    <div className="Linecharts">
                                        <h5>3827 $</h5>
                                        <div className="charthead">
                                            <ResponsiveContainer width="100%" height="100%">
                                                <LineChart
                                                    width={560}
                                                    height={300}
                                                    data={Linechartdata}
                                                    margin={{
                                                        top: 20, right: 10, left: -5, bottom: 10,
                                                    }}
                                                >
                                                    <CartesianGrid stroke="none" />
                                                    <XAxis dataKey="name" axisLine={false} tick={false} />
                                                    <YAxis axisLine={false} tick={false} />
                                                    <Tooltip />
                                                    <Line type="monotone" dataKey="uv" stroke="#223D64" dot={{ stroke: '#223D64', strokeWidth: 2, r: 5 }} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 newPadding">
                            <div className="outerBox">
                                <div className="innerBox">
                                    <div className="HeadingBox" style={{ backgroundColor: '#4481d5' }}>
                                        <h5>OverDue</h5>
                                    </div>

                                    <div className="Linecharts">
                                        <h5>2018 $</h5>
                                        <div className="charthead">
                                            <ResponsiveContainer width="100%" height="100%">
                                                <LineChart
                                                    width={560}
                                                    height={300}
                                                    data={Linechartdata}
                                                    margin={{
                                                        top: 20, right: 10, left: -5, bottom: 10,
                                                    }}
                                                >
                                                    <CartesianGrid stroke="none" />
                                                    <XAxis dataKey="name" axisLine={false} tick={false} />
                                                    <YAxis axisLine={false} tick={false} />
                                                    <Tooltip />
                                                    <Line type="monotone" dataKey="uv" stroke="#4481d5" dot={{ stroke: '#4481d5', strokeWidth: 2, r: 5 }} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 newPadding">
                            <div className="outerBox">
                                <div className="innerBox">
                                    <div className="HeadingBox" style={{ backgroundColor: '#223d64' }}>
                                        <h5>% Overdue</h5>
                                    </div>

                                    <div className="Linecharts">
                                        <h5>523% </h5>
                                        <div className="charthead">
                                            <ResponsiveContainer width="100%" height="100%">
                                                <LineChart
                                                    width={560}
                                                    height={300}
                                                    data={Linechartdata}
                                                    margin={{
                                                        top: 20, right: 10, left: -5, bottom: 10,
                                                    }}
                                                >
                                                    <CartesianGrid stroke="none" />
                                                    <XAxis dataKey="name" axisLine={false} tick={false} />
                                                    <YAxis axisLine={false} tick={false} />
                                                    <Tooltip />
                                                    <Line type="monotone" dataKey="uv" stroke="#223D64" dot={{ stroke: '#223D64', strokeWidth: 2, r: 5 }} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12 newPadding">
                            <div className="outerBox">
                                <div className="innerBox">
                                    <div className="HeadingBox" style={{ backgroundColor: '#4481d5' }}>
                                        <h5>Cash On Hand</h5>
                                    </div>

                                    <div className="Linecharts">
                                        <h5>228 $</h5>
                                        <div className="charthead">
                                            <ResponsiveContainer width="100%" height="100%">
                                                <LineChart
                                                    width={560}
                                                    height={300}
                                                    data={Linechartdata}
                                                    margin={{
                                                        top: 20, right: 10, left: -5, bottom: 10,
                                                    }}
                                                >
                                                    <CartesianGrid stroke="none" />
                                                    <XAxis dataKey="name" axisLine={false} tick={false} />
                                                    <YAxis axisLine={false} tick={false} />
                                                    <Tooltip />
                                                    <Line type="monotone" dataKey="uv" stroke="#4481d5" dot={{ stroke: '#4481d5', strokeWidth: 2, r: 5 }} />
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xs-12 newPadding">
                            <div className="outerBox">
                                <div className="innerBox">
                                    <div className="HeadingBox WidthInc" style={{ backgroundColor: '#4481d5' }}>
                                        <h5>AP Funnel</h5>
                                    </div>

                                    <div className="Linecharts">
                                        <div className="charthead Barchart">
                                            <ResponsiveContainer width="100%" height="100%">
                                                <BarChart
                                                    width={560}
                                                    height={300}
                                                    data={Bardata}
                                                    barSize={40}
                                                    margin={{
                                                        top: 20, right: 0, left: -5, bottom: 10,
                                                    }}
                                                >
                                                    <CartesianGrid vertical={false} />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Bar type="monotone" dataKey="uv" fill="#352b95ab" radius={[5,5,5,5]} />
                                                    {/* <Bar type="monotone" dataKey="uv" fill="#6db6ff" radius={[5,5,5,5]} /> */}
                                                    {/* <Bar type="monotone" dataKey="uv" fill="#4481d5" radius={[5,5,5,5]} /> */}
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12 newPadding">
                            <div className="outerBox">
                                <div className="innerBox">
                                    <div className="HeadingBox WidthInc" style={{ backgroundColor: '#223d64' }}>
                                        <h5>Invoices By Status</h5>
                                    </div>

                                    <div className="Linecharts">
                                        <div className="charthead Barchart">
                                            <ResponsiveContainer width="100%" height="100%">
                                                <PieChart
                                                    width={300}
                                                    height={300}
                                                >
                                                    {/* <Pie data={InvoicePieData} dataKey="value" cx="50%" cy="50%" outerRadius={40} innerRadius={30} fill="#8884d8" >
                                                        {InvoicePieData.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={Piecolors[index % Piecolors.length]} />
                                                        ))}
                                                    </Pie>
                                                    <Pie data={InvoicePieData} dataKey="value" cx="50%" cy="50%" outerRadius={45} innerRadius={40} fill="#cccaca" >
                                                       
                                                    </Pie> */}
                                                    <Pie data={InvoicePieData} dataKey="value" cx="50%" cy="50%" innerRadius={45} outerRadius={90} cornerRadius={3} fill="#82ca9d"
                                                        label={({ value, name, ...otherData }) => <CustomPieLabel value={value} name={name} otherData={otherData} />}
                                                    >
                                                        {InvoicePieData.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={Piecolors[index % Piecolors.length]} />
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12 newPadding">
                            <div className="outerBox">
                                <div className="innerBox">
                                    <div className="HeadingBox WidthInc" style={{ backgroundColor: '#223d64' }}>
                                        <h5>Average Creditor Days</h5>
                                    </div>

                                    <div className="Linecharts">
                                        <div className="charthead PieNeedlechart">
                                            <NeedlePiechart data={NeedlePieData} DaysValue={'122,6'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12 newPadding">
                            <div className="outerBox">
                                <div className="innerBox">
                                    <div className="HeadingBox WidthInc" style={{ backgroundColor: '#4481d5' }}>
                                        <h5>Account Payable Age</h5>
                                    </div>

                                    <div className="Linecharts">
                                        <div className="charthead PieNeedlechart">
                                            <ResponsiveContainer width="100%" height="100%">
                                                <BarChart
                                                    width={560}
                                                    height={300}
                                                    data={AccountPayableAgeData}
                                                    barSize={25}
                                                    layout="vertical"
                                                    margin={{
                                                        top: 20, right: 60, left: -5, bottom: 10,
                                                    }}
                                                >
                                                    <CartesianGrid vertical={false} horizontal={false} />
                                                    <XAxis type="number" />
                                                    <YAxis type="category" dataKey="name" />
                                                    <Tooltip />
                                                    <Bar type="monotone" dataKey="uv" fill="#352b95ab" label={{ position: 'right' }} onClick={(data, index) => handleBarClick(data)}  radius={[0,20,20,0]}/>
                                                    {/* <Bar type="monotone" dataKey="uv" fill="#4481d5" label={{ position: 'right' }} onClick={(data, index) => handleBarClick(data)} /> */}
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    tablePopup ?
                        <TablePopup tablePopupData={tablePopupData} close={closePopup} tabName={'DebtManagementDashboard'} tablePopupDataType={tablePopupDataType} />
                        : ''}
            </div>
        </>
    )
}


const CustomPieLabel = ({ value, name, otherData }) => {
    const RADIAN = Math.PI / 180;
    const radius = otherData.innerRadius + (otherData.outerRadius - otherData.innerRadius) * 0.5;
    const newx = otherData.cx + radius * Math.cos(-otherData.midAngle * RADIAN);
    const newy = otherData.cy + radius * Math.sin(-otherData.midAngle * RADIAN);

    const labelRadius = otherData.outerRadius * 1.5;
    const labelX = otherData.cx + labelRadius * Math.cos(-otherData.midAngle * RADIAN);
    const labelY = otherData.cy + labelRadius * Math.sin(-otherData.midAngle * RADIAN);

    const horizontalLabelX = otherData.cx + (labelRadius + 20) * Math.cos(-otherData.midAngle * RADIAN);
    const horizontalLabelY = labelY;

    const isLeftSide = labelX < otherData.cx;
    return (
        <>
            <text x={newx} y={newy} fill="#fff" textAnchor="middle" dominantBaseline="central">
                {value}
            </text>
            <path
                d={`M${newx},${newy} L${labelX},${labelY} L${horizontalLabelX},${horizontalLabelY}`}
                stroke="#000"
                fill="none"
            />
            {/* <text x={labelX} y={labelY} fill="#000" textAnchor="middle" dominantBaseline="central" style={{ fontSize: '16px' }}>
                {name}
            </text> */}
            <text
                x={isLeftSide ? horizontalLabelX - 10 : horizontalLabelX + 10}
                y={horizontalLabelY}
                fill="#000"
                textAnchor={isLeftSide ? 'end' : 'start'}
                dominantBaseline="central"
                style={{ fontSize: '16px' }}
            >
                {name}
            </text>
        </>
    );
};


function NeedlePiechart({ data, DaysValue }) {

    const Piecolors2 = ['#122a4d', '#2f5488', '#4481d5'];
    // const Piecolors2 = ['#223d64', '#2f5488', '#4481d5'];
    const cx = 250;
    const cy = 150;
    const iR = 80;
    // const iR = 105;
    const oR = 140;
    const value = 50;

    const RADIAN = Math.PI / 180;


    const needle = (value, data, cx, cy, iR, oR) => {
        let total = 0;
        data.forEach((v) => {
            total += v.value;
        });
        const ang = 180.0 * (1 - value / total);
        const length = ((iR + 2 * oR) / 3) - 50;
        const sin = Math.sin(-RADIAN * ang);
        const cos = Math.cos(-RADIAN * ang);
        const r = 5;
        const x0 = cx + 5;
        const y0 = cy + 5;
        const xba = x0 + r * sin;
        const yba = y0 - r * cos;
        const xbb = x0 - r * sin;
        const ybb = y0 + r * cos;
        const xp = x0 + length * cos;
        const yp = y0 + length * sin;

        return [

            <circle cx={x0} cy={y0} r={14} fill={'#232F63'} stroke="none" />,
            <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={'#232F63'} />,
            <circle cx={x0} cy={y0} r={11} fill={'#fff'} stroke="none" />,
            <text x={x0} y={y0 + 30} fill="#000" textAnchor="middle" dominantBaseline="central" style={{ fontSize: '20px', fontWeight: '500' }}>
                {DaysValue + ' Days'}
            </text>
        ];
    };

    return (
        <PieChart width={560} height={300}>
            {/* <Pie data={data} dataKey="value" cx={cx} cy={cy}
                outerRadius={90} innerRadius={70}
                fill="#8884d8"
                startAngle={180}
                endAngle={0}
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={Piecolors2[index % Piecolors2.length]} />
                ))}
            </Pie> */}
            {/* <Pie data={data} dataKey="value" cx={cx} cy={cy}
                outerRadius={105} innerRadius={90}
                fill="#cccaca"
                // fill="#c4c1c1"
                startAngle={180}
                endAngle={0}
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={Piecolors2[index % Piecolors2.length]} />
                ))}
            </Pie> */}
            <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                cornerRadius={3}
                innerRadius={iR}
                outerRadius={oR}
                fill="#8884d8"
                stroke="none"
            >
                {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={Piecolors2[index % Piecolors2.length]} />
                ))}
            </Pie>
            {needle(value, data, cx, cy, iR, oR, '#0f1523')}
        </PieChart>
    )
}

