import React, { useEffect, useState } from "react";
import { createBulKNachApi, getBukNachEntriesApi } from "../AdminActionCreator.component";
import Loader from '../../../presentationals/Loader/layerLoader.component';
import Popup from '../../../presentationals/Popup/Popup.component';
import moment from "moment";

const CreateBulkNach = ({ allStatus }) => {
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const getTodayDate = () => formatDate(new Date());

    const [allUserDetail, setAllUserDetail] = useState([]);
    const [dataToSend, setDataToSend] = useState([]);
    const [loaderState, setLoaderState] = useState(false);
    const [popupState, setPopupState] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [paymentData, setPaymentData] = useState('');
    const [startDate, setStartDate] = useState(getTodayDate());
    const [endDate, setEndDate] = useState(getTodayDate());
    const [selectAllPenalties, setSelectAllPenalties] = useState(false);
    const [addPenalty, setAddPenalty] = useState(false);

    useEffect(() => {
        getData(startDate, endDate);
    }, [startDate, endDate]);

    const getData = (startDate, endDate) => {
        setLoaderState(true);
        getBukNachEntriesApi(startDate, endDate, callBack => {
            if (Array.isArray(callBack)) {
                setAllUserDetail(callBack.map(data => ({ ...data, isSelected: false, penaltyInclude: false })));
            } else {
                setAllUserDetail([]);
            }
            setDataToSend([]);
            setLoaderState(false);
        });
    };

    const handleDateChange = (setter) => (e) => {
        const date = new Date(e.target.value);
        setter(formatDate(date));
    };

    const selectAll = (e) => {
        const checked = e.target.checked;
        const updatedData = allUserDetail.map(data => ({
            ...data,
            isSelected: checked,
            penaltyInclude: checked && selectAllPenalties
        }));

        setAllUserDetail(updatedData);
        setDataToSend(
            checked
                ? updatedData.map(data => ({
                    loanId: data.loanId,
                    amount: data.penaltyInclude ? data.overdueAmount : data.emiAmount,
                    emiId: data.emiId
                }))
                : []
        );
    };

    const selectAllPenaltiesHandler = (e) => {
        const checked = e.target.checked;
        setSelectAllPenalties(checked);
        const updatedData = allUserDetail.map(data => ({
            ...data,
            penaltyInclude: checked,
            amount: checked ? data.overdueAmount : data.emiAmount
        }));
        setAllUserDetail(updatedData);

        setDataToSend(
            updatedData
                .filter(data => data.isSelected)
                .map(data => ({
                    loanId: data.loanId,
                    amount: data.penaltyInclude ? data.overdueAmount : data.emiAmount,
                    emiId: data.emiId
                }))
        );
    };

    const changeHandlerCheckbox = (e, data, index) => {
        const checked = e.target.checked;
        const updatedData = allUserDetail.map((item, i) =>
            i === index ? { ...item, isSelected: checked } : item
        );
        setAllUserDetail(updatedData);

        setDataToSend(prev =>
            checked
                ? [...prev, {
                    loanId: data.loanId,
                    amount: data.penaltyInclude ? data.overdueAmount : data.emiAmount,
                    emiId: data.emiId
                }]
                : prev.filter(item => item.loanId !== data.loanId)
        );
    };

    const submit = () => {
        if (dataToSend.length > 0) {
            setLoaderState(true);
            createBulKNachApi(dataToSend, callback => {
                setLoaderState(false);
                setPaymentData(JSON.parse(callback));
                setPopupState(true);
                setPopupStatus('Done Successfully!');
                removerPopup();
            });
        } else {
            setPopupState(true);
            setPopupStatus('Please select application Id');
            removerPopup();
        }
    };

    const removerPopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    const closePopup = () => {
        setPopupState(false);
    };

    return (
        <div className="container-fluid">
            <h3><b>Create Bulk NACH</b></h3>
            {loaderState && <Loader />}
            {popupState && <Popup closePopup={closePopup} popupStatus={popupStatus} />}
            {!loaderState && (
                <>
                    <div className="row" style={{ marginTop: '12px', marginBottom: '12px' }}>
                        <div className="col-sm-2 col-xs-12">
                            <label>Start Date</label>
                            <input
                                type="date"
                                className="form-control"
                                value={startDate.split('-').reverse().join('-')}
                                onChange={handleDateChange(setStartDate)}
                            />
                        </div>
                        <div className="col-sm-2 col-xs-12">
                            <label>End Date</label>
                            <input
                                type="date"
                                className="form-control"
                                value={endDate.split('-').reverse().join('-')}
                                onChange={handleDateChange(setEndDate)}
                            />
                        </div>
                        <div className="col-sm-2 col-xs-12" style={{ marginTop: '20px' }}>
                            <div style={{ display: 'flex' }}>
                                <input
                                    style={{ height: '30px', width: '30px' }}
                                    type="checkbox"
                                    onChange={selectAllPenaltiesHandler}
                                />&nbsp; <div style={{ marginTop: '10px' }}> &nbsp;Add Penalty</div>
                            </div>
                        </div>
                        <div className="col-sm-4 col-xs-12"></div>
                        <div className="col-sm-2 col-xs-12" style={{ textAlign: 'right' }}>
                            <div style={{ marginTop: '20px', marginBottom: '5px' }}>
                                <button className="btn btn-primary" onClick={submit}>Create NACH</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><input type="checkbox" onChange={selectAll} />&nbsp;Select All</th>
                                        <th>Application ID</th>
                                        <th>EMI Amount</th>
                                        <th>EMI Date</th>
                                        <th>Auto Debit Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(allUserDetail) && allUserDetail.map((data, i) => (
                                        <tr key={data.applicationId}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    id={'disburseIcici' + i}
                                                    checked={data.isSelected || false}
                                                    onChange={(e) => changeHandlerCheckbox(e, data, i)}
                                                />
                                            </td>
                                            <td>{data.applicationId}</td>
                                            <td>{data.emiAmount}</td>
                                            <td>{moment(data.emiDueDate).format('DD-MM-YYYY')}</td>
                                            <td style={{ color: data.penaltyInclude ? 'green' : '' }}>
                                                {data.penaltyInclude ? data.overdueAmount : data.emiAmount}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CreateBulkNach;
