import React from 'react'
import CompleteLeads from './CompleteLeads';
import IncompleteLead from './Incomplete';
import RepeatLead from './repeatleads';
import Legal from './legal';

class MainTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewTab: 'Complete',
            userStatusData: '',
            loaderState: false
        }
    }
    buttonHandle(data) {
        this.setState({ viewTab: data })
    }
    // getAssignedByStatus() {
    //     this.setState({ loaderState: true })
    //     fetch(APIS.GET_ASSIGN_BY_STATUS)
    //         .then(res => res.json())
    //         .then(res => {
    //             this.setState({
    //                 userStatusData: res,
    //                 loaderState: false
    //             })

    //         })
    // }
    // componentDidMount() {
    //     this.getAssignedByStatus()
    // }
    render() {
        const { admin } = this.props
        return (
            <div className='conatainer-fluid details-new'>

                <div className="btn-group btn-grp-own" role="group" aria-label="onboard-btn" style={{ marginTop: '10px', padding: '10px' }}>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'Complete' ? '#1D3557' : '#F1F7FF', color: this.state.viewTab === 'Complete' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('Complete')}> Complete </button>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'Incomplete' ? '#1D3557' : '#F1F7FF', color: this.state.viewTab === 'Incomplete' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('Incomplete')} > Incomplete </button>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'Repeat' ? '#1D3557' : '#F1F7FF', color: this.state.viewTab === 'Repeat' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('Repeat')} > Repeat </button>
                    <button className="btn btn-outline-primary" style={{ background: this.state.viewTab === 'Operations' ? '#1D3557' : '#F1F7FF', color: this.state.viewTab === 'Operations' ? '#fff' : '#000' }} onClick={() => this.buttonHandle('Operations')} > Operations </button>

                </div>
                {this.state.viewTab === 'Complete' ?
                    <CompleteLeads admin={admin} userStatusData={this.state.userStatusData} />
                    : ""}
                {this.state.viewTab === 'Incomplete' ?
                    <IncompleteLead admin={admin} userStatusData={this.state.userStatusData} />
                    : ""}
                {this.state.viewTab === 'Repeat' ?
                    <RepeatLead admin={admin} userStatusData={this.state.userStatusData} />
                    : ""}
                {this.state.viewTab === 'Operations' ?
                    <Legal admin={admin} userStatusData={this.state.userStatusData} />
                    : ""}
            </div>
        )
    }
}
export default MainTab