import React, { useState, useEffect } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Scatter,ComposedChart,Line, } from 'recharts';

const APIDatashow3month = {
    'Projected Loan amt disbrused': [
        {
            date: 'June 2024',
            value: '60000000',value2:'59388300'
        },
        {
            date: 'July 2024',
            value: '60000000',value2:'54388300'
        },
        {
            date: 'August 2024',
            value: '60000000',value2:'60388300'
        },
    ],
    'Actual Loan amt disbrused': [
        {
            date: 'June 2024',
            value: '49502200',value2:'38938300'
        },
        {
            date: 'July 2024',
            value: '56354000',value2:'68938300'
        },
        {
            date: 'August 2024',
            value: '36652000',value2:'48038300'
        },
    ],
    'Growth of Loan disbrused amt in %': [
        {
            date: 'June 2024',
            value: '28',value2:'30'
        },
        {
            date: 'July 2024',
            value: '19',value2:'5'
        },
        {
            date: 'August 2024',
            value: '88',value2:'57'
        },
    ],
    'Growth of Loan disbrused amt in % Highest Disbrushed': [
        {
            date: 'June 2024',
            value: '59.35',value2:'84.93'
        },
        {
            date: 'July 2024',
            value: '66.08',value2:'73.03'
        },
        {
            date: 'August 2024',
            value: '76.40',value2:'93.02'
        },
    ],
    'Actual Loan Amt disbursed(NC)': [
        {
            date: 'June 2024',
            value: '17053000',value2:'28938300'
        },
        {
            date: 'July 2024',
            value: '14034000',value2:'48938300'
        },
        {
            date: 'August 2024',
            value: '6602000',value2:'58938300'
        },
    ],
    'Actual Loan Amt disbursed(EC)': [
        {
            date: 'June 2024',
            value: '12334024',value2:'12938300'
        },
        {
            date: 'July 2024',
            value: '10394000',value2:'18378200'
        },
        {
            date: 'August 2024',
            value: '14034000',value2:'12038638'
        },
    ],
    'Average Projected Loan Ticket size': [
        {
            date: 'June 2024',
            value: '18000',value2:'16383'
        },
        {
            date: 'July 2024',
            value: '17856',value2:'17392'
        },
        {
            date: 'August 2024',
            value: '19333',value2:'14262'
        },
    ],
    'Target Achieved %': [
        {
            date: 'June 2024',
            value: '103',value2:'91'
        },
        {
            date: 'July 2024',
            value: '90',value2:'89'
        },
        {
            date: 'August 2024',
            value: '61',value2:'78'
        },
    ],
    'Average New Loan Ticket size disbrushed': [
        {
            date: 'June 2024',
            value: '15072',value2:'13893'
        },
        {
            date: 'July 2024',
            value: '16131',value2:'14793'
        },
        {
            date: 'August 2024',
            value: '14671',value2:'16829'
        },
    ],
    'Average Existing Loan Ticket size disbrushed': [
        {
            date: 'June 2024',
            value: '21307',value2:'17927'
        },
        {
            date: 'July 2024',
            value: '20132',value2:'16839'
        },
        {
            date: 'August 2024',
            value: '20635',value2:'23892'
        },
    ],
    'Average Loan Ticket size disbrushed': [
        {
            date: 'June 2024',
            value: '19412',value2:'28922'
        },
        {
            date: 'July 2024',
            value: '19229',value2:'16728'
        },
        {
            date: 'August 2024',
            value: '19220',value2:'16829'
        },
    ],
    '% of Ticket Size': [
        {
            date: 'June 2024',
            value: '107',value2:'103'
        },
        {
            date: 'July 2024',
            value: '109',value2:'97'
        },
        {
            date: 'August 2024',
            value: '112',value2:'88'
        },
    ],
}

const APIDatashow6month = {
    'Projected Loan amt disbrused': [
        {
            date: 'March 2024',
            value: '50000000',
            value2:'40000000'
        },
        {
            date: 'April 2024',
            value: '52000000',value2:'48000980'
        },
        {
            date: 'May 2024',
            value: '54000000',value2:'48900000'
        },
        {
            date: 'June 2024',
            value: '60000000',value2:'61000000'
        },
        {
            date: 'July 2024',
            value: '60000000',value2:'48000000'
        },
        {
            date: 'August 2024',
            value: '60000000',value2:'58000000'
        },
    ],
    'Actual Loan amt disbursed': [
        {
            date: 'March 2024',
            value: '48000000',value2:'45000000'
        },
        {
            date: 'April 2024',
            value: '50000000',value2:'38000000'
        },
        {
            date: 'May 2024',
            value: '52000000',value2:'45000000'
        },
        {
            date: 'June 2024',
            value: '54000000',value2:'52000000'
        },
        {
            date: 'July 2024',
            value: '56000000',value2:'28000000'
        },
        {
            date: 'August 2024',
            value: '58000000',value2:'38008200'
        },
    ],
    'Growth of Loan disbursed amt in %': [
        {
            date: 'March 2024',
            value: '10',value2:'10'
        },
        {
            date: 'April 2024',
            value: '7',value2:'9'
        },
        {
            date: 'May 2024',
            value: '8',value2:'6'
        },
        {
            date: 'June 2024',
            value: '10',value2:'7'
        },
        {
            date: 'July 2024',
            value: '12',value2:'5'
        },
        {
            date: 'August 2024',
            value: '14',value2:'9'
        },
    ],
    'Growth of Loan disbursed amt in % Highest Disbursed': [
        {
            date: 'March 2024',
            value: '50',value2:'25'
        },
        {
            date: 'April 2024',
            value: '52',value2:'12'
        },
        {
            date: 'May 2024',
            value: '55',value2:'80'
        },
        {
            date: 'June 2024',
            value: '58',value2:'30'
        },
        {
            date: 'July 2024',
            value: '61',value2:'73'
        },
        {
            date: 'August 2024',
            value: '64',value2:'65'
        },
    ],
    'Actual Loan Amt disbursed(NC)': [
        {
            date: 'March 2024',
            value: '16000000',value2:'18000000'
        },
        {
            date: 'April 2024',
            value: '16500000',value2:'8000000'
        },
        {
            date: 'May 2024',
            value: '17000000',value2:'9000000'
        },
        {
            date: 'June 2024',
            value: '17500000',value2:'20000000'
        },
        {
            date: 'July 2024',
            value: '18000000',value2:'17207200'
        },
        {
            date: 'August 2024',
            value: '18500000',value2:'17282929'
        },
    ],
    'Actual Loan Amt disbursed(EC)': [
        {
            date: 'March 2024',
            value: '12000000',value2:'8000000'
        },
        {
            date: 'April 2024',
            value: '12500000',value2:'15583900'
        },
        {
            date: 'May 2024',
            value: '13000000',value2:'11000000'
        },
        {
            date: 'June 2024',
            value: '13500000',value2:'9000000'
        },
        {
            date: 'July 2024',
            value: '14000000',value2:'7000000'
        },
        {
            date: 'August 2024',
            value: '14500000',value2:'10000000'
        },
    ],
    'Average Projected Loan Ticket size': [
        {
            date: 'March 2024',
            value: '18000',value2:'20000'
        },
        {
            date: 'April 2024',
            value: '18200',value2:'15000'
        },
        {
            date: 'May 2024',
            value: '18400',value2:'17500'
        },
        {
            date: 'June 2024',
            value: '18600',value2:'20050'
        },
        {
            date: 'July 2024',
            value: '18800',value2:'19000'
        },
        {
            date: 'August 2024',
            value: '19000',value2:'20000'
        },
    ],
    'Target Achieved %': [
        {
            date: 'March 2024',
            value: '95',value2:'80'
        },
        {
            date: 'April 2024',
            value: '97',value2:'90'
        },
        {
            date: 'May 2024',
            value: '99',value2:'85'
        },
        {
            date: 'June 2024',
            value: '100',value2:'78.03'
        },
        {
            date: 'July 2024',
            value: '102',value2:'98.04'
        },
        {
            date: 'August 2024',
            value: '105',value2:'94.50'
        },
    ],
    'Average New Loan Ticket size disbursed': [
        {
            date: 'March 2024',
            value: '15000',value2:'10000'
        },
        {
            date: 'April 2024',
            value: '15500',value2:'18000'
        },
        {
            date: 'May 2024',
            value: '16000',value2:'17911'
        },
        {
            date: 'June 2024',
            value: '16500',value2:'9333'
        },
        {
            date: 'July 2024',
            value: '17000',value2:'10005'
        },
        {
            date: 'August 2024',
            value: '17500',value2:'8292'
        },
    ],
    'Average Existing Loan Ticket size disbursed': [
        {
            date: 'March 2024',
            value: '20000',value2:'12300'
        },
        {
            date: 'April 2024',
            value: '20500',value2:'21803'
        },
        {
            date: 'May 2024',
            value: '21000',value2:'20000'
        },
        {
            date: 'June 2024',
            value: '21500',value2:'27191'
        },
        {
            date: 'July 2024',
            value: '22000',value2:'18390'
        },
        {
            date: 'August 2024',
            value: '22500',value2:'18389'
        },
    ],
    'Average Loan Ticket size disbursed': [
        {
            date: 'March 2024',
            value: '19000',value2:'18380'
        },
        {
            date: 'April 2024',
            value: '19200',value2:'13380'
        },
        {
            date: 'May 2024',
            value: '19400',value2:'12390'
        },
        {
            date: 'June 2024',
            value: '19600',value2:'28493'
        },
        {
            date: 'July 2024',
            value: '19800',value2:'12920'
        },
        {
            date: 'August 2024',
            value: '20000',value2:'24083'
        },
    ],
    '% of Ticket Size': [
        {
            date: 'March 2024',
            value: '105',value2:'80'
        },
        {
            date: 'April 2024',
            value: '106',value2:'90'
        },
        {
            date: 'May 2024',
            value: '107',value2:'85'
        },
        {
            date: 'June 2024',
            value: '108',value2:'92'
        },
        {
            date: 'July 2024',
            value: '109',value2:'100'
        },
        {
            date: 'August 2024',
            value: '110',value2:'85'
        },
    ],
}
const APIDatashow1year = {
    'Projected Loan amt disbrused': [
        { date: 'September 2023', value: '35000000',value2:'23432522' },
        { date: 'October 2023', value: '40000000' ,value2:'24432522'},
        { date: 'November 2023', value: '40000000',value2:'43432522' },
        { date: 'December 2023', value: '40000000',value2:'23434522' },
        { date: 'January 2024', value: '45000000' ,value2:'33432522'},
        { date: 'February 2024', value: '50000000',value2:'42232522' },
        {
            date: 'March 2024',
            value: '50000000',
            value2:'42232522'
        },
        {
            date: 'April 2024',
            value: '52000000',
            value2:'51232522'
        },
        {
            date: 'May 2024',
            value: '54000000',
            value2:'55232522'
        },
        {
            date: 'June 2024',
            value: '60000000',
            value2:'55232522'
        },
        {
            date: 'July 2024',
            value: '60000000',
            value2:'57232522'
        },
        {
            date: 'August 2024',
            value: '60000000',
            value2:'58232522'
        },
    ],
    'Actual Loan amt disbursed': [
        { date: 'September 2023', value: '30000000',value2:'30003680' },
        { date: 'October 2023', value: '38000000' ,value2:'22379522'},
        { date: 'November 2023', value: '38000000',value2:'20038000' },
        { date: 'December 2023', value: '40000000' ,value2:'22338000'},
        { date: 'January 2024', value: '45000000',value2:'32038000' },
        { date: 'February 2024', value: '48000000',value2:'45038000' },
        {
            date: 'March 2024',
            value: '48000000'
            ,value2:'54038000'
        },
        {
            date: 'April 2024',
            value: '50000000',value2:'45038000'
        },
        {
            date: 'May 2024',
            value: '52000000',value2:'56038000'
        },
        {
            date: 'June 2024',
            value: '54000000',
            value2:'56038000'
        },
        {
            date: 'July 2024',
            value: '56000000',
            value2:'54038000'
        },
        {
            date: 'August 2024',
            value: '58000000',
            value2:'36038000'
        },
    ],
    'Growth of Loan disbursed amt in %': [
        { date: 'September 2023', value: '12' ,value2:'8'},
        { date: 'October 2023', value: '8.5',value2:'10' },
        { date: 'November 2023', value: '10' ,value2:'12'},
        { date: 'December 2023', value: '8' ,value2:'5'},
        { date: 'January 2024', value: '12' ,value2:'8'},
        { date: 'February 2024', value: '10',value2:'5' },
        {
            date: 'March 2024',
            value: '8'
            ,value2:'8'
        },
        {
            date: 'April 2024',
            value: '10',value2:'5'
        },
        {
            date: 'May 2024',
            value: '12',value2:'10'
        },
        {
            date: 'June 2024',
            value: '8',value2:'8'
        },
        {
            date: 'July 2024',
            value: '12',value2:'10'
        },
        {
            date: 'August 2024',
            value: '9',value2:'12'
        },
    ],
    'Growth of Loan disbursed amt in % Highest Disbursed': [
        { date: 'September 2023', value: '50',value2:'38' },
        { date: 'October 2023', value: '60' ,value2:'47'},
        { date: 'November 2023', value: '55' ,value2:'58'},
        { date: 'December 2023', value: '45' ,value2:'54'},
        { date: 'January 2024', value: '58.7' ,value2:'45'},
        { date: 'February 2024', value: '60' ,value2:'52'},
        {
            date: 'March 2024',
            value: '50',value2:'48'
        },
        {
            date: 'April 2024',
            value: '52',value2:'56'
        },
        {
            date: 'May 2024',
            value: '55',value2:'45'
        },
        {
            date: 'June 2024',
            value: '58',value2:'40'
        },
        {
            date: 'July 2024',
            value: '61',value2:'50'
        },
        {
            date: 'August 2024',
            value: '64',value2:'60'
        },
    ],
    'Actual Loan Amt disbursed(NC)': [
        { date: 'September 2023', value: '16500000',value2:'16000000' },
        { date: 'October 2023', value: '15000000' ,value2:'14500000'},
        { date: 'November 2023', value: '14000000' ,value2:'13497000'},
        { date: 'December 2023', value: '16500000',value2:'15000368' },
        { date: 'January 2024', value: '15500000' ,value2:'10000000'},
        { date: 'February 2024', value: '16000000',value2:'12500000' },
        {
            date: 'March 2024',
            value: '16000000',value2:'12500000'
        },
        {
            date: 'April 2024',
            value: '16500000',value2:'11500000'
        },
        {
            date: 'May 2024',
            value: '17000000',value2:'15000000'
        },
        {
            date: 'June 2024',
            value: '17500000',value2:'13500350'
        },
        {
            date: 'July 2024',
            value: '18000000',value2:'12509000'
        },
        {
            date: 'August 2024',
            value: '18500000',value2:'19500000'
        },
    ],
    'Actual Loan Amt disbursed(EC)': [
        { date: 'September 2023', value: '13420000' ,value2:'12500000'},
        { date: 'October 2023', value: '12000000' ,value2:'12000000'},
        { date: 'November 2023', value: '10000000' ,value2:'16500000'},
        { date: 'December 2023', value: '10500000',value2:'14500000' },
        { date: 'January 2024', value: '11000000',value2:'12500000' },
        { date: 'February 2024', value: '11500000',value2:'10500000' },
        {
            date: 'March 2024',
            value: '12000000',value2:'12000890'
        },
        {
            date: 'April 2024',
            value: '12500000',value2:'10500000'
        },
        {
            date: 'May 2024',
            value: '13000000',value2:'12500000'
        },
        {
            date: 'June 2024',
            value: '13500000',value2:'12300900'
        },
        {
            date: 'July 2024',
            value: '14000000',value2:'14500000'
        },
        {
            date: 'August 2024',
            value: '14500000',value2:'12500000'
        },
    ],
    'Average Projected Loan Ticket size': [
        { date: 'September 2023', value: '15700',value2:'19739' },
        { date: 'October 2023', value: '17500' ,value2:'18739'},
        { date: 'November 2023', value: '18100',value2:'12749' },
        { date: 'December 2023', value: '18200',value2:'14683' },
        { date: 'January 2024', value: '17000' ,value2:'18729'},
        { date: 'February 2024', value: '17500',value2:'18329' },
        {
            date: 'March 2024',
            value: '18000',value2:'18392'
        },
        {
            date: 'April 2024',
            value: '18200',value2:'12426'
        },
        {
            date: 'May 2024',
            value: '18400',value2:'11182'
        },
        {
            date: 'June 2024',
            value: '18600',value2:'12829'
        },
        {
            date: 'July 2024',
            value: '18800',value2:'19739'
        },
        {
            date: 'August 2024',
            value: '19000',value2:'13739'
        },
    ],
    'Target Achieved %': [
        { date: 'September 2023', value: '96.3' ,value2:'91'},
        { date: 'October 2023', value: '94' ,value2:'79'},
        { date: 'November 2023', value: '89',value2:'99' },
        { date: 'December 2023', value: '95' ,value2:'85'},
        { date: 'January 2024', value: '94' ,value2:'97'},
        { date: 'February 2024', value: '92',value2:'93' },
        {
            date: 'March 2024',
            value: '95',value2:'83'
        },
        {
            date: 'April 2024',
            value: '97',value2:'92'
        },
        {
            date: 'May 2024',
            value: '99',value2:'83'
        },
        {
            date: 'June 2024',
            value: '100',value2:'90'
        },
        {
            date: 'July 2024',
            value: '102',value2:'91'
        },
        {
            date: 'August 2024',
            value: '105',value2:'95'
        },
    ],
    'Average New Loan Ticket size disbursed': [
        { date: 'September 2023', value: '15500' ,value2:'13682'},
        { date: 'October 2023', value: '14500' ,value2:'13783'},
        { date: 'November 2023', value: '16500' ,value2:'13993'},
        { date: 'December 2023', value: '15500' ,value2:'13930'},
        { date: 'January 2024', value: '14500' ,value2:'13893'},
        { date: 'February 2024', value: '15000' ,value2:'13839'},
        {
            date: 'March 2024',
            value: '15000',value2:'13893'
        },
        {
            date: 'April 2024',
            value: '15500',value2:'13383'
        },
        {
            date: 'May 2024',
            value: '16000',value2:'12784'
        },
        {
            date: 'June 2024',
            value: '16500',value2:'14902'
        },
        {
            date: 'July 2024',
            value: '17000',value2:'13893'
        },
        {
            date: 'August 2024',
            value: '17500',value2:'13938'
        },
    ],
    'Average Existing Loan Ticket size disbursed': [
        { date: 'September 2023', value: '19000',value2:'15371' },
        { date: 'October 2023', value: '22000',value2:'27291' },
        { date: 'November 2023', value: '19400',value2:'21771' },
        { date: 'December 2023', value: '20000',value2:'24571' },
        { date: 'January 2024', value: '19239' ,value2:'29771'},
        { date: 'February 2024', value: '20000',value2:'26771' },
        {
            date: 'March 2024',
            value: '20000',value2:'18371'
        },
        {
            date: 'April 2024',
            value: '20500',value2:'19771'
        },
        {
            date: 'May 2024',
            value: '21000',value2:'19771'
        },
        {
            date: 'June 2024',
            value: '21500',value2:'17371'
        },
        {
            date: 'July 2024',
            value: '22000',value2:'19371'
        },
        {
            date: 'August 2024',
            value: '22500',value2:'19371'
        },
    ],
    'Average Loan Ticket size disbursed': [
        { date: 'September 2023', value: '24200',value2:'24931' },
        { date: 'October 2023', value: '23200' ,value2:'22231'},
        { date: 'November 2023', value: '23050',value2:'26451' },
        { date: 'December 2023', value: '20200',value2:'25451' },
        { date: 'January 2024', value: '18000',value2:'21271' },
        { date: 'February 2024', value: '19000',value2:'18481' },
        {
            date: 'March 2024',
            value: '19000',value2:'18691'
        },
        {
            date: 'April 2024',
            value: '19200',value2:'15961'
        },
        {
            date: 'May 2024',
            value: '19400',value2:'15881'
        },
        {
            date: 'June 2024',
            value: '19600',value2:'15481'
        },
        {
            date: 'July 2024',
            value: '26800',value2:'15481'
        },
        {
            date: 'August 2024',
            value: '20000',value2:'15481'
        },
    ],
    '% of Ticket Size': [
        { date: 'September 2023', value: '100',value2:'91' },
        { date: 'October 2023', value: '109',value2:'91' },
        { date: 'November 2023', value: '108',value2:'93' },
        { date: 'December 2023', value: '102',value2:'89' },
        { date: 'January 2024', value: '105',value2:'92' },
        { date: 'February 2024', value: '104',value2:'89' },
        {
            date: 'March 2024',
            value: '105',value2:'86'
        },
        {
            date: 'April 2024',
            value: '106',value2:'84'
        },
        {
            date: 'May 2024',
            value: '107',value2:'82'
        },
        {
            date: 'June 2024',
            value: '108',value2:'92'
        },
        {
            date: 'July 2024',
            value: '109',value2:'83'
        },
        {
            date: 'August 2024',
            value: '110',value2:'32'
        },
    ],
}





export default function TicketSize({ fromDate, toDate, applyFilter, handleApplyFilter, clearFilter, handleclearFilter, edit, handleEdit,ActiveMonthFilter  }) {

    const [selectedData, setSelectedData] = useState([]);
    const [selectedDataIndex, setSelectedDataIndex] = useState(-1);
    const [selectedDataHeading, setSelectedDataHeading] = useState('')
    const [APIData,setAPIData]=useState(APIDatashow3month)
    useEffect(() => {
        if (applyFilter == true) {
            console.log("apply", fromDate, toDate)
            // api call for applying filter here 
            setTimeout(() => {
                handleApplyFilter()
            }, 500)
        }
        if (clearFilter == true) {
            setAPIData(APIDatashow3month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')

            console.log("clear", fromDate, toDate)
            // api call for clear filter here 
            setTimeout(() => {
                handleclearFilter()
            }, 500)
        }
        if(edit == true){
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
            setSelectedData([])
        }
         if(ActiveMonthFilter === '3month'){
            setAPIData(APIDatashow3month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        }else if(ActiveMonthFilter === '6month'){
            setAPIData(APIDatashow6month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        }else if(ActiveMonthFilter === '1year'){
            setAPIData(APIDatashow1year)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        }
    }, [applyFilter, clearFilter,edit, ActiveMonthFilter])


   
    const handleSelectedData = (index, datakey, data) => {
        setSelectedData([])
        if (index === selectedDataIndex) {
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')

        } else {
            setSelectedDataHeading(datakey)
            setSelectedDataIndex(index)
            setTimeout(()=>{
                setSelectedData(data)
            },300)  

        }
    }

    const handleEditValue = (e) => {
        const { name, value } = e.target;
    }

    const handleSavebtn = () => {
        handleEdit();
    }

    return (
        <div className="Dashboardsmis">
            {
                !edit ?
            <div className="row" style={{position:'relative'}}>
                <div className={`${selectedDataIndex == '-1' ? 'col-xs-12' : "col-md-6 col-xs-12"} transition-col`}>
                    <div className="table" style={{ marginTop: '20px' }}>
                        <div className="phocket-table-new newTable" style={{ overflowX: 'auto', marginTop: '0px' }}>
                            <table>
                                <thead style={{ position: 'sticky', top: 0 }}>
                                    <tr>
                                        <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Particulars</th>
                                        {
                                            APIData['Projected Loan amt disbrused']?.map((item, index) => (
                                                <th key={index}>{item.date}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(APIData).map(([datakey, datavalue], index) => (
                                        <tr className={selectedDataIndex === index ? 'activeTr' : ''} key={index} style={{ cursor: 'pointer' }} onClick={() => handleSelectedData(index, datakey, datavalue)}>
                                            <td className='leftsticky'><b>{datakey}</b></td>
                                            {
                                                datavalue?.map((value, index) => {
                                                    return (
                                                        <td key={index}  style={{width: ActiveMonthFilter === '3month' ? '100px':'auto'}}>{value?.value}</td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {
                    selectedData?.length > 0 ?
                        <div className="col-md-6 col-xs-12">
                            <div className="charts" style={{ marginTop: '20px' }}>
                                <h3>{selectedDataHeading}</h3>
                                <div className="chartbar" style={{ height: window?.innerWidth > 1800 ? '730px' : '460px' }}>
                                <ResponsiveContainer width="100%" height="100%">
                                                <ComposedChart
                                                    width={500}
                                                    height={300}
                                                    data={selectedData}
                                                    barSize={ActiveMonthFilter === '3month' ? 40: ActiveMonthFilter === '6month' ? 30 : 20}
                                                    margin={{
                                                        top: 20,
                                                        right: 0,
                                                        left: 30,
                                                        bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid stroke='none' />
                                                    <XAxis dataKey="date" />
                                                    <YAxis padding={{ top: 20 }}/>
                                                    <Tooltip />
                                                    <Bar dataKey="value" fill="#8884d8" />
                                                    <Line type="monotone" dataKey="value" stroke="#223D64" strokeWidth={2} dot={ null } />
                                                </ComposedChart>
                                            </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        : ''}
            </div>
            :
            <div className="row">
                <div className='col-xs-12'>
                    <div className="table" style={{ marginTop: '20px' }}>
                        <div className="phocket-table-new newTable" style={{ overflowX: 'auto', marginTop: '0px' }}>
                            <table>
                                <thead style={{ position: 'sticky', top: 0 }}>
                                    <tr>
                                        <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Particulars</th>
                                        {
                                            APIData['Projected Loan amt disbrused'].map((item, index) => (
                                                <th key={index}>{item.date}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(APIData).map(([datakey, datavalue], index) => (
                                        <tr key={index}>
                                            <td className='leftsticky'><b>{datakey}</b></td>
                                            {
                                                datavalue?.map((value, index) => {
                                                    return (
                                                        <td key={index}><input type="number" name={datakey + '-' + value?.date} value={value.value} className='Editinputtag'  onChange={(e) => handleEditValue(e)} /></td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="Filterations" style={{marginTop:'40px',textAlign:'center'}}>
                                <button className='filterbtn' style={{width:'8%'}} onClick={()=>handleSavebtn()}>Save</button>
                            </div>
                </div>
               
            </div>
}
        </div>
    )
}
