import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { APIS } from '../../../../utils/api-factory';

const SaveEmailForm = ({ editData }) => {
    const [roleName, setRoleName] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (editData !== '' && editData.roleId) {
            try {
                const result = await axios.get(APIS.EDIT_USER_ROLE + roleName + '&roleId=' + editData.roleId);
                console.log(result)
                if (result?.data?.message == 'success' && result?.data?.status == 200) {
                    setMessage('Done successfully!');
                }
                if (result?.data?.message == 'failure' && result?.data?.status == 200) {
                    setMessage('Role Already Exist.');
                }
                if (result?.data?.message == 'failure' && result?.data?.status == 500) {
                    setMessage('Error Saving Roles.');
                }

            } catch (error) {
                setMessage('Error Saving Roles.');
            }
        } else {
            try {
                const result = await axios.get(APIS.ADD_NEW_USER_ROLE + roleName);
                console.log(result)
                if (result?.data?.message == 'success' && result?.data?.status == 200) {
                    setMessage('Role Created successfully!');
                }
                if (result?.data?.message == 'failure' && result?.data?.status == 200) {
                    setMessage('Role Already Exist.');
                }
                if (result?.data?.message == 'failure' && result?.data?.status == 500) {
                    setMessage('Error Saving Roles.');
                }

            } catch (error) {
                setMessage('Error Saving Roles.');
            }
        }
    };
    useEffect(() => {
console.log(editData,'editData')
setRoleName(editData.roleName)
    }, [])


    return (
        <div className='container'>
            <div className='shadow-card'>
                <h3 style={{ marginBottom: '25px' }}>{editData !== '' && editData.roleId?'Edit':'Add'} Roles</h3>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <label style={{ whiteSpace: 'nowrap' }}>Role Name:</label>
                                <input
                                    className='form-control'
                                    type="text"
                                    value={roleName}
                                    onChange={(e) => setRoleName(e.target.value)}
                                    required
                                    style={{ width: '50%' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='text-center' style={{ marginTop: '50px' }}>
                        {message && <p>{message}</p>}
                        <button type="submit" className='btn btn-primary'>
                        {editData !== '' && editData.roleId?'Edit':'Save'} Role
                        </button>
                    </div>
                </form>
            </div>

        </div>
    );
};

export default SaveEmailForm;
