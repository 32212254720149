import React from "react";
import { useState } from "react";
import {
  getAgreegatorListApi,
  getConfigDetailsOfAgreegator,
  saveOrUpdateAggregatorPayouts,
} from "../actioncreator";
import { useEffect } from "react";
import SidePopup from "./modals/sidepopup";
import Loader from '../../../../presentationals/Loader/Loader.component'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function Configuration({ editdata,pageViewHandler }) {
  const [loaderState, setloaderState] = useState(false);
  const [configurationData, setconfigurationData] = useState({});
  const [msg, setmsg] = useState('');
  const [popupType, setpopupType] = useState('');
  const [popupState, setpopupState] = useState('');
  const [objectData, setObjectData] = useState('');

  const [errorMessage, seterrorMessage] = useState({});
  const [agreegatorData, setAgreegatorData] = useState(false);
  const [inputs, setInputs] = useState([
    { minCountSlab: '', maxCountSlab: '', costModelValue: '' },
  ]);

  const handleInputChange = (index, name, value) => {
    const newInputs = [...inputs];
    newInputs[index][name] = Number(value);
    setInputs(newInputs);
    let chn = Object.assign({}, configurationData)
    chn.slabDetail = newInputs
    setconfigurationData(chn)
  };

  const handleAddInput = () => {
    setInputs([...inputs, { minCountSlab: '', maxCountSlab: '', costModelValue: '' }]);
  };


  const changeHandler = (e, type) => {
    console.log(e.target.value)
    let changeData = Object.assign({}, configurationData);
    changeData[type] = e.target.value;
    setconfigurationData(changeData);
  };
  const removePopup = () => {
    setTimeout(() => {
      setpopupState(false)
    }, 5000);
  }
  const saveData = () => {
    console.log(configurationData)
    // console.log(inputs)
    // saveBugetPlan(configurationData, (callback) => {
    //   console.log(callback);
    // });
    var payload = {}
    if (editdata) {
      payload = {
        slabDetail: configurationData.slabDetail,
        payoutCycle: configurationData.payoutCycle,
        aggregatorId: configurationData.aggregatorId,
        costModelType: configurationData.costModelType,
        id: configurationData.id
      };
    } else {
      payload = {
        slabDetail: configurationData.slabDetail,
        payoutCycle: configurationData.payoutCycle,
        aggregatorId: configurationData.aggregatorId,
        costModelType: configurationData.costModelType,

      };
    }

    setloaderState(true)
    saveOrUpdateAggregatorPayouts(
      payload,
      (callback) => {
        if (callback === 'success') {
          setpopupState(true)
          setpopupType('success')
          setmsg('Successfully Saved !')
          removePopup()
          setloaderState(false)
          if (editdata) {
            getConfigData(editdata.partnerId)
          }
        } else {
          setpopupState(true)
          setpopupType('error')
          setmsg('Please try again !')
          removePopup()
          setloaderState(false)
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const getAgreegatorData = () => {
    getAgreegatorListApi((callback) => {
      setAgreegatorData(callback);
    });
  };
  useEffect(() => {
    getAgreegatorData();
    if (editdata) {
      getConfigData(editdata.partnerId)
    }
  }, []);
  const getConfigData = (id) => {
    getConfigDetailsOfAgreegator(id, callback => {
      setObjectData(callback)
    })
  }
  const editdataConfig = (data) => {
    console.log(data)
    let changeData = Object.assign({}, configurationData)
    changeData.cpa = data.cpa,
      changeData.cpd = data.cpd,
      changeData.cpl = data.cpl,
      changeData.cpr = data.cpr,
      changeData.payoutCycle = data.payoutCycle,
      changeData.aggregatorId = data.aggregatorId,
      changeData.paymentAmount = data.paymentAmount,
      changeData.id = data.id
    setconfigurationData(changeData)
    console.log(changeData)

  }
  const handleDeleteInput = (index) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
    let chn = Object.assign({}, configurationData)
    chn.slabDetail = newInputs
    setconfigurationData(chn)
  };

  return (
    <div className="marketing-css container-fluid full-height">
      {/* <Bootstrap />  */}
      {loaderState ?
        <Loader />
        : ""}

      <div className="row " style={{ marginTop: '20px', marginLeft: '20px' }}>
        <h4 className="mb-0" style={{display:'flex'}}><b onClick={(e) => pageViewHandler(e, 'landingPage')}>Add Configuration</b></h4>
        {/* <h4 className="mb-0" style={{display:'flex'}}><ArrowBackIosNewIcon onClick={(e) => pageViewHandler(e, 'landingPage')} style={{ cursor: 'pointer',fontSize:'19px' }} />&nbsp; <b onClick={(e) => pageViewHandler(e, 'landingPage')}>Add Configuration</b></h4> */}
      </div>
      <hr className="mb-5 mt-2" />
      {/* <hr className="mb-5 mt-2" /> */}
      <div className="collection-box " style={{ minHeight: "500px", marginTop: '20px' }}>
        <div className="row">
          <div className="col-lg-4 col-sm-6 col-xs-12" style={{ marginTop: "20px" }}>
            <label className="form-label">
              Select Aggregator &nbsp;<span style={{ color: "red" }}>*</span>
            </label>
            <select
              value={configurationData.aggregatorId}
              className="form-select"
              onChange={(e) => changeHandler(e, "aggregatorId")}
            >
              <option value="">Select Option</option>
              {agreegatorData &&
                agreegatorData.map((data, i) => {
                  <option value={''}>Select Agreegator</option>
                  if (data.aggregatorId) {
                    return (
                      <option value={data.aggregatorId}>
                        {data.companyName}
                      </option>
                    );
                  }
                })}
            </select>
            {errorMessage && errorMessage.field === "aggregatorId" ? (
              <span style={{ color: "red" }}>{errorMessage.msg}</span>
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-4 col-sm-6 col-xs-12" style={{ marginTop: "20px" }}>
            <label className="form-label">
              Select Payout Cycle &nbsp;<span style={{ color: "red" }}>*</span>
            </label>
            <select
              value={configurationData.payoutCycle}
              className="form-select"
              onChange={(e) => changeHandler(e, "payoutCycle")}
            >
              <option value="">Select Option</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
              <option value="daily">Daily</option>
            </select>
            {errorMessage && errorMessage.field === "payoutCycle" ? (
              <span style={{ color: "red" }}>{errorMessage.msg}</span>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-4 col-sm-6 col-xs-12" style={{ marginTop: "20px" }}>
            <label className="form-label">
              Select Model &nbsp;<span style={{ color: "red" }}>*</span>
            </label>
            <select
              value={configurationData.model}
              className="form-select"
              onChange={(e) => changeHandler(e, "costModelType")}
            >
              <option value="">Select Option</option>
              <option value="CPA">CPA</option>
              <option value="CPD">CPD</option>
              <option value="CPL">CPL</option>
              <option value="CPI">CPI</option>
              <option value="CPR">CPR</option>


            </select>
            {errorMessage && errorMessage.field === "model" ? (
              <span style={{ color: "red" }}>{errorMessage.msg}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">

        </div>
        <>
          {inputs.map((input, index) => (
            <div className="row" key={index} style={{marginTop:'40px'}}>
              <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                <label>Min Count</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Min Count"
                  value={input.minCountSlab}
                  onChange={(e) => handleInputChange(index, 'minCountSlab', e.target.value)}
                />
              </div>
              <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                <label>Max Count</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Max Count"
                  value={input.maxCountSlab}
                  onChange={(e) => handleInputChange(index, 'maxCountSlab', e.target.value)}
                />
              </div>
              <div className="col-sm-4 col-xs-12" style={{ marginTop: '20px' }}>
                <label>Cost/count</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Cost Count"
                  value={input.costModelValue}
                  onChange={(e) => handleInputChange(index, 'costModelValue', e.target.value)}
                />
              </div>
              <div className="text-right" style={{ marginRight: '20px', marginTop: '100px' }}>
                <button className="btn btn-danger" onClick={() => handleDeleteInput(index)}>Delete</button>
              </div>
            </div>
          ))}
          <div style={{ textAlign: 'right', marginTop: '10px' }}>
            <button className="btn btn-success" onClick={handleAddInput}>Add Input</button>
          </div>
        </>

        <div className="row text-center " style={{ marginTop: '40px', marginLeft: '10px' }}>
          <button type="submit" className="btn submit" onClick={() => saveData()} >Save</button>
        </div>
      </div>
      {objectData !== undefined && objectData !== '' ?
        <div className="">

          <div className='credit-table' style={{ overflow: 'auto' }}>
            <table>
              <thead style={{ background: '#b9d8f9', color: '#000' }}>
                <tr className="thead-class">
                  <th>Added On</th>
                  <th>Updated On</th>
                  <th>Payment Amount</th>
                  <th>CPA</th>
                  <th>CPD </th>
                  <th>CPL</th>
                  <th>CPR</th>
                  <th>Payout Cycle</th>
                  <th>Edit</th>

                </tr>
              </thead>
              <tbody>
                {objectData !== undefined && objectData !== null && objectData.length > 0 && objectData.map((data, i) => {
                  return (
                    <tr>
                      <td></td>
                      <td></td>
                      <td>{data.paymentAmount}</td>
                      <td>{data.cpa}</td>
                      <td>{data.cpd} </td>
                      <td>{data.cpl}</td>
                      <td>{data.cpr}</td>
                      <td>{data.payoutCycle}</td>
                      <td><i className="fa fa-edit" style={{ cursor: 'pointer' }} onClick={() => editdataConfig(data)}></i></td>
                    </tr>
                  )
                })}

              </tbody>
            </table>
          </div>
        </div>
        : ""}

      {
        popupState ?
          <SidePopup msg={msg} popupType={popupType} />
          : ""
      }
    </div >
  );
}

export default Configuration;
