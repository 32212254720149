import React, { useEffect, useState } from "react";
import { GetVendorMasterDetails ,deleteVendorMaster} from "../../targetactioncreator";
import BarChartComponent from "../BarChart";
import NodataImage from '../../../../../../images/nodata.png'
import Loader from '../../../../../presentationals/Loader/Loader.component';
import Popup from '../../../../../presentationals/Popup/Popup.component';
import moment from "moment";
const TargetList = ({ editHandler,applyFilter,fromDate,toDate,ActiveMonthFilter,applyFilterState }) => {

    const [loader, setLoader] = useState(false);
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [expenseData, setExepensedata] = useState('')
    const [viewType ,setViewType] =useState("listing")
    const [detailData ,setDetailData] =useState({})

    const targetData = () => {
        const formattedFromDate = moment(fromDate).format('DD-MM-YYYY');
        const formattedToDate = moment(toDate).format('DD-MM-YYYY');
        setLoader(true)
        GetVendorMasterDetails(formattedFromDate,formattedToDate,callback => {
            setLoader(false)
            if (callback && callback?.status == 200 && callback?.data?.length > 0) {
                setExepensedata(callback.data)
            } else {
                setExepensedata([])
            }
        })
        applyFilterState()

    }


    useEffect(()=>{
        if(applyFilter == true || ActiveMonthFilter !== ''){
        targetData()
        }
    },[applyFilter,ActiveMonthFilter])


    const handleDelete = (data) => {
        setLoader(true)
        deleteVendorMaster(data?.id, (callback) => {
            setLoader(false)
            if (callback?.status == 200) {
                targetData()
            } else {
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })
    };
    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    const closePopup = () => {
        setPopup(false);
    };


    const handleDetailView =(data,type)=>{
        setDetailData(data)
        setViewType(type)
    }

    return (
        <div className="container-fluid Executor">
            {loader ? <Loader /> : ''}
            {sidePopup ?
                <Popup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}

                {
                    viewType === "listing" ?
                    <>
                    {expenseData !== '' && expenseData?.length > 0 ?
                <div className="row" style={{marginTop:'30px'}}>
                    <div className="col-xs-12">
                        <div
                            style={{
                                background: '#f2f2f2',
                                padding: '10px',
                                marginTop: '50px',
                                borderRadius: '5px',
                                overflow: 'auto',
                                // maxWidth: '1000px'
                            }}
                        >
                            <div>
                                <table className='table-logs' style={{ marginBottom: '10px', width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr>
                                            <th className="text-center">View</th>
                                            <th className="text-center">Legal Entity Name</th>
                                            <th className="text-center">Vendor Name</th>
                                            <th className="text-center">Group Name</th>
                                            <th className="text-center">Main Balance Bill</th>
                                            <th className="text-center">Credit Days</th>
                                            <th className="text-center">Edit</th>
                                            <th className="text-center">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {expenseData !== '' && expenseData.length > 0 ? expenseData.map((data, i) => {
                                            return (
                                                <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                                    <td className="text-center"><h5 onClick={() => handleDetailView(data,"details")} style={{ cursor: 'pointer' }}><b>i</b></h5></td>
                                                   
                                                    <td className="text-center">{data.legalEntityName}</td>
                                                    <td className="text-center">{data.vendorName}</td>
                                                    <td className="text-center">{data.groupName}</td>
                                                    <td className="text-center">{data.mainBalanceBill}</td>
                                                    <td className="text-center">{data.creditDays}</td>
                                                    <td className="text-center">
                                                        <i
                                                            className="fa fa-pencil-square-o fa-lg"
                                                            aria-hidden="true"
                                                            onClick={() => editHandler(data)}
                                                            style={{ cursor: 'pointer' }}
                                                            aria-label="Edit Expense"
                                                            title="Edit Expense"
                                                        ></i>
                                                    </td>
                                                    <td className="text-center">
                                                        <i
                                                            className="fa fa-trash-o fa-lg"
                                                            aria-hidden="true"
                                                            onClick={() => handleDelete(data)}
                                                            style={{ cursor: 'pointer' }}
                                                            aria-label="Delete Expense"
                                                            title="Delete Expense"
                                                        ></i>
                                                    </td>
                                                </tr>
                                            )
                                        }) : ""}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                :
                <div className="text-center" style={{ marginTop: '50px' }}>
                    <img src={NodataImage} width={'40%'} />
                </div>
            }
                    </>
               :'' }

               {
                viewType === "details" ? 
                <>
                    <div style={{marginTop:'60px'}}>
                        <button style={{background:'#337ab7',color:'#fff',borderRadius:'4px',padding:'10px'}} onClick={()=>handleDetailView("","listing")}>Back to List</button>
                        <h2>Vendor Details</h2>
                        
                        <div className="shadow-card" style={{marginTop:'40px',padding:'20px',padding:'1px solid rgb(242, 242, 242)',borderRadius:'5px'}}>
                            <div className="row">
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Legal Entity Name</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.legalEntityName || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Vendor Name</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.vendorName || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Group Name</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.groupName || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Main Balance Bill</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.mainBalanceBill || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Credit Days</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.creditDays || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Entity Name</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.entityName || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Address</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.address || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>State</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.state || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Pincode</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.pincode || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Country</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.country || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Account Holder Name</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.accountHolderName || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>IFSC Code</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.ifscCode || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Bank Name</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.bankName || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Branch Name</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.branchName || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>UPI Id</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.upiId || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Contact Person Name</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.contactPersonName || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Mobile Number</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.mobileNumber || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Telephone Number</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.telephoneNumber || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Email Id</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.emailId || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Alternate Email Id</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.alternativeEmailId || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>PAN Card</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.panCard || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>Party Type</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.partyType || "-"}</h4>
                                </div>
                                <div className="col-sm-3" style={{marginTop:'20px'}}>
                                    <label style={{color:'grey'}}>GST Number</label>
                                    <h4 style={{margin:'5px 0px'}}>{detailData?.gstNumber || "-"}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
               :''}
            
        </div>
    );
};

export default TargetList;
