import React, { useState, useEffect } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Scatter,ComposedChart,Line, } from 'recharts';


const APIDatashow3month = {
    'TOTAL DEMAND DUE': [
        { date: 'June 2024', value: '36016297', value2: '35800000' },
        { date: 'July 2024', value: '37172217', value2: '37000000' },
        { date: 'August 2024', value: '412900634', value2: '410000000' }
    ],
    'ACTUAL COLLECTION': [
        { date: 'June 2024', value: '32432507', value2: '32500000' },
        { date: 'July 2024', value: '33278417', value2: '33300000' },
        { date: 'August 2024', value: '38085023', value2: '38000000' }
    ],
    '% EFFICIENCY': [
        { date: 'June 2024', value: '90.05', value2: '90.00' },
        { date: 'July 2024', value: '89.52', value2: '89.50' },
        { date: 'August 2024', value: '90.89', value2: '91.00' }
    ],
    'PREPAYMENTS': [
        { date: 'June 2024', value: '17141367', value2: '17000000' },
        { date: 'July 2024', value: '12388826', value2: '12400000' },
        { date: 'August 2024', value: '16321137', value2: '16000000' }
    ],
    'OVERDUE COLLECTION ': [
        { date: 'June 2024', value: '1241402', value2: '1200000' },
        { date: 'July 2024', value: '2027680', value2: '2000000' },
        { date: 'August 2024', value: '2283478', value2: '2300000' }
    ],
    'OVERDUE AMOUNT TOTAL AMOUNT MORE THAN 30DAYS': [
        { date: 'June 2024', value: '35082089', value2: '35500000' },
        { date: 'July 2024', value: '34254799', value2: '34000000' },
        { date: 'August 2024', value: '37469364', value2: '37000000' }
    ],
    'OVERDUE CASES TOTAL AMOUNT MORE THAN 30DAYS': [
        { date: 'June 2024', value: '3029', value2: '3050' },
        { date: 'July 2024', value: '3160', value2: '3200' },
        { date: 'August 2024', value: '3311', value2: '3300' }
    ],
    'AMOUNT OF OVERDUE TOTAL CASES': [
        { date: 'June 2024', value: '38879306', value2: '39000000' },
        { date: 'July 2024', value: '40720867', value2: '40500000' },
        { date: 'August 2024', value: '44461867', value2: '44500000' }
    ],
    'NUMBER OF OVERDUE TOTAL CASES': [
        { date: 'June 2024', value: '3392', value2: '3400' },
        { date: 'July 2024', value: '3538', value2: '3550' },
        { date: 'August 2024', value: '3840', value2: '3800' }
    ],    
    'Overdue % out of Total O/s Loan': [
        { date: 'June 2024', value: '31.44', value2: '30.00' },
        { date: 'July 2024', value: '28.40', value2: '27.50' },
        { date: 'August 2024', value: '33.20', value2: '32.00' }
    ],
    'Total Collection': [
        { date: 'June 2024', value: '51115276', value2: '51000000' },
        { date: 'July 2024', value: '47694923', value2: '47500000' },
        { date: 'August 2024', value: '56689638', value2: '56500000' }
    ]
    
};

const APIDatashow6month = {
    'TOTAL DEMAND DUE': [
        { date: 'March 2024', value: '34000000', value2: '33500000' },
        { date: 'April 2024', value: '35000000', value2: '34500000' },
        { date: 'May 2024', value: '36000000', value2: '35500000' },
        { date: 'June 2024', value: '36016297', value2: '35900000' },
        { date: 'July 2024', value: '37172217', value2: '37000000' },
        { date: 'August 2024', value: '412900634', value2: '410000000' }
    ],
    'ACTUAL COLLECTION': [
        { date: 'March 2024', value: '31000000', value2: '30800000' },
        { date: 'April 2024', value: '32000000', value2: '31800000' },
        { date: 'May 2024', value: '33000000', value2: '32800000' },
        { date: 'June 2024', value: '32432507', value2: '32300000' },
        { date: 'July 2024', value: '33278417', value2: '33100000' },
        { date: 'August 2024', value: '38085023', value2: '37800000' }
    ],
    '% EFFICIENCY': [
        { date: 'March 2024', value: '89.00', value2: '88.50' },
        { date: 'April 2024', value: '89.50', value2: '89.00' },
        { date: 'May 2024', value: '90.00', value2: '89.50' },
        { date: 'June 2024', value: '90.05', value2: '90.00' },
        { date: 'July 2024', value: '89.52', value2: '89.00' },
        { date: 'August 2024', value: '90.89', value2: '90.50' }
    ],
    'PREPAYMENTS': [
        { date: 'March 2024', value: '16000000', value2: '15800000' },
        { date: 'April 2024', value: '16500000', value2: '16300000' },
        { date: 'May 2024', value: '17000000', value2: '16800000' },
        { date: 'June 2024', value: '17141367', value2: '17000000' },
        { date: 'July 2024', value: '12388826', value2: '12200000' },
        { date: 'August 2024', value: '16321137', value2: '16100000' }
    ],
    'OVERDUE COLLECTION ': [
        { date: 'March 2024', value: '1200000', value2: '1180000' },
        { date: 'April 2024', value: '1220000', value2: '1200000' },
        { date: 'May 2024', value: '1250000', value2: '1230000' },
        { date: 'June 2024', value: '1241402', value2: '1230000' },
        { date: 'July 2024', value: '2027680', value2: '2000000' },
        { date: 'August 2024', value: '2283478', value2: '2250000' }
    ],
    'OVERDUE AMOUNT TOTAL AMOUNT MORE THAN 30DAYS': [
        { date: 'March 2024', value: '34000000', value2: '33500000' },
        { date: 'April 2024', value: '34500000', value2: '34000000' },
        { date: 'May 2024', value: '35000000', value2: '34500000' },
        { date: 'June 2024', value: '35082089', value2: '35000000' },
        { date: 'July 2024', value: '34254799', value2: '34000000' },
        { date: 'August 2024', value: '37469364', value2: '37000000' }
    ],
    'OVERDUE CASES TOTAL AMOUNT MORE THAN 30DAYS': [
        { date: 'March 2024', value: '2900', value2: '2950' },
        { date: 'April 2024', value: '2950', value2: '3000' },
        { date: 'May 2024', value: '3000', value2: '3050' },
        { date: 'June 2024', value: '3029', value2: '3100' },
        { date: 'July 2024', value: '3160', value2: '3200' },
        { date: 'August 2024', value: '3311', value2: '3400' }
    ],
    'AMOUNT OF OVERDUE TOTAL CASES': [
        { date: 'March 2024', value: '38000000', value2: '37500000' },
        { date: 'April 2024', value: '38500000', value2: '38000000' },
        { date: 'May 2024', value: '39000000', value2: '38500000' },
        { date: 'June 2024', value: '38879306', value2: '39000000' },
        { date: 'July 2024', value: '40720867', value2: '41000000' },
        { date: 'August 2024', value: '44461867', value2: '45000000' }
    ],
    'NUMBER OF OVERDUE TOTAL CASES': [
        { date: 'March 2024', value: '3300', value2: '3250' },
        { date: 'April 2024', value: '3350', value2: '3300' },
        { date: 'May 2024', value: '3400', value2: '3350' },
        { date: 'June 2024', value: '3392', value2: '3400' },
        { date: 'July 2024', value: '3538', value2: '3550' },
        { date: 'August 2024', value: '3840', value2: '3800' }
    ],
    'Overdue % out of Total O/s Loan': [
        { date: 'March 2024', value: '30.00', value2: '30.50' },
        { date: 'April 2024', value: '30.50', value2: '31.00' },
        { date: 'May 2024', value: '31.00', value2: '31.50' },
        { date: 'June 2024', value: '31.44', value2: '32.00' },
        { date: 'July 2024', value: '28.40', value2: '28.50' },
        { date: 'August 2024', value: '33.20', value2: '33.50' }
    ],
    'Total Collection': [
        { date: 'March 2024', value: '50000000', value2: '49500000' },
        { date: 'April 2024', value: '51000000', value2: '50500000' },
        { date: 'May 2024', value: '52000000', value2: '51500000' },
        { date: 'June 2024', value: '51115276', value2: '51000000' },
        { date: 'July 2024', value: '47694923', value2: '48000000' },
        { date: 'August 2024', value: '56689638', value2: '57000000' }
    ]    
};

const APIDatashow1year = {
    'TOTAL DEMAND DUE': [
        { date: 'September 2023', value: '35000000', value2: '35500000' },
        { date: 'October 2023', value: '35500000', value2: '36000000' },
        { date: 'November 2023', value: '36000000', value2: '36500000' },
        { date: 'December 2023', value: '36500000', value2: '37000000' },
        { date: 'January 2024', value: '37000000', value2: '37500000' },
        { date: 'February 2024', value: '37500000', value2: '38000000' },
        { date: 'March 2024', value: '34000000', value2: '34500000' },
        { date: 'April 2024', value: '35000000', value2: '35500000' },
        { date: 'May 2024', value: '36000000', value2: '36500000' },
        { date: 'June 2024', value: '36016297', value2: '36200000' },
        { date: 'July 2024', value: '37172217', value2: '37500000' },
        { date: 'August 2024', value: '412900634', value2: '420000000' }
    ],
    'ACTUAL COLLECTION': [
        { date: 'September 2023', value: '31500000', value2: '32000000' },
        { date: 'October 2023', value: '32000000', value2: '32500000' },
        { date: 'November 2023', value: '32500000', value2: '33000000' },
        { date: 'December 2023', value: '33000000', value2: '33500000' },
        { date: 'January 2024', value: '33500000', value2: '34000000' },
        { date: 'February 2024', value: '34000000', value2: '34500000' },
        { date: 'March 2024', value: '31000000', value2: '31500000' },
        { date: 'April 2024', value: '32000000', value2: '32500000' },
        { date: 'May 2024', value: '33000000', value2: '33500000' },
        { date: 'June 2024', value: '32432507', value2: '33000000' },
        { date: 'July 2024', value: '33278417', value2: '34000000' },
        { date: 'August 2024', value: '38085023', value2: '38500000' }
    ],
    '% EFFICIENCY': [
        { date: 'September 2023', value: '88.00', value2: '88.50' },
        { date: 'October 2023', value: '88.50', value2: '89.00' },
        { date: 'November 2023', value: '89.00', value2: '89.50' },
        { date: 'December 2023', value: '89.50', value2: '90.00' },
        { date: 'January 2024', value: '90.00', value2: '90.50' },
        { date: 'February 2024', value: '90.05', value2: '90.55' },
        { date: 'March 2024', value: '89.00', value2: '89.50' },
        { date: 'April 2024', value: '89.50', value2: '90.00' },
        { date: 'May 2024', value: '90.00', value2: '90.50' },
        { date: 'June 2024', value: '90.05', value2: '90.10' },
        { date: 'July 2024', value: '89.52', value2: '89.80' },
        { date: 'August 2024', value: '90.89', value2: '91.00' }
    ],
    'PREPAYMENTS': [
        { date: 'September 2023', value: '16500000', value2: '17000000' },
        { date: 'October 2023', value: '17000000', value2: '17500000' },
        { date: 'November 2023', value: '17500000', value2: '18000000' },
        { date: 'December 2023', value: '18000000', value2: '18500000' },
        { date: 'January 2024', value: '18500000', value2: '19000000' },
        { date: 'February 2024', value: '19000000', value2: '19500000' },
        { date: 'March 2024', value: '16000000', value2: '16500000' },
        { date: 'April 2024', value: '16500000', value2: '17000000' },
        { date: 'May 2024', value: '17000000', value2: '17500000' },
        { date: 'June 2024', value: '17141367', value2: '17500000' },
        { date: 'July 2024', value: '12388826', value2: '13000000' },
        { date: 'August 2024', value: '16321137', value2: '17000000' }
    ],
    'OVERDUE COLLECTION ': [
        { date: 'September 2023', value: '1150000', value2: '1200000' },
        { date: 'October 2023', value: '1200000', value2: '1250000' },
        { date: 'November 2023', value: '1250000', value2: '1300000' },
        { date: 'December 2023', value: '1300000', value2: '1350000' },
        { date: 'January 2024', value: '1350000', value2: '1400000' },
        { date: 'February 2024', value: '1400000', value2: '1450000' },
        { date: 'March 2024', value: '1200000', value2: '1250000' },
        { date: 'April 2024', value: '1220000', value2: '1270000' },
        { date: 'May 2024', value: '1250000', value2: '1300000' },
        { date: 'June 2024', value: '1241402', value2: '1300000' },
        { date: 'July 2024', value: '2027680', value2: '2100000' },
        { date: 'August 2024', value: '2283478', value2: '2350000' }
    ],
    'OVERDUE AMOUNT TOTAL AMOUNT MORE THAN 30DAYS': [
        { date: 'September 2023', value: '34000000', value2: '34500000' },
        { date: 'October 2023', value: '34500000', value2: '35000000' },
        { date: 'November 2023', value: '35000000', value2: '35500000' },
        { date: 'December 2023', value: '35500000', value2: '36000000' },
        { date: 'January 2024', value: '36000000', value2: '36500000' },
        { date: 'February 2024', value: '36500000', value2: '37000000' },
        { date: 'March 2024', value: '34000000', value2: '34500000' },
        { date: 'April 2024', value: '34500000', value2: '35000000' },
        { date: 'May 2024', value: '35000000', value2: '35500000' },
        { date: 'June 2024', value: '35082089', value2: '35500000' },
        { date: 'July 2024', value: '34254799', value2: '35000000' },
        { date: 'August 2024', value: '37469364', value2: '38000000' }
    ],
        "OVERDUE CASES TOTAL AMOUNT MORE THAN 30DAYS": [
            {
                "date": "September 2023",
                "value": "2900",
                "value2": "2800" // Slightly less than value
            },
            {
                "date": "October 2023",
                "value": "2950",
                "value2": "2850" // Slightly less than value
            },
            {
                "date": "November 2023",
                "value": "3000",
                "value2": "2900" // Slightly less than value
            },
            {
                "date": "December 2023",
                "value": "3050",
                "value2": "2950" // Slightly less than value
            },
            {
                "date": "January 2024",
                "value": "3100",
                "value2": "3000" // Slightly less than value
            },
            {
                "date": "February 2024",
                "value": "3150",
                "value2": "3050" // Slightly less than value
            },
            {
                "date": "March 2024",
                "value": "2900",
                "value2": "2800" // Slightly less than value
            },
            {
                "date": "April 2024",
                "value": "2950",
                "value2": "2850" // Slightly less than value
            },
            {
                "date": "May 2024",
                "value": "3000",
                "value2": "2900" // Slightly less than value
            },
            {
                "date": "June 2024",
                "value": "3029",
                "value2": "2929" // Slightly less than value
            },
            {
                "date": "July 2024",
                "value": "3160",
                "value2": "3060" // Slightly less than value
            },
            {
                "date": "August 2024",
                "value": "3311",
                "value2": "3211" // Slightly less than value
            }
        ],
        "AMOUNT OF OVERDUE TOTAL CASES": [
            {
                "date": "September 2023",
                "value": "38000000",
                "value2": "37000000" // Slightly less than value
            },
            {
                "date": "October 2023",
                "value": "38500000",
                "value2": "37500000" // Slightly less than value
            },
            {
                "date": "November 2023",
                "value": "39000000",
                "value2": "38000000" // Slightly less than value
            },
            {
                "date": "December 2023",
                "value": "39500000",
                "value2": "38500000" // Slightly less than value
            },
            {
                "date": "January 2024",
                "value": "40000000",
                "value2": "39000000" // Slightly less than value
            },
            {
                "date": "February 2024",
                "value": "40500000",
                "value2": "39500000" // Slightly less than value
            },
            {
                "date": "March 2024",
                "value": "38000000",
                "value2": "37000000" // Slightly less than value
            },
            {
                "date": "April 2024",
                "value": "38500000",
                "value2": "37500000" // Slightly less than value
            },
            {
                "date": "May 2024",
                "value": "39000000",
                "value2": "38000000" // Slightly less than value
            },
            {
                "date": "June 2024",
                "value": "38879306",
                "value2": "37879306" // Slightly less than value
            },
            {
                "date": "July 2024",
                "value": "40720867",
                "value2": "39720867" // Slightly less than value
            },
            {
                "date": "August 2024",
                "value": "44461867",
                "value2": "43461867" // Slightly less than value
            }
        ],
        "NUMBER OF OVERDUE TOTAL CASES": [
            {
                "date": "September 2023",
                "value": "3300",
                "value2": "3200" // Slightly less than value
            },
            {
                "date": "October 2023",
                "value": "3350",
                "value2": "3250" // Slightly less than value
            },
            {
                "date": "November 2023",
                "value": "3400",
                "value2": "3300" // Slightly less than value
            },
            {
                "date": "December 2023",
                "value": "3450",
                "value2": "3350" // Slightly less than value
            },
            {
                "date": "January 2024",
                "value": "3500",
                "value2": "3400" // Slightly less than value
            },
            {
                "date": "February 2024",
                "value": "3550",
                "value2": "3450" // Slightly less than value
            },
            {
                "date": "March 2024",
                "value": "3300",
                "value2": "3200" // Slightly less than value
            },
            {
                "date": "April 2024",
                "value": "3350",
                "value2": "3250" // Slightly less than value
            },
            {
                "date": "May 2024",
                "value": "3400",
                "value2": "3300" // Slightly less than value
            },
            {
                "date": "June 2024",
                "value": "3392",
                "value2": "3292" // Slightly less than value
            },
            {
                "date": "July 2024",
                "value": "3538",
                "value2": "3438" // Slightly less than value
            },
            {
                "date": "August 2024",
                "value": "3840",
                "value2": "3740" // Slightly less than value
            }
        ],
        "Overdue % out of Total O/s Loan": [
            {
                "date": "September 2023",
                "value": "30.50",
                "value2": "29.50" // Slightly less than value
            },
            {
                "date": "October 2023",
                "value": "31.00",
                "value2": "30.00" // Slightly less than value
            },
            {
                "date": "November 2023",
                "value": "31.50",
                "value2": "30.50" // Slightly less than value
            },
            {
                "date": "December 2023",
                "value": "32.00",
                "value2": "31.00" // Slightly less than value
            },
            {
                "date": "January 2024",
                "value": "32.50",
                "value2": "31.50" // Slightly less than value
            },
            {
                "date": "February 2024",
                "value": "33.00",
                "value2": "32.00" // Slightly less than value
            },
            {
                "date": "March 2024",
                "value": "30.00",
                "value2": "29.00" // Slightly less than value
            },
            {
                "date": "April 2024",
                "value": "30.50",
                "value2": "29.50" // Slightly less than value
            },
            {
                "date": "May 2024",
                "value": "31.00",
                "value2": "30.00" // Slightly less than value
            },
            {
                "date": "June 2024",
                "value": "31.44",
                "value2": "30.44" // Slightly less than value
            },
            {
                "date": "July 2024",
                "value": "28.40",
                "value2": "27.40" // Slightly less than value
            },
            {
                "date": "August 2024",
                "value": "33.20",
                "value2": "32.20" // Slightly less than value
            }
        ],
        "Total Collection": [
            {
                "date": "September 2023",
                "value": "50500000",
                "value2": "49500000" // Slightly less than value
            },
            {
                "date": "October 2023",
                "value": "51000000",
                "value2": "50000000" // Slightly less than value
            },
            {
                "date": "November 2023",
                "value": "51500000",
                "value2": "50500000" // Slightly less than value
            },
            {
                "date": "December 2023",
                "value": "52000000",
                "value2": "51000000" // Slightly less than value
            },
            {
                "date": "January 2024",
                "value": "52500000",
                "value2": "51500000" // Slightly less than value
            },
            {
                "date": "February 2024",
                "value": "53000000",
                "value2": "52000000" // Slightly less than value
            },
            {
                "date": "March 2024",
                "value": "50000000",
                "value2": "49000000" // Slightly less than value
            },
            {
                "date": "April 2024",
                "value": "51000000",
                "value2": "50000000" // Slightly less than value
            },
            {
                "date": "May 2024",
                "value": "52000000",
                "value2": "51000000" // Slightly less than value
            },
            {
                "date": "June 2024",
                "value": "51115276",
                "value2": "50115276" // Slightly less than value
            },
            {
                "date": "July 2024",
                "value": "47694923",
                "value2": "46694923" // Slightly less than value
            },
            {
                "date": "August 2024",
                "value": "56689638",
                "value2": "55689638" // Slightly less than value
            }
        ]
        
};






export default function CollectionEfficiency({ fromDate, toDate, applyFilter, handleApplyFilter, clearFilter, handleclearFilter, edit, handleEdit, ActiveMonthFilter }) {

    const [selectedData, setSelectedData] = useState([]);
    const [selectedDataIndex, setSelectedDataIndex] = useState(-1);
    const [selectedDataHeading, setSelectedDataHeading] = useState('')
    const [APIData,setAPIData]=useState(APIDatashow3month)
    useEffect(() => {
        if (applyFilter == true) {
            console.log("apply", fromDate, toDate)
            // api call for applying filter here 
            setTimeout(() => {
                handleApplyFilter()
            }, 500)
        }
        if (clearFilter == true) {
            setAPIData(APIDatashow3month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')

            console.log("clear", fromDate, toDate)
            // api call for clear filter here 
            setTimeout(() => {
                handleclearFilter()
            }, 500)
        }
        if(edit == true){
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
            setSelectedData([])
        }
        if(ActiveMonthFilter === '3month'){
            setAPIData(APIDatashow3month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        }else if(ActiveMonthFilter === '6month'){
            setAPIData(APIDatashow6month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        }else if(ActiveMonthFilter === '1year'){
            setAPIData(APIDatashow1year)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        }
    }, [applyFilter, clearFilter,edit,ActiveMonthFilter])


  

    const handleSelectedData = (index, datakey, data) => {
        setSelectedData([])
        if (index === selectedDataIndex) {
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')

        } else {
            setSelectedDataHeading(datakey)
            setSelectedDataIndex(index)
            setTimeout(() => {
                setSelectedData(data)
            }, 300)

        }

    }

    const handleEditValue = (e) => {
        const { name, value } = e.target;
    }

    const handleSavebtn = () => {
        handleEdit();
    }


    return (
        <div className="Dashboardsmis">
            {
                !edit ?
                    <div className="row" style={{position:'relative'}}>
                        <div className={`${selectedDataIndex == '-1' ? 'col-xs-12' : "col-md-6 col-xs-12"} transition-col`}>
                            <div className="table" style={{ marginTop: '20px' }}>
                                <div className="phocket-table-new newTable" style={{ overflowX: 'auto', marginTop: '0px' }}>
                                    <table>
                                        <thead style={{ position: 'sticky', top: 0 }}>
                                            <tr>
                                                <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Particulars</th>
                                                {
                                                    APIData['TOTAL DEMAND DUE'].map((item, index) => (
                                                        <th key={index}>{item.date}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(APIData).map(([datakey, datavalue], index) => (
                                                <tr className={selectedDataIndex === index ? 'activeTr' : ''} key={index} style={{ cursor: 'pointer' }} onClick={() => handleSelectedData(index, datakey, datavalue)}>
                                                    <td className='leftsticky'><b>{datakey}</b></td>
                                                    {
                                                        datavalue?.map((value, index) => {
                                                            return (
                                                                <td key={index}  style={{width: ActiveMonthFilter === '3month' ? '100px':'auto'}}>{value?.value}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {
                            selectedData?.length > 0 ?
                                <div className="col-md-6 col-xs-12">
                                    <div className="charts" style={{ marginTop: '20px' }}>
                                        <h3>{selectedDataHeading}</h3>
                                        <div className="chartbar" style={{ height: window?.innerWidth > 1800 ? '730px' : '460px' }}>
                                        <ResponsiveContainer width="100%" height="100%">
                                                <ComposedChart
                                                    width={500}
                                                    height={300}
                                                    data={selectedData}
                                                    barSize={ActiveMonthFilter === '3month' ? 40: ActiveMonthFilter === '6month' ? 30 : 20}
                                                    margin={{
                                                        top: 20,
                                                        right: 0,
                                                        left: 30,
                                                        bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid stroke='none' />
                                                    <XAxis dataKey="date" />
                                                    <YAxis padding={{ top: 20 }}/>
                                                    <Tooltip />
                                                    <Bar dataKey="value" fill="#8884d8" />
                                                    <Line type="monotone" dataKey="value" stroke="#223D64" strokeWidth={2} dot={ null } />
                                                </ComposedChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                    </div>
                    :
                    <div className="row">
                        <div className='col-xs-12'>
                            <div className="table" style={{ marginTop: '20px' }}>
                                <div className="phocket-table-new newTable" style={{ overflowX: 'auto', marginTop: '0px' }}>
                                    <table>
                                        <thead style={{ position: 'sticky', top: 0 }}>
                                            <tr>
                                                <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Particulars</th>
                                                {
                                                    APIData['TOTAL DEMAND DUE'].map((item, index) => (
                                                        <th key={index}>{item.date}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(APIData).map(([datakey, datavalue], index) => (
                                                <tr key={index}>
                                                    <td className='leftsticky'><b>{datakey}</b></td>
                                                    {
                                                        datavalue?.map((value, index) => {
                                                            return (
                                                                <td key={index}><input type="number" name={datakey + '-' + value?.date} value={value.value} className='Editinputtag'  onChange={(e) => handleEditValue(e)} /></td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="Filterations" style={{marginTop:'40px',textAlign:'center'}}>
                                <button className='filterbtn' style={{width:'8%'}} onClick={()=>handleSavebtn()}>Save</button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}
