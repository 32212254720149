import React, { useState, useEffect } from 'react'
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Scatter, ComposedChart, Line,
} from 'recharts';


const APIDatashow3month = {
    'Projected Loan nos Disbursed': [
        {
            date: 'June 2024',
            value: '2800', value2: '2500'
        },
        {
            date: 'July 2024',
            value: '3000', value2: '2000'
        },
        {
            date: 'August 2024',
            value: '3500', value2: '3452'
        },
    ],
    'Actual Loan nos Disbursed': [
        {
            date: 'June 2024',
            value: '2664', value2: '1362'
        },
        {
            date: 'July 2024',
            value: '2565', value2: '937'
        },
        {
            date: 'August 2024',
            value: '1907', value2: '2345'
        },
    ],
    'Growth of Loan Application nos in %': [
        {
            date: 'June 2024',
            value: '26.08', value2: '56.3'
        },
        {
            date: 'July 2024',
            value: '3.72', value2: '5.93'
        },
        {
            date: 'August 2024',
            value: '89.07', value2: '78.2'
        },
    ],
    'Actual Loan nos disbursed(NC)': [
        {
            date: 'June 2024',
            value: '1083', value2: '937'
        },
        {
            date: 'July 2024',
            value: '870', value2: '900'
        },
        {
            date: 'August 2024',
            value: '450', value2: '350'
        },
    ],
    'Actual Loan nos disbursed(EC)': [
        {
            date: 'June 2024',
            value: '1581', value2: '1562'
        },
        {
            date: 'July 2024',
            value: '1695', value2: '1453'
        },
        {
            date: 'August 2024',
            value: '1457', value2: '1347'
        },
    ],
    'Actual Loan nos disbursed(EC) %': [
        {
            date: 'June 2024',
            value: '59.35', value2: '60.73'
        },
        {
            date: 'July 2024',
            value: '66.08', value2: '56.03'
        },
        {
            date: 'August 2024',
            value: '76.40', value2: '70.03'
        },
    ],
    'Target Achieved % of nos of Loan': [
        {
            date: 'June 2024',
            value: '95', value2: '80'
        },
        {
            date: 'July 2024',
            value: '86', value2: '78'
        },
        {
            date: 'August 2024',
            value: '54', value2: '67'
        },
    ],
    'Total nos of CRIF Hit': [
        {
            date: 'June 2024',
            value: '10000', value2: '5272'
        },
        {
            date: 'July 2024',
            value: '12500', value2: '6892'
        },
        {
            date: 'August 2024',
            value: '13277', value2: '10003'
        },
    ],
    'CAC in Rs.': [
        {
            date: 'June 2024',
            value: '806', value2: '739'
        },
        {
            date: 'July 2024',
            value: '688', value2: '783'
        },
        {
            date: 'August 2024',
            value: '1661', value2: '1452'
        },
    ],
    'CAC with new + old in Rs.': [
        {
            date: 'June 2024',
            value: '328', value2: '283'
        },
        {
            date: 'July 2024',
            value: '233', value2: '154'
        },
        {
            date: 'August 2024',
            value: '87', value2: '90'
        },
    ],

}
const APIDatashow6month = {
    'Projected Loan nos Disbursed': [
        {
            date: 'March 2024',
            value: '2100', value2: '1502'
        },
        {
            date: 'April 2024',
            value: '2300', value2: '1294'
        },
        {
            date: 'May 2024',
            value: '2350', value2: '2579'
        },
        {
            date: 'June 2024',
            value: '2800', value2: '2603'
        },
        {
            date: 'July 2024',
            value: '3000', value2: '2700'
        },
        {
            date: 'August 2024',
            value: '3500', value2: '3028'
        },
    ],
    'Actual Loan nos Disbursed': [
        {
            date: 'March 2024',
            value: '2010', value2: '1672'
        },
        {
            date: 'April 2024',
            value: '2205', value2: '1893'
        },
        {
            date: 'May 2024',
            value: '2450', value2: '2073'
        },
        {
            date: 'June 2024',
            value: '2664', value2: '2683'
        },
        {
            date: 'July 2024',
            value: '2565', value2: '2573'
        },
        {
            date: 'August 2024',
            value: '1907', value2: '1572'
        },
    ],
    'Growth of Loan Application nos in %': [
        {
            date: 'March 2024',
            value: '21.0', value2: '34.93'
        },
        {
            date: 'April 2024',
            value: '15.0', value2: '18.09'
        },
        {
            date: 'May 2024',
            value: '24.0', value2: '30.56'
        },
        {
            date: 'June 2024',
            value: '26.08', value2: '57.03'
        },
        {
            date: 'July 2024',
            value: '3.72', value2: '2.04'
        },
        {
            date: 'August 2024',
            value: '89.07', value2: '68.03'
        },
    ],
    'Actual Loan nos disbursed(NC)': [
        {
            date: 'March 2024',
            value: '113', value2: '102'
        },
        {
            date: 'April 2024',
            value: '525', value2: '408'
        },
        {
            date: 'May 2024',
            value: '707', value2: '739'
        },
        {
            date: 'June 2024',
            value: '1083', value2: '893'
        },
        {
            date: 'July 2024',
            value: '870', value2: '900'
        },
        {
            date: 'August 2024',
            value: '450', value2: '256'
        },
    ],
    'Actual Loan nos disbursed(EC)': [
        {
            date: 'March 2024',
            value: '1381', value2: '1683'
        },
        {
            date: 'April 2024',
            value: '1437', value2: '1573'
        },
        {
            date: 'May 2024',
            value: '1296', value2: '1294'
        },
        {
            date: 'June 2024',
            value: '1581', value2: '1368'
        },
        {
            date: 'July 2024',
            value: '1695', value2: '1263'
        },
        {
            date: 'August 2024',
            value: '1457', value2: '1638'
        },
    ],
    'Actual Loan nos disbursed(EC) %': [
        {
            date: 'March 2024',
            value: '72.46', value2: '80.03'
        },
        {
            date: 'April 2024',
            value: '67.02', value2: '56.02'
        },
        {
            date: 'May 2024',
            value: '61.03', value2: '56.03'
        },
        {
            date: 'June 2024',
            value: '59.35', value2: '57.02'
        },
        {
            date: 'July 2024',
            value: '66.08', value2: '70.93'
        },
        {
            date: 'August 2024',
            value: '76.40', value2: '56.03'
        },
    ],
    'Target Achieved % of nos of Loan': [
        {
            date: 'March 2024',
            value: '87', value2: '78'
        },
        {
            date: 'April 2024',
            value: '91', value2: '78'
        },
        {
            date: 'May 2024',
            value: '85', value2: '93'
        },
        {
            date: 'June 2024',
            value: '95', value2: '93'
        },
        {
            date: 'July 2024',
            value: '86', value2: '84'
        },
        {
            date: 'August 2024',
            value: '54', value2: '78'
        },
    ],
    'Total nos of CRIF Hit': [
        {
            date: 'March 2024',
            value: '10090', value2: '13082'
        },
        {
            date: 'April 2024',
            value: '13000', value2: '12023'
        },
        {
            date: 'May 2024',
            value: '11030', value2: '12843'
        },
        {
            date: 'June 2024',
            value: '10000', value2: '10833'
        },
        {
            date: 'July 2024',
            value: '12500', value2: '8037'
        },
        {
            date: 'August 2024',
            value: '13277', value2: '5083'
        },
    ],
    'CAC in Rs.': [
        {
            date: 'March 2024',
            value: '810', value2: '759'
        },
        {
            date: 'April 2024',
            value: '1000', value2: '800'
        },
        {
            date: 'May 2024',
            value: '790', value2: '730'
        },
        {
            date: 'June 2024',
            value: '806', value2: '573'
        },
        {
            date: 'July 2024',
            value: '688', value2: '245'
        },
        {
            date: 'August 2024',
            value: '1661', value2: '973'
        },
    ],
    'CAC with new + old in Rs.': [
        {
            date: 'March 2024',
            value: '233', value2: '156'
        },
        {
            date: 'April 2024',
            value: '510', value2: '520'
        },
        {
            date: 'May 2024',
            value: '433', value2: '433'
        },
        {
            date: 'June 2024',
            value: '328', value2: '193'
        },
        {
            date: 'July 2024',
            value: '233', value2: '132'
        },
        {
            date: 'August 2024',
            value: '87', value2: '90'
        },
    ],

}
const APIDatashow1year = {
    'Projected Loan nos Disbursed': [
        {
            date: 'September 2023',
            value: '2347', value2: '1456'
        },
        {
            date: 'October 2023',
            value: '1988', value2: '1452'
        },
        {
            date: 'November 2023',
            value: '2009', value2: '1637'
        },
        {
            date: 'December 2023',
            value: '2110', value2: '1267'
        },
        {
            date: 'January 2024',
            value: '2200', value2: '2100'
        },
        {
            date: 'February 2024',
            value: '2000', value2: '1452'
        },
        {
            date: 'March 2024',
            value: '2100', value2: '368'
        },
        {
            date: 'April 2024',
            value: '2300', value2: '267'
        },
        {
            date: 'May 2024',
            value: '2350', value2: '2672'
        },
        {
            date: 'June 2024',
            value: '2800', value2: '1682'
        },
        {
            date: 'July 2024',
            value: '3000', value2: '2000'
        },
        {
            date: 'August 2024',
            value: '3500', value2: '1427'
        },
    ],
    'Actual Loan nos Disbursed': [
        {
            date: 'September 2023',
            value: '2147', value2: '1462'
        },
        {
            date: 'October 2023',
            value: '1650', value2: '1572'
        },
        {
            date: 'November 2023',
            value: '1765', value2: '1452'
        },
        {
            date: 'December 2023',
            value: '1920', value2: '1893'
        },
        {
            date: 'January 2024',
            value: '2150', value2: '1657'
        },
        {
            date: 'February 2024',
            value: '1900', value2: '1672'
        },
        {
            date: 'March 2024',
            value: '2010', value2: '1672'
        },
        {
            date: 'April 2024',
            value: '2205', value2: '1672'
        },
        {
            date: 'May 2024',
            value: '2450', value2: '2718'
        },
        {
            date: 'June 2024',
            value: '2664', value2: '1673'
        },
        {
            date: 'July 2024',
            value: '2565', value2: '2561'
        },
        {
            date: 'August 2024',
            value: '1907', value2: '1452'
        },
    ],
    'Growth of Loan Application nos in %': [
        {
            date: 'September 2023',
            value: '28.50', value2: '26.92'
        },
        {
            date: 'October 2023',
            value: '30.05', value2: '26.53'
        },
        {
            date: 'November 2023',
            value: '18.11', value2: '15.82'
        },
        {
            date: 'December 2023',
            value: '52.11', value2: '29.93'
        },
        {
            date: 'January 2024',
            value: '12.49', value2: '63.28'
        },
        {
            date: 'February 2024',
            value: '21.0', value2: '20.92'
        },
        {
            date: 'March 2024',
            value: '21.0', value2: '27.92'
        },
        {
            date: 'April 2024',
            value: '15.0', value2: '13.03'
        },
        {
            date: 'May 2024',
            value: '24.0', value2: '26.93'
        },
        {
            date: 'June 2024',
            value: '26.08', value2: '16.93'
        },
        {
            date: 'July 2024',
            value: '3.72', value2: '8.93'
        },
        {
            date: 'August 2024',
            value: '89.07', value2: '67.93'
        },
    ],
    'Actual Loan nos disbursed(NC)': [
        {
            date: 'September 2023',
            value: '134', value2: '129'
        },
        {
            date: 'October 2023',
            value: '151', value2: '173'
        },
        {
            date: 'November 2023',
            value: '113', value2: '153'
        },
        {
            date: 'December 2023',
            value: '97', value2: '97'
        },
        {
            date: 'January 2024',
            value: '215', value2: '216'
        },
        {
            date: 'February 2024',
            value: '128', value2: '142'
        },
        {
            date: 'March 2024',
            value: '113', value2: '98'
        },
        {
            date: 'April 2024',
            value: '525', value2: '267'
        },
        {
            date: 'May 2024',
            value: '707', value2: '567'
        },
        {
            date: 'June 2024',
            value: '1083', value2: '707'
        },
        {
            date: 'July 2024',
            value: '870', value2: '673'
        },
        {
            date: 'August 2024',
            value: '450', value2: '203'
        },
    ],
    'Actual Loan nos disbursed(EC)': [
        {
            date: 'September 2023',
            value: '1140', value2: '1282'
        },
        {
            date: 'October 2023',
            value: '1343', value2: '1192'
        },
        {
            date: 'November 2023',
            value: '1381', value2: '1083'
        },
        {
            date: 'December 2023',
            value: '1576', value2: '783'
        },
        {
            date: 'January 2024',
            value: '1150', value2: '379'
        },
        {
            date: 'February 2024',
            value: '1280', value2: '756'
        },
        {
            date: 'March 2024',
            value: '1381', value2: '573'
        },
        {
            date: 'April 2024',
            value: '1437', value2: '1252'
        },
        {
            date: 'May 2024',
            value: '1296', value2: '1357'
        },
        {
            date: 'June 2024',
            value: '1581', value2: '673'
        },
        {
            date: 'July 2024',
            value: '1695', value2: '900'
        },
        {
            date: 'August 2024',
            value: '1457', value2: '168'
        },
    ],
    'Actual Loan nos disbursed(EC) %': [
        {
            date: 'September 2023',
            value: '72.50', value2: '67.03'
        },
        {
            date: 'October 2023',
            value: '83.56', value2: '67.39'
        },
        {
            date: 'November 2023',
            value: '93.48', value2: '90.93'
        },
        {
            date: 'December 2023',
            value: '95.98', value2: '87.93'
        },
        {
            date: 'January 2024',
            value: '72.36', value2: '89.03'
        },
        {
            date: 'February 2024',
            value: '64.02', value2: '67.03'
        },
        {
            date: 'March 2024',
            value: '72.46', value2: '70.03'
        },
        {
            date: 'April 2024',
            value: '67.02', value2: '63.03'
        },
        {
            date: 'May 2024',
            value: '61.03', value2: '50.03'
        },
        {
            date: 'June 2024',
            value: '59.35', value2: '69.03'
        },
        {
            date: 'July 2024',
            value: '66.08', value2: '50.03'
        },
        {
            date: 'August 2024',
            value: '76.40', value2: '89.03'
        },
    ],
    'Target Achieved % of nos of Loan': [
        {
            date: 'September 2023',
            value: '68.50', value2: '78.49'
        },
        {
            date: 'October 2023',
            value: '78.56', value2: '67.49'
        },
        {
            date: 'November 2023',
            value: '90.48', value2: '67.94'
        },
        {
            date: 'December 2023',
            value: '95.98', value2: '78.94'
        },
        {
            date: 'January 2024',
            value: '79.36', value2: '67.93'
        },
        {
            date: 'February 2024',
            value: '89.02', value2: '98.03'
        },
        {
            date: 'March 2024',
            value: '87', value2: '78'
        },
        {
            date: 'April 2024',
            value: '91', value2: '89'
        },
        {
            date: 'May 2024',
            value: '85', value2: '67'
        },
        {
            date: 'June 2024',
            value: '95', value2: '90'
        },
        {
            date: 'July 2024',
            value: '86', value2: '78.39'
        },
        {
            date: 'August 2024',
            value: '54', value2: '37.93'
        },
    ],
    'Total nos of CRIF Hit': [
        {
            date: 'September 2023',
            value: '9020', value2: '6738'
        },
        {
            date: 'October 2023',
            value: '10001', value2: '8039'
        },
        {
            date: 'November 2023',
            value: '14090', value2: '13829'
        },
        {
            date: 'December 2023',
            value: '10300', value2: '12768'
        },
        {
            date: 'January 2024',
            value: '12090', value2: '10000'
        },
        {
            date: 'February 2024',
            value: '10340', value2: '8392'
        },
        {
            date: 'March 2024',
            value: '10090', value2: '8922'
        },
        {
            date: 'April 2024',
            value: '13000', value2: '2782'
        },
        {
            date: 'May 2024',
            value: '11030', value2: '7628'
        },
        {
            date: 'June 2024',
            value: '10000', value2: '5000'
        },
        {
            date: 'July 2024',
            value: '12500', value2: '6000'
        },
        {
            date: 'August 2024',
            value: '13277', value2: '7368'
        },
    ],
    'CAC in Rs.': [
        {
            date: 'September 2023',
            value: '367', value2: '738'
        },
        {
            date: 'October 2023',
            value: '472', value2: '356'
        },
        {
            date: 'November 2023',
            value: '282', value2: '268'
        },
        {
            date: 'December 2023',
            value: '689', value2: '456'
        },
        {
            date: 'January 2024',
            value: '790', value2: '267'
        },
        {
            date: 'February 2024',
            value: '634', value2: '567'
        },
        {
            date: 'March 2024',
            value: '810', value2: '782'
        },
        {
            date: 'April 2024',
            value: '1000', value2: '800'
        },
        {
            date: 'May 2024',
            value: '790', value2: '937'
        },
        {
            date: 'June 2024',
            value: '806', value2: '368'
        },
        {
            date: 'July 2024',
            value: '688', value2: '567'
        },
        {
            date: 'August 2024',
            value: '1661', value2: '598'
        },
    ],
    'CAC with new + old in Rs.': [
        {
            date: 'September 2023',
            value: '634', value2: '673'
        },
        {
            date: 'October 2023',
            value: '540', value2: '356'
        },
        {
            date: 'November 2023',
            value: '450', value2: '246'
        },
        {
            date: 'December 2023',
            value: '400', value2: '346'
        },
        {
            date: 'January 2024',
            value: '490', value2: '467'
        },
        {
            date: 'February 2024',
            value: '515', value2: '356'
        },
        {
            date: 'March 2024',
            value: '233', value2: '467'
        },
        {
            date: 'April 2024',
            value: '510', value2: '456'
        },
        {
            date: 'May 2024',
            value: '433', value2: '567'
        },
        {
            date: 'June 2024',
            value: '328', value2: '456'
        },
        {
            date: 'July 2024',
            value: '233', value2: '578'
        },
        {
            date: 'August 2024',
            value: '87', value2: '76'
        },
    ],

}

export default function CACDashboard({ fromDate, toDate, applyFilter, handleApplyFilter, clearFilter, handleclearFilter, edit, handleEdit, ActiveMonthFilter }) {

    const [selectedData, setSelectedData] = useState([]);
    const [selectedDataIndex, setSelectedDataIndex] = useState(-1);
    const [selectedDataHeading, setSelectedDataHeading] = useState('')
    const [editedData, setEditedData] = useState({});
    const [APIData, setAPIData] = useState(APIDatashow3month)
    useEffect(() => {
        if (applyFilter == true) {
            console.log("apply", fromDate, toDate)
            // api call for applying filter here 
            setTimeout(() => {
                handleApplyFilter()
            }, 500)
        }
        if (clearFilter == true) {
            setAPIData(APIDatashow3month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')

            console.log("clear", fromDate, toDate)
            // api call for clear filter here 
            setTimeout(() => {
                handleclearFilter()
            }, 500)
        }
        if (edit == true) {
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
            setSelectedData([])
        }
        if (ActiveMonthFilter === '3month') {
            setAPIData(APIDatashow3month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        } else if (ActiveMonthFilter === '6month') {
            setAPIData(APIDatashow6month)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        } else if (ActiveMonthFilter === '1year') {
            setAPIData(APIDatashow1year)
            setSelectedData([])
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')
        }
    }, [applyFilter, clearFilter, edit, ActiveMonthFilter])


    const handleSelectedData = (index, datakey, data) => {
        // if (index === selectedDataIndex) {
        //     setSelectedDataHeading('')
        //     setSelectedData([])
        //     setSelectedDataIndex(-1)

        // } else {
        //     setSelectedDataHeading(datakey)
        //     setSelectedData(data)
        //     setSelectedDataIndex(index)
        // }
        setSelectedData([])
        if (index === selectedDataIndex) {
            setSelectedDataIndex(-1)
            setSelectedDataHeading('')

        } else {
            setSelectedDataHeading(datakey)
            setSelectedDataIndex(index)
            setTimeout(() => {
                setSelectedData(data)
            }, 300)

        }
    }


    const handleEditValue = (e) => {
        const { name, value } = e.target;
        console.log(name, '-', value)
        const [dataKey, date] = name.split('-'); 

  
        setEditedData(prevData => {
            const updatedData = prevData[dataKey] ? [...prevData[dataKey]] : [];

            const existingIndex = updatedData.findIndex(item => item.date === date);

            if (existingIndex >= 0) {
                updatedData[existingIndex].value = value;
            } else {
                updatedData.push({ date, value });
            }

            return {
                ...prevData,
                [dataKey]: updatedData
            };
        });
   
    
    }

    const handleSavebtn = () => {
        console.log("Edited Data: ", editedData);
        // handleEdit();
    }


    return (
        <div className="Dashboardsmis">
            {
                !edit ?
                    <div className="row" style={{ position: 'relative' }}>
                        <div className={`${selectedDataIndex == '-1' ? 'col-xs-12' : "col-md-6 col-xs-12"} transition-col`}>
                            <div className="table" style={{ marginTop: '20px' }}>
                                <div className="phocket-table-new newTable" style={{ overflowX: 'auto', marginTop: '0px' }}>
                                    <table>
                                        <thead style={{ position: 'sticky', top: 0 }}>
                                            <tr>
                                                <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Particulars</th>
                                                {
                                                    APIData['Projected Loan nos Disbursed'].map((item, index) => (
                                                        <th key={index}>{item.date}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(APIData).map(([datakey, datavalue], index) => (
                                                <tr className={selectedDataIndex === index ? 'activeTr' : ''} key={index} style={{ cursor: 'pointer' }} onClick={() => handleSelectedData(index, datakey, datavalue)}>
                                                    <td className='leftsticky'><b>{datakey}</b></td>
                                                    {
                                                        datavalue?.map((value, index) => {
                                                            return (
                                                                <td key={index} style={{width: ActiveMonthFilter === '3month' ? '100px':'auto'}}>{value?.value}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {
                            selectedData?.length > 0 ?
                                <div className="col-md-6 col-xs-12">
                                    <div className="charts" style={{ marginTop: '20px' }}>
                                        <h3>{selectedDataHeading}</h3>
                                        <div className="chartbar" style={{ height: window?.innerWidth > 1800 ? '730px' : '460px' }}>
                                            <ResponsiveContainer width="100%" height="100%">
                                                <ComposedChart
                                                    width={500}
                                                    height={150}
                                                    data={selectedData}
                                                    barSize={ActiveMonthFilter === '3month' ? 40: ActiveMonthFilter === '6month' ? 30 : 20}
                                                    margin={{
                                                        top: 20,
                                                        right: 0,
                                                        left: 30,
                                                        bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid stroke='none' />
                                                    <XAxis dataKey="date" />
                                                    <YAxis  padding={{ top: 20 }}/>
                                                    <Tooltip />
                                                    <Bar dataKey="value" fill="#8884d8" />
                                                    <Line type="monotone" dataKey="value" stroke="#223D64" strokeWidth={2} dot={null} />
                                                </ComposedChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                    </div>
                    :
                    <div className="row">
                        <div className='col-xs-12'>
                            <div className="table" style={{ marginTop: '20px' }}>
                                <div className="phocket-table-new newTable" style={{ overflowX: 'auto', marginTop: '0px' }}>
                                    <table>
                                        <thead style={{ position: 'sticky', top: 0 }}>
                                            <tr>
                                                <th style={{ position: 'sticky', left: 0, zIndex: '1' }}>Particulars</th>
                                                {
                                                    APIData['Projected Loan nos Disbursed'].map((item, index) => (
                                                        <th key={index}>{item.date}</th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(APIData).map(([datakey, datavalue], index) => (
                                                <tr key={index}>
                                                    <td className='leftsticky'><b>{datakey}</b></td>
                                                    {
                                                        datavalue?.map((value, index) => {
                                                            return (
                                                                <td key={index}><input type="number" name={datakey + '-' + value?.date}  value={editedData[datakey]?.find(item => item.date === value.date)?.value  || value.value}  className='Editinputtag' onChange={(e) => handleEditValue(e)} /></td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="Filterations" style={{ marginTop: '40px', textAlign: 'center' }}>
                                <button className='filterbtn' style={{ width: '8%' }} onClick={() => handleSavebtn()}>Save</button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}
