import React, { Component } from 'react'
import UserFinancial from '../../../presentationals/Admin/CustomerPages/UserMobileContacts.component'
import DotLoader from '../../../presentationals/Loader/DotLoader.component'
import { APIS } from '../../../../utils/api-factory';
var sortJsonArray = require('sort-json-array');
class ContactApps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MobileContacts: '',
            AppList: '',
            deviceData:'',
            loaderState:false
        }
    }

    isJson(e){
        try{
            JSON.parse(e)
        }catch(e){
            return false
        }
        return true;
    }

    componentDidMount() {
        let abc = [
            '{"packageName":"com.borrowbuddy"',
            '"appName":"BorrowBuddy"',
            '"firstInstalledTime":1625902307199',
            '"lastUpdatedTime":1625902307199}'
          ];
      
        const { allDetail } = this.props
        this.setState({loaderState:true})
        fetch(APIS.GET_USER_DEVICE_DATA + allDetail.userId)
            .then(res => res.json())
            .then(json => {
                            
                this.setState({
                    deviceData: json,
                    loaderState: false
                })
               
            })
           
        // if (allDetail != null && allDetail.appData != null && typeof allDetail.appData.installedAppName === 'string') {
        //     if(this.isJson(allDetail.appData.installedAppName)){
        //         this.setState({ AppList: JSON.parse(allDetail.appData.installedAppName)})
        //     }
            
        // }
     
    }

    render() {
        const { deviceData, deviceDataLoader } = this.props;
        
        return (
            <div>
                <UserFinancial
                    MobileContacts={this.state.deviceData.userContactList}
                    AppList={this.state.deviceData.userAppList}
                />
                {this.state.loaderState ?
                    <DotLoader /> : null}
            </div>
        )
    }
}

export default ContactApps;