import React, { useState, useEffect } from 'react'
import DebtManagement from './DebtManagement'
import DebtManagementDashboard from './DebtManagementDashboard'
import KPIDebtManagementDashboard from './KPIDebtManagementDashboard'
import KPILoanManagementDashboard from './KPILoanManagementDashboard'
import LoanDashboard from './LoanDashboard'
import LoanPortfolioReview from './LoanPortfolioReview'
import PaymentsCollected from './PaymentsCollected'
import RevenueOperationalExpenses from './RevenueOperationalExpenses'
import BackLogo from '../../../../images/rewampimages/Chevron.png'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { getNBFCList } from './DebtListActionCreator'
import NPALeadsContainer from './NPALeads.container'
export default function DebtListMain({ pageViewHandler,admin }) {
    const [activeTab, setActiveTab] = useState('debtManagement')
    const [detailView,setDetailView] = useState(false)
    const [nbfcList, setnbfcList] = useState([])
    
    const maxHeight = window.innerWidth > 1300 ? '60px' : '75px'
    const sectionStyle ={
        padding: (activeTab === 'debtManagement' || activeTab == 'KPILoanManagmentDashboard') && detailView == true ? '0px' :  window.innerWidth > 1800 ? '20px' : '100px 20px' 
    }
    const BackBtnStyle = {
        height: maxHeight,
        width: '100px',
        padding: '10px',
        textAlign: 'center',
        backgroundColor: '#1D3557',
        color: '#fff',
        cursor: 'pointer',
    }

    useEffect(() => {
            getNBFCListAPI()
    }, [])

    const getNBFCListAPI = ()=>{
        getNBFCList((callback) => {
            console.log(callback)
            if (callback) {
                setnbfcList(callback)
            } else {
                setnbfcList([])
            }
        })
    }
    const handleTabs = (type) => {
        setActiveTab(type)
    }

    const detailViewHandler = (type) =>{
        setDetailView(type)
    }

    return (
        <>
            <div className="DebtListMain">
                {
                    !detailView ?
                <div className="tabs" style={{ display: 'flex' }}>
                    <div className="" style={BackBtnStyle} id="debtListTabs" onClick={(e) => pageViewHandler(e, 'landingPage')}>
                        <ExitToAppIcon style={{ fontSize: '30px', margin: '7px 20px 10px 0px', transform: 'rotateY(180deg)' }} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="row">
                            <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'debtManagement' ? 'active' : ''}`} onClick={() => handleTabs('debtManagement')} style={{ height: maxHeight }} id="debtListTabs">
                                <h5>Debt Management</h5>
                            </div>
                            <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'KPIDebtManagement' ? 'active' : ''}`} onClick={() => handleTabs('KPIDebtManagement')} style={{ height: maxHeight }} id="debtListTabs">
                                <h5>KPI Debt Managment</h5>
                            </div>
                            {/* <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'DebtManagmentDashboard' ? 'active' : ''}`} onClick={() => handleTabs('DebtManagmentDashboard')} style={{ height: maxHeight }} id="debtListTabs">
                            <h5>Debt Management Dashboard</h5>
                        </div> */}
                            <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'KPILoanManagmentDashboard' ? 'active' : ''}`} onClick={() => handleTabs('KPILoanManagmentDashboard')} style={{ height: maxHeight }} id="debtListTabs">
                                <h5>KPI Loan Managament</h5>
                            </div>
                            <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'Paymentscollected' ? 'active' : ''}`} onClick={() => handleTabs('Paymentscollected')} style={{ height: maxHeight }} id="debtListTabs">
                                <h5>Payments collected</h5>
                            </div>
                            <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'LoanPortfolio' ? 'active' : ''}`} onClick={() => handleTabs('LoanPortfolio')} style={{ height: maxHeight }} id="debtListTabs">
                                <h5>Loan Portfolio Review</h5>
                            </div>
                            <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'RevenueOperation' ? 'active' : ''}`} onClick={() => handleTabs('RevenueOperation')} style={{ height: maxHeight }} id="debtListTabs">
                                <h5>Revenue & Operational Expenses</h5>
                            </div>
                            {/* <div className={`col-sm-1 col-xs-12 box customwidth ${activeTab == 'LoanDashboard' ? 'active' : ''}`} onClick={() => handleTabs('LoanDashboard')} style={{ height: maxHeight }} id="debtListTabs">
                                <h5>Loan Dashboard</h5>
                            </div> */}
                        </div>
                    </div>
                </div>
:''}
                <div className="Sections" style={sectionStyle}>
                    {/* <div className="row">
                        <div className="col-sm-4 " style={{ marginTop: '15px', marginBottom: '30px' }}>
                            <button style={{ color: '#223d64', fontWeight: '400', display: 'flex', border: '1px solid #267dff', borderRadius: '6px', padding: '8px 10px' }} onClick={(e) => pageViewHandler(e, 'landingPage')} ><ArrowBackIosNewIcon style={{ marginTop: '1px' }} />&nbsp;Back</button>
                        </div>
                    </div> */}
                    {
                        activeTab == 'debtManagement'  || activeTab == 'KPILoanManagmentDashboard'?
                            <NPALeadsContainer admin={admin} detailViewHandler={detailViewHandler} activeTab={activeTab}/>
                            // <DebtManagement nbfcList={nbfcList} />
                            : ''
                    }
                    {
                        activeTab == 'KPIDebtManagement' ?
                            <KPIDebtManagementDashboard nbfcList={nbfcList} />
                            : ''
                    }
                    {
                        activeTab == 'DebtManagmentDashboard' ?
                            <DebtManagementDashboard />
                            : ''
                    }
                   
                    {
                        activeTab == 'Paymentscollected' ?
                            <PaymentsCollected nbfcList={nbfcList}/>
                            : ''
                    }
                    {
                        activeTab == 'RevenueOperation' ?
                            <RevenueOperationalExpenses nbfcList={nbfcList}/>
                            : ''
                    }
                    {
                        activeTab == 'LoanPortfolio' ?
                            <LoanPortfolioReview nbfcList={nbfcList}/>
                            : ''
                    }
                    {
                        activeTab == 'LoanDashboard' ?
                            <LoanDashboard />
                            : ''
                    }
                </div>

            </div>
        </>
    )
}
