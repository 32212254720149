import React, { useState } from 'react';
import VerifyImage from '../../../../images/verified.png';
import NotVerified from '../../../../images/cross.png';
import moment from 'moment';
import { updatePaymentBreakupApi } from '../AdminActionCreator.component';

const EmiBreakUp = ({ close, payData, leadData, admin ,editable}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const handleEditClick = (data) => {
        setSelectedData(data);
        setIsPopupOpen(true);
    };

    const handleInputChange = (field, value) => {
        setSelectedData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSave = () => {
        if (selectedData) {
            const updatedData = {
                ...selectedData,
                verifiedBy: admin.emailId,
            };
            updatePaymentBreakupApi(updatedData, callback => {
                setIsPopupOpen(false);
                setSelectedData(null);
            })

            console.log('Saving data:', updatedData);



        }
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedData(null);
    };

    return (
        <>
            <div className="background-blur " onClick={close}></div>
            <div
                className="popup-main small-popup container-fluid dbl-border"
                style={{ marginTop: '40px', width: '90%', maxWidth: '90%' }}
            >
                <div className="popup-content">
                    <h4 className="text-center">
                        Payment Breakdown for {leadData.applicationId}
                    </h4>
                    <div className="phocket-table-new" style={{ overflow: 'auto' }}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Bounce GST</th>
                                    <th>Created At</th>
                                    <th>Principal Amount</th>
                                    <th>Interest Amount</th>
                                    <th>EMI Amount</th>
                                    <th>Is Active</th>
                                    <th>NACH Bounce</th>
                                    <th>NACH Bounce Waived</th>
                                    <th>Overdue Amount</th>
                                    <th>Penalty</th>
                                    <th>Penalty GST</th>
                                    <th>Penalty Waived</th>
                                    <th>Rate of Interest</th>
                                    {
                                    editable ?
                                    <th>Edit</th>
                                :""}
                                    <th>Updated At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payData.map((data) => (
                                    <tr key={data?.id}>
                                        <td style={{ textAlign: 'center' }}>{data?.bounceGst}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            {moment(data?.createdAt).format('DD-MM-YYYY')}
                                        </td>
                                        <td style={{ textAlign: 'center' }}>{(data?.principalAmount)?.toLocaleString("en-US")}</td>
                                        <td style={{ textAlign: 'center' }}>{(data?.interestAmount)?.toLocaleString("en-US")}</td>
                                        <td style={{ textAlign: 'center' }}>{(data?.emiAmount)?.toLocaleString("en-US")}</td>
                                        <td style={{ textAlign: 'center' }}>
                                            {data?.isActive ? (
                                                <img
                                                    src={VerifyImage}
                                                    width="22px"
                                                    alt="Verified"
                                                />
                                            ) : (
                                                <img
                                                    src={NotVerified}
                                                    width="20px"
                                                    alt="Not Verified"
                                                />
                                            )}
                                        </td>
                                        <td style={{ textAlign: 'center' }}>{data?.nachBounce}</td>
                                        <td style={{ textAlign: 'center' }}>{data?.nachBounceWaived}</td>
                                        <td style={{ textAlign: 'center' }}>{data?.overdueAmount}</td>
                                        <td style={{ textAlign: 'center' }}>{data?.penalty}</td>
                                        <td style={{ textAlign: 'center' }}>{data?.penaltyGst}</td>
                                        <td style={{ textAlign: 'center' }}>{data?.penaltyWaived}</td>
                                        <td style={{ textAlign: 'center' }}>{data?.rateOfInterest}</td>
                                        {
                                            editable ?
                                            <td style={{ textAlign: 'center' }}>
                                            <i
                                                className="fa fa-edit"
                                                onClick={() => handleEditClick(data)}
                                                style={{ cursor: 'pointer' }}
                                                ></i>
                                        </td>
                                            :''}
                                        <td style={{ textAlign: 'center' }}>
                                            {moment(data?.updatedAt).format('DD-MM-YYYY')}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {isPopupOpen && selectedData && (
                    <div className="edit-popup">
                        <hr />
                        <div className="row">
                            <h4 style={{ marginLeft: '18px' }}>Edit Fields for selected Payment</h4>
                            {/* <div className='col-sm-4 col-xs-12' style={{marginTop:'15px'}}>
                                <div className="form-group">
                                    <label>Principal Amount</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        value={selectedData.principalAmount || ''}
                                        onChange={(e) =>
                                            handleInputChange('principalAmount', e.target.value)
                                        }
                                    />
                                </div>
                            </div> */}
                            {/* <div className='col-sm-4 col-xs-12' style={{marginTop:'15px'}}>
                                <div className="form-group">
                                    <label>Interest Amount</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        value={selectedData.interestAmount || ''}
                                        onChange={(e) =>
                                            handleInputChange('interestAmount', e.target.value)
                                        }
                                    />
                                </div>
                            </div> */}
                            <div className='col-sm-4 col-xs-12' style={{marginTop:'15px'}}>
                                <div className="form-group">
                                    <label>EMI Amount</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        value={selectedData.emiAmount || ''}
                                        onChange={(e) =>
                                            handleInputChange('emiAmount', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{marginTop:'15px'}}>
                                <div className="form-group">
                                    <label>NACH Bounce</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        value={selectedData.nachBounce || ''}
                                        onChange={(e) =>
                                            handleInputChange('nachBounce', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{marginTop:'15px'}}>

                                <div className="form-group">
                                    <label>NACH Bounce Waived</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={selectedData.nachBounceWaived || ''}
                                        onChange={(e) =>
                                            handleInputChange('nachBounceWaived', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{marginTop:'15px'}}>

                                <div className="form-group">
                                    <label>Overdue Amount</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={selectedData.overdueAmount || ''}
                                        onChange={(e) =>
                                            handleInputChange('overdueAmount', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{marginTop:'15px'}}>
                                <div className="form-group">
                                    <label>Penalty</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        value={selectedData.penalty || ''}
                                        onChange={(e) =>
                                            handleInputChange('penalty', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{marginTop:'15px'}}>
                                <div className="form-group">
                                    <label>Penalty GST</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        value={selectedData.penaltyGst || ''}
                                        onChange={(e) =>
                                            handleInputChange('penaltyGst', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='col-sm-4 col-xs-12' style={{marginTop:'15px'}}>
                                <div className="form-group">
                                    <label>Penalty Waived</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        value={selectedData.penaltyWaived || ''}
                                        onChange={(e) =>
                                            handleInputChange('penaltyWaived', e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            {

                            }
                            {/* <div className='col-sm-4 col-xs-12' style={{marginTop:'15px'}}>
                                <div className="form-group">
                                    <label>Paid Amount</label>
                                    <input
                                        className='form-control'
                                        type="text"
                                        value={selectedData.paidAmount || ''}
                                        onChange={(e) =>
                                            handleInputChange('paidAmount', e.target.value)
                                        }
                                    />
                                </div>
                            </div> */}
                            <div className="col-xs-12">
                            <button onClick={handleSave} className="btn btn-primary" style={{ marginTop: '22px',marginLeft:'48%' }}>
                                    Save
                                </button>
                                <button onClick={handleClosePopup} className="btn btn-secondary" style={{ marginTop: '22px',marginLeft:'15px',border:'1px solid #267dff' }}>
                                    Cancel
                                </button>
                            </div>
                            {/* <div className="form-actions" style={{marginTop:'30px'}}>
                                <button onClick={handleSave} className="btn btn-primary" style={{ marginTop: '22px',marginLeft:'48%' }}>
                                    Save
                                </button>
                                <button onClick={handleClosePopup} className="btn btn-secondary" style={{ marginTop: '22px',marginLeft:'15px',border:'1px solid #267dff' }}>
                                    Cancel
                                </button>
                            </div> */}
                        </div>
                    </div>
                )}
            </div>


        </>
    );
};

export default EmiBreakUp;
