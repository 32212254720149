import React, { useEffect, useState } from 'react'
import dashboardimg from './images/dashboardimg.png';
import selecteddashboardimg from './images/selecteddashboardimg.png';
import PerformanceDashboard from './PerformanceDashboardOperations';
import CustomerServiceDahsboard from './dashbaordCustomerService';
import RepeatDashboard from './repeatDashboard';
import Repeat from './creditRepeatDashboard';
import { ROLE } from '../../../../../utils/Constant';
import BackLogo from '../../../../../images/rewampimages/Chevron.png'

export default function configMain({ admin, pageViewHandler }) {
    const [tab, setselectedtab] = useState('');

    const tabchange = (type) => {
        setselectedtab(type);

    }
    useEffect(() => {
     
            admin.rolelist.indexOf(ROLE.LM_Legal_Dashboard) >= 0 ?
                setselectedtab('operation') : "" 
     
            admin.rolelist.indexOf(ROLE.LM_Customer_Service_Dashboard) >= 0 ?
                setselectedtab('customerservice') : "" 
  
            admin.rolelist.indexOf(ROLE.LM_Credit_Dashboard) >= 0 ?
                setselectedtab('creditrepeat') : "" 




    }, [])
    return (
        <>
            <div className="PerformanceTracker">
                <div className="container-fluid details-new fixed-top"  >
                    <div className="row details-new-tab-div" style={{ display: 'flex', justifyContent: 'end' }}>
                        <div className="col-sm-6 col-xs-12 " style={{ marginTop: '15px' }}>
                        <img onClick={(e) => pageViewHandler(e,'landingPage')} style={{ cursor: 'pointer' }} src={BackLogo} width={'30px'} /> <button style={{ color: '#1D3557', fontWeight: '700' }} onClick={(e) => pageViewHandler(e,'landingPage')} >Back</button>
                    </div>

                        <div className=" col-lg-6 col-xs-12" style={{ fontSize: '10px', display: 'flex', justifyContent: 'end' }}>


                            <div className="btn-group-new">
                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                    admin.rolelist.indexOf(ROLE.LM_Legal_Dashboard) >= 0 ?
                                        <button className={(tab === 'operation') ? "active" : ""} onClick={(e) => tabchange('operation')}><img src={tab === 'operation' ? selecteddashboardimg : dashboardimg} style={{ width: '27px' }} /><br />Operaton Team</button>
                                        : "" : ""}
                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                    admin.rolelist.indexOf(ROLE.LM_Customer_Service_Dashboard) >= 0 ?
                                        <button className={(tab === 'customerservice') ? "active" : ""} onClick={(e) => tabchange('customerservice')}><img src={tab === 'customerservice' ? selecteddashboardimg : dashboardimg} style={{ width: '27px' }} /><br />Customer Service</button>
                                        : "" : ""}
                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                    admin.rolelist.indexOf(ROLE.LM_Credit_Dashboard) >= 0 ? <button className={(tab === 'creditfresh') ? "active" : ""} onClick={(e) => tabchange('creditfresh')}><img src={tab === 'creditfresh' ? selecteddashboardimg : dashboardimg} style={{ width: '27px' }} /><br />Credit Team <br /> (Fresh)
                                    </button> : "" : ""}
                                {admin.rolelist != undefined && admin.rolelist.length > 0 ?
                                    admin.rolelist.indexOf(ROLE.LM_Credit_Dashboard) >= 0 ?
                                        <button className={(tab === 'creditrepeat') ? "active" : ""} onClick={(e) => tabchange('creditrepeat')}><img src={tab === 'creditrepeat' ? selecteddashboardimg : dashboardimg} style={{ width: '27px' }} /><br />Credit Team <br /> (Repeat)</button>
                                        : "" : ""}
                            </div>

                        </div>
                    </div>
                </div>
                {tab === 'operation' ?
                    <PerformanceDashboard />
                    : ""}
                {tab === 'customerservice' ?

                    <CustomerServiceDahsboard />
                    : ""}
                {tab === 'creditfresh' ?
                    <RepeatDashboard />
                    : ""}
                {tab === 'creditrepeat' ?
                    <Repeat />
                    : ""}
            </div >
        </>
    )
}
