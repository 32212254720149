import React, { useEffect, useState } from 'react'
import { getApiCostindDataApi, updateCostingApi } from '../AdminActionCreator.component'
import Loader from '../../../presentationals/Loader/Loader.component'
import Popup from '../../../presentationals/Popup/Popup.component'
const ApiConfig = () => {
    const [costData, setCostData] = useState([])
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [loaderState, setLoader] = useState(false)
    const [popupStatus, setPopupStatus] = useState('')
    const [popupstate, setPopup] = useState('');
    useEffect(() => {
        getCost()
        setTimeout(() => {
            updateHeight();
        }, 400)
    }, [])
    const closePopup = () => {
        setPopup(false)
    }
    function updateHeight() {
        const leftDivHeight = document.getElementById('updateconfigbox')?.clientHeight;
        document.getElementById('saveconfigbox').style.height = leftDivHeight + 'px';
    }

    const getCost = () => {
        setLoader(true)
        getApiCostindDataApi(callback => {
            setCostData(callback)
            setLoader(false)


        })
    }
    const changeHandler = (e, i, type) => {
        let changeData = costData
        if (type === 'failureCost') {
            changeData[i][type] = e.target.checked;
        } else {
            changeData[i][type] = (e.target.value)
        }
        // changeData[i]['costing'] = (e.target.value)
        setCostData(changeData)
        forceUpdate()

    }
    const submit = () => {
        setLoader(true)
        updateCostingApi(costData, callback => {
            if (callback === 'success') {
                setPopupStatus('Updated Successfully !')
                setPopup(true)
                setLoader(false)
                remove()
            } else {
                setPopupStatus('Please try again later !')
                setPopup(true)
                setLoader(false)
                remove()
            }
        })
    }
    const remove = () => {
        setTimeout(() => {
            setPopup(false)
        }, 5000);
    }
    return (
        <div>
            {loaderState ?
                <Loader />
                : ""}
            {popupstate ?
                <Popup closePopup={closePopup}
                    popupStatus={popupStatus}
                />
                : ""}

            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>

                        <div className="credit-second-box-small" style={{ padding: '10px', minHeight: '500px', background: 'white', boxShadow: 'rgba(50, 71, 92, 0.02) 0px 2px 6px 4px, rgba(50, 71, 92, 0.04) 0px 2px 9px 0px, rgba(0, 0, 0, 0.25) 0px 4px 4px 0px' }} id="updateconfigbox">
                            <h5 ><b>Update Configuration</b></h5>
                            <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                            {/* <div className='row'>
                                {costData && costData.map((data, i) => {
                                    return (
                                        <div className="col-sm-4 col-xs-12 form-group" style={{marginTop:'7px'}}>
                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>{data?.child !==null && data?.child !== undefined && data?.child !== '' ? data?.child : data?.apiDisplay }</b>
                                            <div style={{ display: 'flex' }}>
                                                <div>
                                                    <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Order</b>

                                                    <input type='number' style={{ borderRadius: '5px',width:'90%' }} value={costData[i]?.order} placeholder='' className='form-control credit-input' onChange={(e) => changeHandler(e, i, 'order')} />
                                                </div>
                                                <div>
                                                    <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>Cost</b>
                                                    <input type='number' style={{ borderRadius: '5px',width:'90%' }} value={costData[i].costing} placeholder='' className='form-control credit-input' onChange={(e) => changeHandler(e, i, 'costing')} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div> */}
                            {
                                costData && costData?.length > 0 ? (
                                    <>
                                        <div className='APITracker'>
                                            <div className="outer-table table-responsive" style={{ width: '100%', margin: '0px' }}>
                                                <table className="table" style={{ margin: '0px' }}>
                                                    <thead className='table-heads2'>
                                                        <tr style={{ backgroundColor: '#223D64' }}>
                                                            <th style={{ borderTopLeftRadius: '10px' }}>API Name</th>
                                                            <th>Include Failure Cost</th>
                                                            <th>Order</th>
                                                            <th style={{ borderTopRightRadius: '10px' }}>Cost</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='api-tracker-striped-data'>
                                                        {costData && costData.map((data, i) => {
                                                            return (

                                                                <tr key={i}>

                                                                    <td style={{ verticalAlign: 'middle', textAlign: 'left' }}>{data?.child !== null && data?.child !== undefined && data?.child !== '' ? data?.child : data?.apiDisplay}</td>

                                                                    <td> <input type='checkbox' checked={costData[i]?.failureCost} placeholder='' className='' onChange={(e) => changeHandler(e, i, 'failureCost')} style={{ marginTop: '15px' }} /></td>

                                                                    <td> <input type='number' style={{ borderRadius: '5px', width: '90%', textAlign: 'center' }} value={costData[i]?.order} placeholder='' className='form-control credit-input' onChange={(e) => changeHandler(e, i, 'order')} /></td>

                                                                    <td><input type='number' style={{ borderRadius: '5px', width: '90%', textAlign: 'center' }} value={costData[i].costing} placeholder='' className='form-control credit-input' onChange={(e) => changeHandler(e, i, 'costing')} /></td>


                                                                </tr>
                                                            )
                                                        })}
                                                        <tr>
                                                            <td></td>
                                                            <td colSpan={2}>
                                                                <div className='text-left' style={{ marginTop: '10px' }}>
                                                                    <button className='btn btn-success' onClick={() => submit()}>Update</button>
                                                                </div>
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )
                                    : ''}
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12'>
                        <div className="credit-second-box-small" id="saveconfigbox" style={{ padding: '10px', minHeight: '500px', background: 'white', boxShadow: 'rgba(50, 71, 92, 0.02) 0px 2px 6px 4px, rgba(50, 71, 92, 0.04) 0px 2px 9px 0px, rgba(0, 0, 0, 0.25) 0px 4px 4px 0px' }}>
                            <h5 ><b>Saved Configuration</b></h5>
                            <div style={{ borderBottom: '1.5px solid #7D6FD9', marginBottom: '10px' }}></div>
                            {/* <div className='row'>
                                {costData && costData.map((data, i) => {
                                    return (
                                        <div className="col-sm-4 col-xs-12 form-group">
                                            <b style={{ fontSize: '10px', color: ' rgba(0, 0, 0, 0.60)' }}>{(data?.order ? data?.order : 'N/A') + ' - ' + (data?.child !== null && data?.child !== undefined && data?.child !== '' ? data?.child : data?.apiDisplay)}</b>
                                            <h4>{costData[i].costing}</h4>
                                        </div>
                                    )
                                })}
                            </div> */}
                            {
                                costData && costData?.length > 0 ? (
                                    <>
                                        <div className='APITracker'>
                                            <div className="outer-table table-responsive" style={{ width: '100%', margin: '0px' }}>
                                                <table className="table" style={{ margin: '0px' }}>
                                                    <thead className='table-heads2'>
                                                        <tr style={{ backgroundColor: '#223D64' }}>
                                                            <th style={{ borderTopLeftRadius: '10px' }}>Order</th>
                                                            <th>API Name</th>
                                                            <th>Failure Cost Included</th>
                                                            <th style={{ borderTopRightRadius: '10px' }}>Cost</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='api-tracker-striped-data'>
                                                        {costData && costData.map((data, i) => {
                                                            return (

                                                                <tr key={i}>

                                                                    <td style={{ verticalAlign: 'middle', textAlign: 'center', padding: '18.5px 15px' }}>{data?.order !== undefined && data?.order !== '' && data?.order !== null ? data?.order : 'N/A'}</td>

                                                                    <td style={{ verticalAlign: 'middle', textAlign: 'left', padding: '18.7px 15px' }}>{data?.child !== null && data?.child !== undefined && data?.child !== '' ? data?.child : data?.apiDisplay}</td>

                                                                    <td>{data?.failureCost !== undefined && data?.failureCost !== '' && data?.failureCost !== null ? data?.failureCost == true ? 
                                                                    <i class="fa fa-check" aria-hidden="true" style={{color:'green',marginTop:'15px'}}></i> : 
                                                                    <i class="fa fa-times" aria-hidden="true" style={{color:'red',marginTop:'15px'}}></i> : ''}</td>

                                                                    <td style={{ padding: '18.5px 15px' }}>{costData[i].costing}</td>


                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                )
                                    : ''}

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default ApiConfig