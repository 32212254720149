import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoanApply from '../../../presentationals/Admin/Recovery/Disburse.component';
import {
    pushNotification, saveImportantLoans, getUserDetailByLoanIdOptimisedApi, getWhatsappMsgApi,
    getLoanApprovalLeadOptimized, saveNachDataApi, getRoleAssignView, pushLeadToRetraApi, getCountSatusWise,
} from '../AdminActionCreator.component';
import UserDetail from '../CustomerPages/UserDetailMainNew.container';
import { APIS } from '../../../../utils/api-factory';
import { STRING, ROLE } from '../../../../utils/Constant'
import { _preFormatDate } from '../../../../utils/validation'
import MainDetailsPopup from '../CustomerPages/MainDetailPopup.container'
import localStorageUtil from '../../../../utils/localStorageUtil';
import Popup from '../../../presentationals/Popup/Popup.component';
import UsersForAssignUi from '../../../presentationals/Popup/AssignUserPopup.component'
import DeletePopup from '../../../presentationals/Popup/Delete.component'
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import SearchSelect from './SelectSearch.container'
import WhatsAppPopup from '../../../presentationals/Popup/WhatsApp.popup'
import FixedLoader from '../../../presentationals/Loader/Loader.component'
import moment from 'moment'
let columnHide = true;
let isDeleteUser = false
let nachCheckbox = false
let markImportant = false
let interval;
let paginationNumber = 10;
let commentColumn = false

class LoanApplyContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userState: true,
            viewState: false,
            allDetail: "",
            userDetail: "",
            followUpStatus: STRING.DISBURSED,
            forwordPageState: 1,
            loadarState: false,
            viewDetailPopupState: false,
            viewPopupDetail: '',
            logsUserId: '',
            todaysApplicationNo: '',
            actionButtonState: true,
            allAdminList: '',
            confirmationPopup: false,
            confirmationText: '',
            userIdForDelete: '',
            userIdForDeactivate: '',
            searchByDate: moment(new Date()).subtract(1, 'days').format('DD-MM-YYYY'),
            searchByDateShow: moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'),
            searchByEndDate: moment(new Date()).format('DD-MM-YYYY'),
            searchByEndDateTOShow: moment(new Date()).format('YYYY-MM-DD'),
            popupState: false,
            popupStatus: '',
            assignPopupState: false,
            loanIdForAssign: '',
            emailIdForAssign: '',
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
            commonSearch: '',
            markCompleteBtn: true,
            whatsappPopupState: false,
            whatsapMsgToShow: '',
            whatsappPopupIndex: -1,
            mainLoaderState: false,
            btnForAction: '',
            loanIdForPush: '',
            tabType: '',
            dataChange: '',
            nameOfUser: '',
            DeletePopupState: false,
            productType: '',
            assignToUserId: '',
            dateType: '',
            pageNumber: 1,
            startDate: moment(new Date()).format('DD-MM-YYYY'),
            endDate: moment(new Date()).format('DD-MM-YYYY'),
            countData: ''
        }
    }

    componentWillMount() {
        localStorage.setItem('showComment', 'no')

        getRoleAssignView(localStorageUtil.getFromLocalStorage("AdminemailId"), (callBack) => {
            this.setState({ allAdminList: callBack })
        })
        this.getLoanLead()
        getCountSatusWise(this.state.searchByDate, this.state.searchByEndDate, 'disburseDate', callBack => {
            console.log(callBack)
            this.setState({ countData: callBack })
        })
    }
    deactivateNachHandler(data) {
        this.setState({
            userIdForDeactivate: data.userId,
            DeletePopupState: true,
            confirmationText: 'Are you sure to Deactivate Nach',
            btnForAction: 'nach',
            nameOfUser: data
        })
    }

    getLoanLead() {
        let adminEmail = localStorageUtil.getFromLocalStorage("AdminemailId")
        let nbfcName = localStorageUtil.getFromLocalStorage("nbfcName")
        this.setState({
            loadarState: true
        })
        if (this.state.tabType == 'Advance') {
            this.props.getLoanApprovalLeadOptimized(this.state.followUpStatus, this.state.forwordPageState, adminEmail, this.state.searchByDate, nbfcName, this.state.searchByEndDate, this.state.tabType, '', '', '', '', this.state.dateType,
                (callBack) => {

                    fetch(APIS.TODAYS_LOAN_COUNT + this.state.searchByDate)
                        .then(res => res.json())
                        .then(json => {
                            this.setState({ todaysApplicationNo: json })
                        })
                    this.setState({
                        loadarState: false,
                    })
                });
        } else if (this.state.tabType == 'phocketMini') {
            // console.log("phocketmini")

        }
    }

    componentDidMount() {
        // if (this.state.viewState === false) {

        //     interval = setInterval(function () {
        //         let adminEmail = localStorageUtil.getFromLocalStorage("AdminemailId")
        //         let nbfcName = localStorageUtil.getFromLocalStorage("nbfcName")

        //         this.props.getLoanApprovalLeadOptimized(this.state.followUpStatus, this.state.forwordPageState, adminEmail, this.state.searchByDate, nbfcName, this.state.searchByEndDate, this.state.tabType, '', '', '', '', this.state.dateType,
        //             (callBack) => {
        //                 fetch(APIS.TODAYS_LOAN_COUNT + this.state.searchByDate)
        //                     .then(res => res.json())
        //                     .then(json => {
        //                         this.setState({ todaysApplicationNo: json, canCallApi: true })
        //                     })
        //             });
        //     }.bind(this), 300000) // 5 min
        // }
        let adminEmail = localStorageUtil.getFromLocalStorage("AdminemailId")
        let nbfcName = localStorageUtil.getFromLocalStorage("nbfcName")

        this.setState({ loadarState: true })
        this.props.getLoanApprovalLeadOptimized(this.state.followUpStatus, this.state.forwordPageState, adminEmail, this.state.searchByDate, nbfcName, this.state.searchByEndDate, this.state.tabType, '', '', '', '', this.state.dateType,
            (callBack) => {
                fetch(APIS.TODAYS_LOAN_COUNT + this.state.searchByDate)
                    .then(res => res.json())
                    .then(json => {
                        this.setState({ todaysApplicationNo: json, canCallApi: true })
                    })
                this.setState({
                    loadarState: false
                })
            })
    }
    newFilterHandler(e, type) {
        if (type === 'dateType') {
            this.setState({ dateType: e.target.value }, () => this.dateFilterFunction())
        }
        if (type === 'startDate') {
            this.setState({ searchByDate: moment(e.target.value).format('DD-MM-YYYY') })
            this.setState({ searchByDateShow: e.target.value }, () => this.dateFilterFunction())
        }
        if (type === 'endDate') {
            this.setState({ searchByEndDate: moment(e.target.value).format('DD-MM-YYYY') })
            this.setState({ searchByEndDateTOShow: e.target.value }, () => this.dateFilterFunction())
        }
        if (e.target.value === '' || e.target.value === 'Invalid date') {
            this.setState({ dateType: '', searchByDateShow: '', searchByEndDateTOShow: '', searchByDate: '', searchByEndDate: '' })
            let adminEmail = localStorageUtil.getFromLocalStorage("AdminemailId")
            let nbfcName = localStorageUtil.getFromLocalStorage("nbfcName")
            this.props.getLoanApprovalLeadOptimized(this.state.followUpStatus, this.state.forwordPageState, adminEmail, '', nbfcName, '', this.state.tabType, '', '', '', '', '',
                (callBack) => {
                    fetch(APIS.TODAYS_LOAN_COUNT + '')
                        .then(res => res.json())
                        .then(json => {
                            this.setState({ todaysApplicationNo: json })
                        })
                });
        }
    }
    dateFilterFunction() {
        if (this.state.searchByDate && this.state.searchByEndDate) {
            this.setState({
                loadarState: true
            })
            let adminEmail = localStorageUtil.getFromLocalStorage("AdminemailId")
            let nbfcName = localStorageUtil.getFromLocalStorage("nbfcName")
            this.props.getLoanApprovalLeadOptimized(this.state.followUpStatus, this.state.forwordPageState, adminEmail, this.state.searchByDate, nbfcName, this.state.searchByEndDate, this.state.tabType, '', '', '', '', this.state.dateType,
                (callBack) => {
                    this.setState({ loadarState: false })
                    fetch(APIS.TODAYS_LOAN_COUNT + this.state.searchByDate + '&eDate=' + this.state.searchByEndDate)
                        .then(res => res.json())
                        .then(json => {
                            this.setState({ todaysApplicationNo: json })
                        })
                });
                getCountSatusWise(this.state.searchByDate, this.state.searchByEndDate, 'disburseDate', callBack => {
                    console.log(callBack)
                    this.setState({ countData: callBack })
                })
        }
    }
    handleChangeNewPagination(e, value) {
        this.setState({ pageNumber: value })
        const newMinPagination = (value - 1) * paginationNumber;
        const newMaxPagination = value * paginationNumber;
        this.setState({
            tableMinPagination: newMinPagination,
            tableMaxPagination: newMaxPagination,
        });
    };

    changeHandler(e) {
        this.setState({ emailIdForAssign: e.target.value })
    }
    render() {
        const { loanApprovalLeadOptimized, admin, allStatus, newDataBackground } = this.props;
        if (admin != null) {
            if (admin.role != undefined && (admin.role == 'member' || admin.role == '' || admin.role == null)) {
                columnHide = false;
            }
            if (admin.role != null && admin.emailId == 'SuperUser') {
                isDeleteUser = true
            }
        }
        if (admin.rolelist != undefined) {
            if (admin.rolelist.indexOf(ROLE.NACH_CHANGE) >= 0) {
                nachCheckbox = true
            }
            if (admin.rolelist.indexOf(ROLE.MARK_AS_IMPORTANT) >= 0) {
                markImportant = true
            } if (admin.rolelist.indexOf(ROLE.COMMUNICATION) >= 0) {
                commentColumn = true
            }
        }

        let allAgentData = loanApprovalLeadOptimized
        if (this.state.commonSearch) {
            allAgentData = allAgentData.filter(row => {
                if (row.userName != null && row.state != null && row.loginDevice != null) {
                    return row.userName.toLowerCase().includes(this.state.commonSearch.toLowerCase()) ||
                        row.state.toLowerCase().includes(this.state.commonSearch.toLowerCase()) ||
                        row.loginDevice.toLowerCase().includes(this.state.commonSearch.toLowerCase())
                }
            })
        }


        return (
            <>
                {this.state.userState == true ?
                    <div className="container-fluid" style={{}}>
                        <div className='row'>
                            <h3 className=' blue-text' style={{ marginLeft: '20px' }}>Disbursed Leads</h3>
                        </div>
                        <div className='row'>
                            <div className='col-sm-3' >
                                <label style={{ fontSize: '14px' }}>Select Filter</label>
                                <br />
                                {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
                                    <SearchSelect
                                        admin={admin}
                                        getLoanLead={this.getLoanLead.bind(this)}
                                        getLoaderState={this.getLoaderState.bind(this)}
                                        commonSearchHandler={this.commonSearchHandler.bind(this)}
                                    />
                                    : null}
                                {/* <button className="reset-btn " style={{ marginTop: '10px',width:'200px',padding:'10px' }} onClick={e => this.paginationHandler('refresh')}>Refresh</button> */}
                            </div>
                            {/* <div className='col-sm-2 col-xs-12 select-new'> */}
                            {/* {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
                                    <div >
                                        <label style={{ fontSize: '14px' }}>Loan Status</label>
                                        <select className="form-control" value={this.state.followUpStatus} onChange={(e) => this.statusHandler(e)}>
                                            <optgroup className="display-none">
                                                <option value="">Select Status</option>
                                            </optgroup>
                                            <optgroup label="Status">
                                                {allStatus != null && allStatus != undefined && allStatus != '' ?
                                                    allStatus.map((status, i) => {
                                                        return (
                                                            <option value={status.statusId} key={i}>{status.adminStatus}</option>
                                                        );
                                                    }) : ""}
                                            </optgroup>
                                        </select>
                                    </div>
                                    : null} */}
                            {/* {admin != undefined && admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.LEAD_SELECTION) >= 0 ?
                                    <div className='select-new'>
                                        <select className="form-control" onChange={e => this.props.leadChangeHandler(e)} value={this.state.leadsViewState}>
                                            <option value="Advance">Loan Approval Leads</option>

                                            <option value="Important Leads">Important Leads</option>

                                        </select>
                                    </div>
                                    : null} */}
                            {/* </div> */}
                            <div className='col-sm-8 ' >
                                <div className="col-sm-3 col-xs-12">
                                    <label style={{ fontSize: '14px' }}>Start Date</label>
                                    <input value={this.state.searchByDateShow} style={{ padding: '13px' }} className='form-control' type='date' placeholder='Start Date' onChange={(e) => this.newFilterHandler(e, 'startDate')} />

                                </div>
                                <div className="col-sm-3 col-xs-12">
                                    <label style={{ fontSize: '14px' }}>End Date</label>
                                    <input value={this.state.searchByEndDateTOShow} style={{ padding: '13px' }} className='form-control' type='date' placeholder='End Date' onChange={(e) => this.newFilterHandler(e, 'endDate')} />

                                </div>
                                <div className='col-sm-2 col-xs-12'>
                                    <button className='btn btn-primary' onClick={()=>this.dateFilterFunction()} style={{marginTop:'23px',padding:'12px'}}>Filter</button>
                                </div>
                                {/* <label style={{ fontSize: '14px' }}>Select Filter</label>

                                <span className="main-search" >

                                    <select className="form-control " style={{ background: '#DBE9FF', fontWeight: '700', width: this.state.dateType === '' ? '50%' : '30%', borderRadius: this.state.dateType === '' ? '10px' : '', border: this.state.dateType === '' ? 'none' : '' }} value={this.state.dateType} onChange={(e) => this.newFilterHandler(e, 'dateType')}>
                                        <option value={''}>Select Date Type</option>
                                        <option value={'apply'}>Apply </option>
                                        <option value={'disburse'}>Disburse </option>
                                        <option value={'approve'}>Approved </option>
                                    </select>
                                    {this.state.dateType !== '' ?
                                        <span className="main-search" >
                                            <input style={{ borderRadius: '0 0px 0px 0' }} value={this.state.searchByDateShow} className='form-control' type='date' placeholder='Start Date' onChange={(e) => this.newFilterHandler(e, 'startDate')} />
                                        </span>
                                        : ""}
                                    {this.state.dateType !== '' ?
                                        <span className="main-search"  >
                                            <input value={this.state.searchByEndDateTOShow} className='form-control' type='date' placeholder='End Date' onChange={(e) => this.newFilterHandler(e, 'endDate')} />
                                        </span>
                                        : ""}
                                </span> */}
                                {/* <label style={{ fontSize: '14px' }}>Date Type</label>

                                <select className='form-control' value={this.state.dateType} onChange={(e) => this.newFilterHandler(e, 'dateType')}>
                                    <option value={''}>Select Date Type</option>
                                    <option value={'apply'}>Apply Date</option>
                                    <option value={'disburse'}>Disburse Date</option>
                                    <option value={'approve'}>Approved Date</option>

                                </select>
                                {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
                                    <div className="col-sm-2 col-xs-12">
                                        <label style={{ fontSize: '14px' }}>Start Date</label>
                                        <input value={this.state.searchByDateShow} className='form-control' type='date' placeholder='Start Date' onChange={(e) => this.newFilterHandler(e, 'startDate')} />

                                    </div>
                                    : null}
                                {admin != null && admin.nbfcName == null || admin.nbfcName == "" ?
                                    <div className="col-sm-2 col-xs-12">
                                        <label style={{ fontSize: '14px' }}>End Date</label>
                                        <input value={this.state.searchByEndDateTOShow} className='form-control' type='date' placeholder='End Date' onChange={(e) => this.newFilterHandler(e, 'endDate')} />


                                    </div>
                                    : null} */}
                            </div>

                            {/* {admin.rolelist != undefined && admin.rolelist.indexOf(ROLE.Lead_Count) >= 0 ?
                                <div className="col-sm-3 col-xs-12">
                                    <div className='count-div'>
                                        <div className='row'>
                                            <div className='col-sm-6 col-xs-6'>
                                                {this.state.todaysApplicationNo != null && admin.emailId !== 'collection5@borrowbuddy.co.in' ?
                                                    <button className="btn-count-new" onClick={e => this.showRepeateApplications()}><div style={{ fontSize: '14px', color: '#1D3557', fontWeight: '700' }}>REPEAT<br /> APPLICATIONS <br /><br /></div> <span style={{ color: '#2B78FF', fontSize: '20px', fontWeight: 700 }}>{this.state.todaysApplicationNo.repeatUserCount}</span>&nbsp;<span style={{ color: '#2B78FF', fontSize: '12px', fontWeight: '700' }}>counts</span></button>
                                                    : ""}
                                            </div>
                                            <div className='col-sm-6 col-xs-6'>
                                                {this.state.todaysApplicationNo != null && admin.emailId !== 'collection5@borrowbuddy.co.in' ?
                                                    <button className="btn-count-new" onClick={e => this.paginationHandler('all')}><div style={{ fontSize: '14px', color: '#1D3557', fontWeight: '700' }}>NEW<br /> APPLICATIONS <br /><br /></div><span style={{ color: '#2B78FF', fontSize: '20px', fontWeight: 700 }}> {this.state.todaysApplicationNo.newUserCount}</span>&nbsp;<span style={{ color: '#2B78FF', fontSize: '12px', fontWeight: '700' }}>counts</span></button>
                                                    : ""}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                : ""} */}
                        </div>
                        {/* <div className='row'>
                            <div className='col-sm-5 ' style={{ marginTop: '-25px' }} >
                                <label style={{ fontSize: '14px' }}>Select Filter</label>

                                <span className="main-search" >

                                    <select className="form-control " style={{ background: '#DBE9FF', fontWeight: '700', width: '30%', borderRadius: this.state.dateType === '' ? '12px' : '', borderRight: this.state.dateType === '' ? '1px solid #A8C8FF' : 0 }} value={this.state.dateType} onChange={(e) => this.newFilterHandler(e, 'dateType')}>
                                        <option value={''}>Select Date Type</option>
                                        <option value={'apply'}>Apply Date</option>
                                        <option value={'disburse'}>Disburse Date</option>
                                        <option value={'approve'}>Approved Date</option>
                                    </select>
                                    {this.state.dateType !== '' ?
                                        <span className="main-search" >
                                            <input style={{ borderRadius: '0 0px 0px 0' }} value={this.state.searchByDateShow} className='form-control' type='date' placeholder='Start Date' onChange={(e) => this.newFilterHandler(e, 'startDate')} />
                                        </span>
                                        : ""}
                                    {this.state.dateType !== '' ?
                                        <span className="main-search"  >
                                            <input value={this.state.searchByEndDateTOShow} className='form-control' type='date' placeholder='End Date' onChange={(e) => this.newFilterHandler(e, 'endDate')} />
                                        </span>
                                        : ""}
                                </span>
                       
                            </div>
                        </div> */}
                        {/* <br /> */}
                        {this.state.countData !== '' ?
                            <div className='row'>
                                <div className='col-sm-2 col-xs-12'>
                                    <div className='shadow-card' style={{marginTop:'30px',fontWeight:'700',minHeight:'133px'}}>
                                        Total Application Counts <br/>
                                        <h3 style={{color:'grey',fontWeight:'700'}}> {this.state.countData.totalApplications}</h3>
                                    </div>
                                </div>
                                <div className='col-sm-2 col-xs-12'>
                                    <div className='shadow-card' style={{marginTop:'30px',fontWeight:'700',minHeight:'133px'}}>
                                        Repeat Leads Counts <br/>
                                        <h3 style={{color:'grey',fontWeight:'700'}}> {this.state.countData.repeatLeads}</h3>
                                    </div>
                                </div>
                                <div className='col-sm-2 col-xs-12'>
                                    <div className='shadow-card' style={{marginTop:'30px',fontWeight:'700',minHeight:'133px'}}>
                                        Fresh Leads Counts <br/>
                                        <h3 style={{color:'grey',fontWeight:'700'}}> {this.state.countData.freshLeads}</h3>

                                    </div>
                                </div>
                                <div className='col-sm-2 col-xs-12'>
                                    <div className='shadow-card' style={{marginTop:'30px',fontWeight:'700',minHeight:'133px'}}>
                                        Fresh Disbursement Amount <br/>
                                        <h3 style={{color:'grey',fontWeight:'700'}}><i className='fa fa-rupee'></i>&nbsp;{Number(parseFloat(this.state.countData.freshDisbursement).toFixed(2)).toLocaleString('en-IN')} </h3>
                                    </div>
                                </div>
                                <div className='col-sm-2 col-xs-12'>
                                    <div className='shadow-card' style={{marginTop:'30px',fontWeight:'700',minHeight:'133px'}}>
                                        Repeat Disbursement Amount <br/>
                                        <h3 style={{color:'grey',fontWeight:'700'}}><i className='fa fa-rupee'></i>&nbsp;{Number(parseFloat(this.state.countData.repeatDisbursement).toFixed(2)).toLocaleString('en-IN')}</h3>

                                    </div>
                                </div>

                                <div className='col-sm-2 col-xs-12'>
                                    <div className='shadow-card' style={{marginTop:'30px',fontWeight:'700',minHeight:'133px'}}>
                                        Total Disbursement  Amount<br/>
                                        <h3 style={{color:'grey',fontWeight:'700'}}><i className='fa fa-rupee'></i>&nbsp;{Number(parseFloat(this.state.countData.totalDisbursement).toFixed(2)).toLocaleString('en-IN')}</h3>
                                    </div>
                                </div>

                            </div>
                            : ""}
                        <LoanApply
                            updatenameasonpanapi={this.updatenameasonpanapi.bind(this)}
                            forwordPageState={this.state.forwordPageState}
                            handleChangeNewPagination={this.handleChangeNewPagination.bind(this)}
                            pageNumber={this.state.pageNumber}
                            dateType={this.state.dateType}
                            newFilterHandler={this.newFilterHandler.bind(this)}
                            paginationNumber={this.state.paginationNumber}
                            commentColumn={commentColumn}
                            deactivateNachHandler={this.deactivateNachHandler.bind(this)}
                            userDetailHandler={this.userDetailHandler.bind(this)}
                            allUserDetail={allAgentData}
                            statusHandler={this.statusHandler.bind(this)}
                            dataFilter={this.dataFilter.bind(this)}
                            userViewHandler={this.userViewHandler.bind(this)}
                            admin={admin}
                            loadarState={this.state.loadarState}
                            allStatus={allStatus}
                            newDataBackground={newDataBackground}
                            todaysApplicationNo={this.state.todaysApplicationNo}
                            columnHide={columnHide}
                            isDeleteUser={isDeleteUser}
                            markAsImportant={this.markAsImportant.bind(this)}
                            showRepeateApplications={this.showRepeateApplications.bind(this)}
                            followUpStatus={this.state.followUpStatus}
                            deleteUserHandler={this.deleteUserHandler.bind(this)}
                            userCommentHandler={this.userCommentHandler.bind(this)}
                            searchByDate={this.state.searchByDate}
                            searchByEndDate={this.state.searchByEndDate}
                            searchByDateShow={this.state.searchByDateShow}
                            searchByEndDateTOShow={this.state.searchByEndDateTOShow}
                            userCommentChange={this.userCommentChange.bind(this)}
                            nachCheckbox={nachCheckbox}
                            markImportant={markImportant}
                            nashHandler={this.nashHandler.bind(this)}
                            userAssignPopup={this.userAssignPopup.bind(this)}
                            paginationHandler={this.paginationHandler.bind(this)}
                            phocketTablePagination={this.phocketTablePagination.bind(this)}
                            tableMinPagination={this.state.tableMinPagination}
                            tableMaxPagination={this.state.tableMaxPagination}
                            commonSearch={this.state.commonSearch}
                            commonSearchHandler={this.commonSearchHandler.bind(this)}
                            showWhatsappMsg={this.showWhatsappMsg.bind(this)}
                            pushToRetraHandler={this.pushToRetraHandler.bind(this)}
                        />
                    </div>
                    // </div>
                    : ""}
                {this.state.whatsappPopupState ?
                    <WhatsAppPopup
                        messages={this.state.whatsapMsgToShow}
                        closeWhatsappPopup={this.showWhatsappMsg.bind(this)} />
                    : null}
                <div className={this.state.viewState === true ? 'full-height' : ''}>
                    {this.state.viewState == true ?
                        <UserDetail
                            userDetailHandler={this.userDetailHandler.bind(this)}
                            dataChange={this.state.dataChange}
                            allDetail={this.state.allDetail}
                            userDetails={this.state.userDetail}
                            admin={admin}
                            getData={this.getData.bind(this)}
                            backHandler={this.backHandler.bind(this)}
                            actionButtonState={this.state.actionButtonState}
                            markCompleteBtn={this.state.markCompleteBtn}
                        />
                        : ""}
                </div>
                {this.state.viewDetailPopupState == true ?
                    <MainDetailsPopup
                        getData={this.getData.bind(this)}
                        userDetails={this.state.userDetail}
                        getviewDetailPopupState={this.getviewDetailPopupState.bind(this)}
                        admin={admin}
                        allDetail={this.state.viewPopupDetail}
                    />
                    : ""}
                {this.state.confirmationPopup == true ?
                    <ConfirmationPopup
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.DeletePopupState == true ?
                    <DeletePopup
                        nameOfUser={this.state.nameOfUser}
                        cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
                        confirmationText={this.state.confirmationText} />
                    : ""}
                {this.state.popupState == true ?
                    <Popup
                        closePopup={this.closePopup.bind(this)}
                        popupStatus={this.state.popupStatus}
                    />
                    : ""}
                {this.state.assignPopupState ?
                    <UsersForAssignUi
                        changeHandler={this.changeHandler.bind(this)}
                        closeTaskAssignPopup={this.closeTaskAssignPopup.bind(this)}
                        taskAssignHandler={this.taskAssignHandler.bind(this)}
                        emailIdForAssign={this.state.emailIdForAssign}
                        allAdminList={this.state.allAdminList} />
                    : null}

                {this.state.mainLoaderState ?
                    <FixedLoader />
                    : null}
            </>

        )
    }

    pushToRetraHandler(loanId) {
        this.setState({
            btnForAction: 'pushRetra',
            loanIdForPush: loanId,
            confirmationPopup: true,
            confirmationText: 'Are you sure to push this user to Retra',
        })
    }

    userCommentChange(e) {
        this.setState({ commentState: e.target.value })
    }

    userCommentHandler(data) {
        const { admin } = this.props
        pushNotification(data, this.state.commentState, '', admin.emailId, "comment", '', '', '', '', '',
            (callBack) => {
                if (document.getElementById("comment" + data.loanId) != null) {
                    document.getElementById("comment" + data.loanId).value = ""
                }
                this.setState({
                    popupState: true,
                    popupStatus: 'Comment Saved Successfully',
                    commentState: ''
                })
                this.removePopup();
            })
    }

    getLoaderState(state) {
        this.setState({
            loadarState: state,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        })
    }

    showWhatsappMsg(data) {
        if (data != '') {
            getWhatsappMsgApi(data.mobileNumber, 'communication', 'unread',
                (callBack) => {
                    this.setState({
                        whatsappPopupState: !this.state.whatsappPopupState,
                        whatsapMsgToShow: callBack
                    })
                })
        } else {
            this.setState({
                whatsappPopupState: false,
                whatsapMsgToShow: ''
            })
        }

    }

    commonSearchHandler(e) {
        this.setState({
            commonSearch: e.target.value,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        })
    }

    phocketTablePagination(data, type) {
        if (type == 'back') {
            if (this.state.tableMinPagination >= paginationNumber) {
                this.setState({
                    tableMinPagination: this.state.tableMinPagination - paginationNumber,
                    tableMaxPagination: this.state.tableMaxPagination - paginationNumber,
                })
            }
        } else if (type == 'forward') {
            if (this.state.tableMaxPagination < data.length) {
                this.setState({
                    tableMaxPagination: this.state.tableMaxPagination + paginationNumber,
                    tableMinPagination: this.state.tableMinPagination + paginationNumber
                })
            }
        }
    }
    paginationHandler(type) {
        if (type == 'refresh') {
            this.getLoanLead()
            fetch(APIS.TODAYS_LOAN_COUNT + this.state.searchByDate + '&eDate=' + this.state.searchByEndDate)
                .then(res => res.json())
                .then(json => {
                    this.setState({
                        todaysApplicationNo: json,
                        tableMinPagination: 0,
                        tableMaxPagination: paginationNumber,
                    })
                })

        } else if (type == 'all') {
            this.setState({
                tableMinPagination: 0,
                tableMaxPagination: paginationNumber,
                followUpStatus: STRING.SELECT_ALL
            }, () => this.getLoanLead())
        } else if (type == 'cache') {
            this.setState({
                loadarState: true
            })
            fetch(APIS.LOAD_CACHE + this.state.searchByDate)
                .then(res => res.text())
                .then(json => {
                    if (json == 'success') {
                        this.getLoanLead()
                        this.setState({
                            tableMinPagination: 0,
                            tableMaxPagination: paginationNumber,
                        })
                    }
                })

        } else if (type == 'left') {
            if (this.state.forwordPageState > 1) {
                this.setState({
                    forwordPageState: this.state.forwordPageState - 1,
                    tableMinPagination: 0,
                    tableMaxPagination: paginationNumber,
                }, () => this.getLoanLead())
            }
        } else if (type == 'right') {
            this.setState({
                forwordPageState: this.state.forwordPageState + 1,
                tableMinPagination: 0,
                tableMaxPagination: paginationNumber,
            }, () => this.getLoanLead())
        }
    }

    userAssignPopup(data) {
        this.setState({
            assignPopupState: true,
            loanIdForAssign: data.loanId !== null ? data.loanId : data.giftCardId,
            // emailIdForAssign: data.assignUser,
            productType: data.typeOfProduct,
            assignToUserId: data.userId
        })
    }

    nashHandler(e, incomingData) {
        const { admin } = this.props;
        saveNachDataApi(e.target.checked, incomingData.userId, admin,
            (callBack) => {
                if (callBack == 'success') {
                    this.getLoanLead()
                }
            })
    }

    removePopup() {
        setTimeout(function () {
            this.setState({ popupState: false });
        }.bind(this), 5000)
    }

    closePopup() {
        this.setState({ popupState: false });
    }

    deleteUserHandler(data) {
        this.setState({
            userIdForDelete: data.userId,
            DeletePopupState: true,
            confirmationText: 'Are you sure to Delete this user',
            btnForAction: 'delete',
            nameOfUser: data
        })
    }

    cancelLoanConfirm(conType) {
        if (conType == 'yes') {
            if (this.state.btnForAction == 'delete') {
                fetch(APIS.DELETE_COMPLETE_USER + this.state.userIdForDelete)
                    .then(res => res.text())
                    .then(text => {
                        if (text == 'success') {
                            this.setState({ DeletePopupState: false })
                            this.getLoanLead()
                        }
                    })
            } else if (this.state.btnForAction == 'nach') {
                fetch(APIS.DEACTIVATE_NACH + this.state.userIdForDeactivate)
                    .then(res => res.text())
                    .then(text => {
                        if (text == 'success') {
                            this.setState({
                                confirmationPopup: false, popupState: true,
                                DeletePopupState: false,
                                popupStatus: 'Deactivated Successfully'
                            })
                            this.removePopup();
                        } else {
                            this.setState({
                                confirmationPopup: false, popupState: true,
                                DeletePopupState: false,
                                popupStatus: 'Already Deactivated'
                            })
                        }
                        this.removePopup();

                    })
            }
            else if (this.state.btnForAction == 'pushRetra') {
                pushLeadToRetraApi(this.state.loanIdForPush, callBack => {
                    if (callBack == 'success') {
                        this.setState({
                            confirmationPopup: false,
                            popupState: true,
                            popupStatus: 'PUsh successfully'
                        })
                        this.removePopup();
                    } else {
                        this.setState({
                            popupState: true,
                            popupStatus: 'Please try again'
                        })
                        this.removePopup();
                    }
                })
            }
        } else if (conType == 'no') {
            this.setState({ confirmationPopup: false, DeletePopupState: false })
        }
    }
    updatenameasonpanapi(userId) {
        this.setState({ loadarState: true })
        fetch(APIS.UPDATE_NAME_AS_ON_PAN + userId)
            .then(res => res.text())
            .then(text => {
                if (text == 'success') {
                    this.setState({ loadarState: false, popupState: true, popupStatus: 'Updated Successfully!' }, () => this.removePopup())
                } else {
                    this.setState({ loadarState: false, popupState: true, popupStatus: text }, () => this.removePopup())

                }
            })
    }
    showRepeateApplications() {
        this.setState({
            followUpStatus: 'repeate'
        }, () => this.getLoanLead())
    }

    markAsImportant(allDetail, isImportant) {
        const { admin } = this.props
        if (allDetail.loanId != null && allDetail.loanId != '') {
            saveImportantLoans(allDetail, allDetail.loanId, isImportant, admin.emailId,
                (callBack) => {
                    this.getLoanLead()
                })
        } else {
            saveImportantLoans(allDetail, allDetail.giftCardId, isImportant, admin.emailId,
                (callBack) => {
                    this.getLoanLead()
                })
        }
    }

    taskAssignHandler(e) {
        // this.setState({ emailIdForAssign: e.target.value })
        if (this.state.emailIdForAssign !== '') {
            fetch(APIS.ASSIGN_LOAN + this.state.loanIdForAssign + "&empId=" + this.state.emailIdForAssign + '&type=' + this.state.productType + '&userId=' + this.state.assignToUserId)
                .then(res => res.text())
                .then(text => {
                    if (text == 'success') {
                        this.getLoanLead()
                    }
                })
            this.setState({ assignPopupState: false })
        } else {
            this.setState({

                popupState: true,
                popupStatus: 'Select User'
            })
            this.removePopup();
        }
    }

    closeTaskAssignPopup() {
        this.setState({ assignPopupState: false })
    }

    dataFilter(e) {
        this.setState({
            searchByDate: _preFormatDate(e),
            searchByDateShow: e,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        }, () => this.getLoanLead())
    }

    statusHandler(e) {
        this.setState({
            followUpStatus: e.target.value,
            tableMinPagination: 0,
            tableMaxPagination: paginationNumber,
        }, () => this.getLoanLead())
    }

    componentDidUpdate() {
        const { loanApprovalLeadOptimized } = this.props;
        if (loanApprovalLeadOptimized.length > 0) {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = false;
        } else {
            if (document.getElementById("forword") != null)
                document.getElementById("forword").disabled = true;
        }

    }
    getData(val) {
        this.setState({ dataChange: val })
    }
    backHandler(data) {
        const { admin } = this.props
        this.setState({
            userState: true,
            viewState: false
        })
        if (this.state.dataChange == 'yes') {
            this.getLoanLead()
            this.setState({ dataChange: '' })
        }
        // this.getLoanLead()
    }

    userDetailHandler(data) {
        this.setState({ mainLoaderState: true })
        if (data.loanId != '' && data.loanId != null) {
            getUserDetailByLoanIdOptimisedApi(data.loanId, data.userId,
                (callBack) => {
                    this.setState({
                        userState: false,
                        viewState: true,
                        allDetail: callBack,
                        userDetail: data,
                        mainLoaderState: false
                    })
                })
        } else {
            //    console.log(APIS.USER_DETAIL_BY_ID + data.userId)
            fetch(APIS.USER_DETAIL_BY_ID + data.userId)
                .then(res => res.json())
                .then(res => {
                    this.setState({
                        userState: false,
                        viewState: true,
                        allDetail: res,
                        userDetail: data,
                        mainLoaderState: false
                    })
                })


        }
    }

    userViewHandler(data) {
        this.setState({ mainLoaderState: true })
        if (data.loanId != null && data.loanId != '') {
            getUserDetailByLoanIdOptimisedApi(data.loanId, data.userId,
                (callBack) => {
                    this.setState({
                        viewDetailPopupState: true,
                        viewPopupDetail: callBack,
                        userDetail: data,
                        mainLoaderState: false
                    })
                })
        } else {

            fetch(APIS.USER_DETAIL_BY_ID + data.userId)
                .then(res => res.json())
                .then(res => {
                    this.setState({
                        viewDetailPopupState: true,
                        viewPopupDetail: res,
                        userDetail: data,
                        mainLoaderState: false
                    })
                })


        }
    }

    getviewDetailPopupState(state) {
        this.setState({
            viewDetailPopupState: state,
        })
    }

    componentWillUnmount() {
        // console.log('unmount', interval)
        clearInterval(interval);
    }

}

const mapStateToProps = ({ allUserDetail, loanApprovalLeadOptimized }) => {
    return {
        allUserDetail: allUserDetail,
        loanApprovalLeadOptimized: loanApprovalLeadOptimized,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getLoanApprovalLeadOptimized
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(LoanApplyContainer);