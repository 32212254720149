import React, { useState } from 'react'
import moment from 'moment'
import { PieChart, Pie, Cell, ComposedChart, Scatter, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
const differenceDate = () => {
    let d = new Date()
    let todaysDate = d.getDate();
    return todaysDate - 1
}

const InitalDate = (type) => {
    const FirstDay = moment().subtract(differenceDate(), 'days');
    if (type === 'fromDate') {
        const fromdate = FirstDay.format('YYYY-MM-DD');
        return fromdate
    } else if (type === 'toDate') {
        const todate = moment(new Date).format('YYYY-MM-DD')
        return todate
    }
}


export default function LoanInsights() {
    const Piecolors = ['#800080', '#267dff'];
    const [fromDate, setFromDate] = useState(InitalDate('fromDate'))
    const [toDate, setToDate] = useState(InitalDate('toDate'))
    const [clearbtnShow, setClearBtnShow] = useState(false);

    const handleDate = (e, type) => {
        if (type === 'fromDate') {
            setFromDate(e.target.value)
            setClearBtnShow(true)

        } else if (type === 'toDate') {
            setToDate(e.target.value)
            setClearBtnShow(true)

        } else if (type === 'clearFilter') {
            setClearBtnShow(false)
            setFromDate(InitalDate('fromDate'))
            setToDate(InitalDate('toDate'))

        } else if (type === 'applyFilter') {

        }
    }

    const MonthlyRevenue = [
        {
            name: 'January',
            Revenue: 4000,
            Goal: 4500,
            Volume: 200,
            amt: 2400,
        },
        {
            name: 'February',
            Revenue: 3000,
            Goal: 3200,
            Volume: 9200,
            amt: 2210,
        },
        {
            name: 'March',
            Revenue: 2000,
            Goal: 2100,
            Volume: 1220,
            amt: 2290,
        },
        {
            name: 'April',
            Revenue: 2780,
            Goal: 2900,
            Volume: 2200,
            amt: 2000,
        },
        {
            name: 'May',
            Revenue: 1890,
            Goal: 2000,
            Volume: 100,
            amt: 2181,
        },
        {
            name: 'June',
            Revenue: 2390,
            Goal: 2600,
            Volume: 3200,
            amt: 2500,
        },
        {
            name: 'July',
            Revenue: 3490,
            Goal: 3600,
            Volume: 4200,
            amt: 2100,
        },
        {
            name: 'August',
            Revenue: 2000,
            Goal: 2200,
            Volume: 2200,
            amt: 2300,
        },
        {
            name: 'September',
            Revenue: 2780,
            Goal: 5900,
            Volume: 500,
            amt: 2000,
        },
        {
            name: 'October',
            Revenue: 1890,
            Goal: 3000,
            Volume: 1000,
            amt: 2181,
        },
        {
            name: 'November',
            Revenue: 2390,
            Goal: 4500,
            Volume: 1400,
            amt: 2500,
        },
        {
            name: 'December',
            Revenue: 3490,
            Goal: 2700,
            Volume: 1100,
            amt: 2100,
        },
    ];


    const DeclinedReasonBreakdown = [
        { name: 'Credit Issue', value: 41.7 },
        { name: 'Balance Too High', value: 58.3 },
    ];

    const customerGrowth = [
        {
            name:'Technology',
            value:200
        },
        {
            name:'Electronics',
            value:400
        },
        {
            name:'Consulting',
            value:800
        },
    ]

    return (
        <>
            <div className="LoanInsights">
                <div className="Filterations">
                    <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <div className="inputgrp">
                                <label htmlFor="fromDate" className='inputlabel'>From</label>
                                <input type="date" id='fromDate' className='inputtag' value={fromDate} onChange={(e) => handleDate(e, 'fromDate')} />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <div className="inputgrp">
                                <label htmlFor="toDate" className='inputlabel'>To</label>
                                <input type="date" id='toDate' className='inputtag' value={toDate} min={fromDate} onChange={(e) => handleDate(e, 'toDate')} />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div style={{ display: 'flex' }}>
                                <button className='filterbtn' style={{ marginTop: '38px', marginRight: '20px' }} onClick={(e) => handleDate(e, 'applyFilter')}>Apply Filter</button>
                                {
                                    clearbtnShow ?
                                        <button className='filterbtnReverse' style={{ marginTop: '38px' }} onClick={(e) => handleDate(e, 'clearFilter')}>Clear Filter</button>
                                        : ''}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="topTabs">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-xs-12">
                            <div className="tabss">
                                <div className="topBox">
                                    <h4>Total Revenue</h4>
                                    <h5>0%</h5>
                                </div>
                                <div className="BottomBox">
                                    <div className="left">
                                        <h5>CY</h5>
                                        <h6>₹ 56789</h6>
                                    </div>
                                    <div className="left">
                                        <h5>PY</h5>
                                        <h6>₹ 56789</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12">
                            <div className="tabss">
                                <div className="topBox">
                                    <h4>Total Leads</h4>
                                    <h5>0%</h5>
                                </div>
                                <div className="BottomBox">
                                    <div className="left">
                                        <h5>CY</h5>
                                        <h6>40</h6>
                                    </div>
                                    <div className="left">
                                        <h5>PY</h5>
                                        <h6>40</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12">
                            <div className="tabss">
                                <div className="topBox">
                                    <h4>Principal</h4>
                                    <h5>0%</h5>
                                </div>
                                <div className="BottomBox">
                                    <div className="left">
                                        <h5>CY</h5>
                                        <h6>97</h6>
                                    </div>
                                    <div className="left">
                                        <h5>PY</h5>
                                        <h6>97</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-12">
                            <div className="tabss">
                                <div className="topBox">
                                    <h4>Interest</h4>
                                    <h5>0%</h5>
                                </div>
                                <div className="BottomBox">
                                    <div className="left">
                                        <h5>CY</h5>
                                        <h6>97</h6>
                                    </div>
                                    <div className="left">
                                        <h5>PY</h5>
                                        <h6>97</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12">
                            <div className="tabss">
                                <div className="heads">
                                    <h5>Monthly Disbursed Amount</h5>
                                </div>
                                <div className="charts" style={{ height: '300px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart
                                            width={500}
                                            height={300}
                                            data={MonthlyRevenue}
                                            barSize={80}
                                            margin={{
                                                top: 20,
                                                right: 0,
                                                left: 0,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid vertical={false} />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar dataKey="Volume" fill="#267dff" />
                                            <Scatter dataKey="Goal" fill="#dccc2a" strokeWidth={2} line shape="dot" />
                                            <Scatter dataKey="Revenue" fill="purple" strokeWidth={2} line shape="dot" />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>

                                <div className="legends">
                                    <div className="legend1">
                                        <div className="line" style={{ backgroundColor: 'purple' }}></div>
                                        <h5>Revenue</h5>
                                    </div>
                                    <div className="legend1">
                                        <div className="line" style={{ backgroundColor: '#dccc2a' }}></div>
                                        <h5>Goal</h5>
                                    </div>
                                    <div className="legend1">
                                        <div className="square" style={{ backgroundColor: '#267dff' }}></div>
                                        <h5>Volume</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-xs-12">
                            <div className="tabss">
                                <div className="heads">
                                    <h5>Declined Reason Breakdown</h5>
                                </div>
                                <div className="Piechart" style={{ height: '300px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <PieChart
                                            width={300}
                                            height={300}
                                        >
                                            <Pie data={DeclinedReasonBreakdown} dataKey="value" cx="50%" cy="50%" outerRadius={80} fill="#82ca9d"
                                                label={({ value, name, ...otherData }) => <CustomPieLabel value={value} name={name} otherData={otherData} />}
                                            >
                                                {DeclinedReasonBreakdown.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={Piecolors[index % Piecolors.length]} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-xs-12">
                            <div className="tabss">
                                <div className="heads">
                                    <h5>Customer Growth</h5>
                                </div>
                                <div className="chart" style={{ height: '300px' }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={560}
                                            height={300}
                                            data={customerGrowth}
                                            barSize={60}
                                            margin={{
                                                top: 20, right: 0, left: -5, bottom: 10,
                                            }}
                                        >
                                            <CartesianGrid vertical={false} />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Bar type="monotone" dataKey="value" fill="#4481d5" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const CustomPieLabel = ({ value, name, otherData }) => {
    const RADIAN = Math.PI / 180;
    const radius = otherData.innerRadius + (otherData.outerRadius - otherData.innerRadius) * 0.5;
    const newx = otherData.cx + radius * Math.cos(-otherData.midAngle * RADIAN);
    const newy = otherData.cy + radius * Math.sin(-otherData.midAngle * RADIAN);

    const labelRadius = otherData.outerRadius * 1.5;
    const labelX = otherData.cx + labelRadius * Math.cos(-otherData.midAngle * RADIAN);
    const labelY = otherData.cy + labelRadius * Math.sin(-otherData.midAngle * RADIAN);

    const horizontalLabelX = otherData.cx + (labelRadius + 20) * Math.cos(-otherData.midAngle * RADIAN);
    const horizontalLabelY = labelY;

    const isLeftSide = labelX < otherData.cx;
    return (
        <>
            <path
                d={`M${newx},${newy} L${labelX},${labelY} L${horizontalLabelX},${horizontalLabelY}`}
                stroke="#000"
                fill="none"
            />
            <text
                x={isLeftSide ? horizontalLabelX - 10 : horizontalLabelX + 10}
                y={horizontalLabelY - 20}
                fill="#000"
                textAnchor={isLeftSide ? 'end' : 'start'}
                dominantBaseline="central"
                style={{ fontSize: '20px' }}
            >
                {value}
            </text>
            <text
                x={isLeftSide ? horizontalLabelX - 10 : horizontalLabelX + 10}
                y={horizontalLabelY}
                fill="#000"
                textAnchor={isLeftSide ? 'end' : 'start'}
                dominantBaseline="central"
                style={{ fontSize: '18px' }}
            >
                {name}
            </text>
        </>
    );
};